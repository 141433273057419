import React, { useEffect, useState, useRef, useContext } from "react";
import Header from "./Header";
import axios from "axios";
import { token_abi } from "../ABI";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import { BigNumber } from "bignumber.js";
import toast, { Toaster } from "react-hot-toast";
import "../App.css";
import { Network_Options } from "../NetworkList";
import ABI from "../Abi.json";
import { WalletContext } from "./WalletContext";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import TonWeb from "tonweb";
import {
  Contract_details,
  NODE_URL,
  RPC_URL,
  TOKEN_CONTRACT,
  SOL_ADMIN_ADDRESS,
  EVM_ADMIN_ADDRESS,
  TON_ADMIN_ADDRESS,
  TRON_ADMIN_ADDRESS,
  BTC_ADMIN_ADDRESS,
} from "./Config";
import {
  PublicKey,
  Connection,
  Transaction,
  TransactionInstruction,
  SystemProgram,
  clusterApiUrl,
  sendAndConfirmRawTransaction,
} from "@solana/web3.js";
import QRCode from "qrcode.react";
import { Buffer } from "buffer";
import BN from "bn.js";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import Solflare from "@solflare-wallet/sdk";
const { Cell } = require("ton");
const solanaWeb3 = require("@solana/web3.js");  

export default function Presale() {
  const [Tokens, setTokens] = useState([]);

  const { walletAddress, walletAddressType } = useContext(WalletContext);
  console.log(walletAddress, walletAddressType);

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [tonConnectUI] = useTonConnectUI();

  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [selectedToken, setSelectedToken] = useState(null);
  const [Amount, setAmount] = useState(1);
  const [TokenAmount, setTokenAmount] = useState(0);
  const [tokenSupply, setTokenSupply] = useState(null);

  const [PreOrder, setPreOrder] = useState(null);
  const [TokenUSDprice, setTokenUSDprice] = useState(null);
  const [MinQuantity, setMinQuantity] = useState(null);
  const [MaxQuantity, setMaxQuantity] = useState(null);
  const [PreSaleStatus, setPreSaleStatus] = useState(null);

  const [PreOrderBalance, setPreOrderBalance] = useState(null);
  const [Percentage, setPercentage] = useState(0);

  const [showPopup, setShowPopup] = useState(false);
  const [solanaWalletAddress, setSolanaWalletAddress] = useState(null);

  const [activeDropdown, setActiveDropdown] = useState(null);
  const [selectedItems, setSelectedItems] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isAnyTokenSelected, setIsAnyTokenSelected] = useState(false);
  const [adminWallet, setAdminWallet] = useState(null);

  const [hasShownWalletToast, setHasShownWalletToast] = useState(false);
  const [hasShownInputToast, setHasShownInputToast] = useState(false);
  const [hasShownMaxQuantityToast, setHasShownMaxQuantityToast] =
    useState(false);

  const walletToastTimeoutRef = useRef(null);
  const inputToastTimeoutRef = useRef(null);
  const maxQuantityToastTimeoutRef = useRef(null);

  const [fromAddress, setFromAddress] = useState("");
  const [transactionHash, setTransactionHash] = useState("");
  const [qrPopup, setQrPopup] = useState("");
  const [confirmPopup, setConfirmPopup] = useState("");

  const tokens = [
    {
      name: "ETH",
      symbol: "ERC-20",
      img: "https://etherscan.io/images/svg/brands/ethereum-original.svg",
      dropdownItems: [
        {
          name: "ETH",
          symbol: "ETH",
          decimals: 18,
          img: "https://etherscan.io/images/svg/brands/ethereum-original.svg",
          address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
        },
        {
          name: "Tether USD",
          symbol: "USDT",
          decimals: Contract_details.ETH_USDT_DECIMAL,
          img: "https://etherscan.io/token/images/tethernew_32.png",
          address: Contract_details.ETH_USDT_CONTRACT,
        },
        {
          name: "USDC",
          symbol: "USDC",
          decimals: Contract_details.ETH_USDC_DECIMAL,
          img: "https://etherscan.io/token/images/centre-usdc_28.png",
          address: Contract_details.ETH_USDC_CONTRACT,
        },
        {
          name: "SHIBA INU",
          symbol: "SHIB",
          decimals: Contract_details.ETH_SHIB_DECIMAL,
          img: "https://etherscan.io/token/images/shibatoken_32.png",
          address: Contract_details.ETH_SHIB_CONTRACT,
        },
      ],
    },
    {
      name: "BSC",
      symbol: "BEP-20",
      img: "https://bscscan.com/assets/bsc/images/svg/logos/token-light.svg?v=24.7.2.0",
      dropdownItems: [
        {
          name: "BNB",
          symbol: "BNB",
          address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
          decimals: 18,
          img: "https://bscscan.com/assets/bsc/images/svg/logos/token-light.svg?v=24.7.2.1",
        },
        {
          name: "Binance-Peg USD Coin",
          symbol: "BUSD",
          address: Contract_details.BSC_BUSD_CONTRACT,
          decimals: Contract_details.BSC_BUSD_DECIMAL,
          img: "https://bscscan.com/token/images/busd_32_2.png",
        },
      ],
    },
    {
      name: "BASE",
      symbol: "ERC-20",
      img: "https://basescan.org/assets/base/images/svg/logos/chain-light.svg?v=24.7.2.0",
      dropdownItems: [
        {
          name: "BASE",
          symbol: "BASE",
          address: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
          decimals: 18,
          img: "https://basescan.org/assets/base/images/svg/logos/chain-light.svg?v=24.7.2.0",
        },
        {
          name: "USD Coin",
          symbol: "USDC",
          address: Contract_details.BASE_USDC_CONTRACT,
          decimals: Contract_details.BASE_USDC_DECIMAL,
          img: "https://basescan.org/token/images/centre-usdc_28.png",
        },
      ],
    },
    {
      name: "MATIC",
      symbol: "ERC-20",
      img: "https://polygonscan.com/assets/poly/images/svg/logos/token-light.svg?v=24.6.3.0",
      dropdownItems: [
        {
          name: "Matic Token",
          symbol: "MATIC",
          address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
          decimals: 18,
          img: "https://polygonscan.com/assets/poly/images/svg/logos/token-light.svg?v=24.7.2.0",
        },
        {
          name: "(PoS) Tether USD",
          symbol: "USDT",
          address: Contract_details.POLYGON_USDT_CONTRACT,
          decimals: Contract_details.POLYGON_USDT_DECIMAL,
          img: "https://polygonscan.com/token/images/tether_32.png",
        },
        {
          symbol: "USDC",
          address: Contract_details.POLYGON_USDC_CONTRACT,
          decimals: Contract_details.POLYGON_USDC_DECIMAL,
          name: "USD Coin",
          img: "https://polygonscan.com/token/images/usdc_32.png",
        },
      ],
    },

    // {name: "TRC", symbol: "TRC-20"},
    // {name: "BTC", symbol: "Bitcoin"},
    // {name: "SOL", symbol: "Solana"},
  ];

  const tokens1 = [
    {
      name: "SOL",
      symbol: "ERC-20",
      img: "https://solscan.io/_next/static/media/solana-sol-logo.ecf2bf3a.svg",
      dropdownItems: [
        {
          name: "SOL",
          symbol: "SOL",
          decimals: 9,
          img: "https://solscan.io/_next/static/media/solPriceLogo.76eeb122.png",
          address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
        },
      ],
    },
  ];

  const tokens2 = [
    {
      name: "TON",
      symbol: "ERC-20",
      img: "https://cryptologos.cc/logos/toncoin-ton-logo.png",
      dropdownItems: [
        {
          name: "TON",
          symbol: "TON",
          decimals: 9,
          img: "https://cryptologos.cc/logos/toncoin-ton-logo.png",
          address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
        },
      ],
    },
  ];

  const tokens3 = [
    {
      name: "TRON",
      symbol: "TRC-20",
      img: "https://static.tronscan.org/production/logo/trx.png",
      dropdownItems: [
        {
          name: "TRON",
          symbol: "TRX",
          address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
          decimals: 6,
          img: "https://static.tronscan.org/production/logo/trx.png",
        },
        {
          name: "Tether USD",
          symbol: "USDT",
          address: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
          decimals: 6,
          img: "https://static.tronscan.org/production/logo/usdtlogo.png",
        },
      ],
    },
  ];

  const tokens4 = [
    {
      name: "BTC",
      symbol: "ERC-20",
      img: "https://btcscan.org/img/icons/Bitcoin-menu-logo.svg",
      dropdownItems: [
        {
          name: "BTC",
          symbol: "BTC",
          address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
          decimals: 8,
          img: "https://btcscan.org/img/icons/Bitcoin-menu-logo.svg",
        },
      ],
    },
  ];

  useEffect(() => {
    console.log(walletAddressType);
    if (walletAddressType !== null) {
      if (walletAddressType === "PHANTOM") {
        setTokens(tokens1);
        setAdminWallet(SOL_ADMIN_ADDRESS);
      } else if (walletAddressType === "TON") {
        setTokens(tokens2);
        setAdminWallet(TON_ADMIN_ADDRESS);
      } else if (walletAddressType === "TRON") {
        setTokens(tokens3);
        setAdminWallet(TRON_ADMIN_ADDRESS);
      } else if (walletAddressType === "BTC") {
        setTokens(tokens4);
        setAdminWallet(BTC_ADMIN_ADDRESS);
      } else {
        setTokens(tokens);
        setAdminWallet(EVM_ADMIN_ADDRESS);
      }
    } else {
      setTokens(tokens);
    }
  }, [walletAddress, walletAddressType]);

  const handleDropdown = (index) => {
    const newIndex = activeDropdown === index ? null : index;
    setActiveDropdown(newIndex);
    setSelectedIndex(newIndex);
    setIsAnyTokenSelected(newIndex !== null);
    setSelectedToken(null);
  };

  const choseTokens = async (tokenIndex, item) => {
    try {
      if (walletAddressType === "PHANTOM") {
        handleSelectSol(tokenIndex, item);
      } else if (walletAddressType === "TON") {
        handleSelectTon(tokenIndex, item);
      } else if (walletAddressType === "TRON") {
        handleSelectTron(tokenIndex, item);
      } else if (walletAddressType === "BTC") {
        handleSelectBtc(tokenIndex, item);
      } else {
        handleSelectItem(tokenIndex, item);
      }
    } catch (error) {
      console.error("Error chosing network : ", error);
    }
  };

  const handleSelectItem = async (tokenIndex, item) => {
    try {
      if (isConnected === false) {
        toast.error("Please connect the wallet");
        return false;
      }
      setSelectedToken(item);
      setSelectedIndex(tokenIndex);
      setSelectedItems({
        ...selectedItems,
        [tokenIndex]: item,
      });
      setActiveDropdown(null);

      const selectedOption = Network_Options.find(
        (option) => option.id === tokenIndex
      );
      console.log(selectedOption, tokenIndex, chainId);
      if (selectedOption) {
        if (chainId === parseInt(selectedOption.chainId)) {
          return;
        } else {
          try {
            await walletProvider.request({
              method: "wallet_switchEthereumChain",
              params: [
                {
                  chainId: ethers.utils.hexValue(
                    parseInt(selectedOption.chainId)
                  ),
                },
              ],
            });
            toast.success("Network changed successfully");
          } catch (switchError) {
            toast.error("Failed to switch the network");
            console.error("Error switching the network:", switchError);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
      } else {
        toast.error("Please select a valid network");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        setSelectedNetwork(null);
        setSelectedToken(null);
      }
    } catch (error) {
      console.error(error);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const handleSelectSol = async (tokenIndex, item) => {
    if (walletAddress === null) {
      toast.error("Please connect Phantom wallet");
      return false;
    }
    setSelectedToken(item);
    setSelectedIndex(tokenIndex);
    setSelectedItems({
      ...selectedItems,
      [tokenIndex]: item,
    });
    setActiveDropdown(null);
  };

  const handleSelectTon = async (tokenIndex, item) => {
    if (walletAddress === null) {
      toast.error("Please connect Ton wallet");
      return false;
    }
    setSelectedToken(item);
    setSelectedIndex(tokenIndex);
    setSelectedItems({
      ...selectedItems,
      [tokenIndex]: item,
    });
    setActiveDropdown(null);
  };

  const handleSelectTron = async (tokenIndex, item) => {
    if (walletAddress === null) {
      toast.error("Please connect Tron wallet");
      return false;
    }
    setSelectedToken(item);
    setSelectedIndex(tokenIndex);
    setSelectedItems({
      ...selectedItems,
      [tokenIndex]: item,
    });
    setActiveDropdown(null);
  };

  const handleSelectBtc = async (tokenIndex, item) => {
    if (walletAddress === null) {
      toast.error("Please connect Tron wallet");
      return false;
    }
    setSelectedToken(item);
    setSelectedIndex(tokenIndex);
    setSelectedItems({
      ...selectedItems,
      [tokenIndex]: item,
    });
    setActiveDropdown(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValidAddress = isValidSolanaAddress(solanaWalletAddress);
    console.log(isValidAddress, walletAddress, solanaWalletAddress);
    if (isValidAddress) {
      const res = await axios.post(`${NODE_URL}/apiv2/addSolanaAddress`, {
        address: walletAddress,
        solanaWalletAddress: solanaWalletAddress,
      });
      if (res.data && res.data.status === "success") {
        console.log("Solana Wallet Address:", solanaWalletAddress, res.data);
        setShowPopup(false);
        Transfer();
      } else {
        console.error("Error : ", res);
        toast.error("Re-enter Solana Wallet Address");
      }
    } else {
      console.error("Invalid Solana Wallet Address");
      toast.error("Invalid Solana Wallet Address");
    }
  };

  const isValidSolanaAddress = (address) => {
    try {
      new PublicKey(address);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;

    if (isNaN(value)) {
      if (!hasShownInputToast) {
        toast.error("Please enter numerical values only");
        setHasShownInputToast(true);

        if (inputToastTimeoutRef.current) {
          clearTimeout(inputToastTimeoutRef.current);
        }
        inputToastTimeoutRef.current = setTimeout(() => {
          setHasShownInputToast(false);
        }, 5000);
      }
      return;
    }

    if (walletAddress === null && !hasShownWalletToast) {
      toast.error("Please connect the wallet");
      setHasShownWalletToast(true);

      if (walletToastTimeoutRef.current) {
        clearTimeout(walletToastTimeoutRef.current);
      }
      walletToastTimeoutRef.current = setTimeout(() => {
        setHasShownWalletToast(false);
      }, 5000);
    }
    console.log(value);
    setAmount(value);
  };

  useEffect(() => {
    return () => {
      if (walletToastTimeoutRef.current) {
        clearTimeout(walletToastTimeoutRef.current);
      }
      if (inputToastTimeoutRef.current) {
        clearTimeout(inputToastTimeoutRef.current);
      }
      if (maxQuantityToastTimeoutRef.current) {
        clearTimeout(maxQuantityToastTimeoutRef.current);
      }
    };
  }, []);

  const validateAmount = async () => {
    let Token;
    let value;
    if (Amount > 0) {
      value = Amount;
    } else {
      value = 0;
    }
    if (selectedToken.symbol === "WMATIC") {
      Token = "MATIC";
    } else if (selectedToken.symbol === "USDC.e") {
      Token = "USDC";
    } else {
      Token = selectedToken.symbol;
    }

    const response = await axios.get(
      `https://min-api.cryptocompare.com/data/price?api_key=830c94225fed427911d9c8e4ea29b220d78e466ee4f597fa8ecce33ee602355c&fsym=${Token}&tsyms=USD`
    );
    const data = response.data;

    if (data.Response === "Error") {
      console.error("Error in API response:", data.Message);
      return;
    }

    const conversionRate = data ? data.USD : 0;
    console.log("Conversion Rate:", conversionRate);

    // if ((value * conversionRate)/TokenUSDprice > Number(MaxQuantity)) {
    //   if (!hasShownMaxQuantityToast) {
    //     toast.error("Entered Amount is greater than the Max Limit");
    //     setHasShownMaxQuantityToast(true);

    //     if (maxQuantityToastTimeoutRef.current) {
    //       clearTimeout(maxQuantityToastTimeoutRef.current);
    //     }
    //     maxQuantityToastTimeoutRef.current = setTimeout(() => {
    //       setHasShownMaxQuantityToast(false);
    //     }, 5000);
    //   }
    //   return;
    // }

    setTokenAmount((value * conversionRate).toFixed(4));
  };

  useEffect(() => {
    if (selectedToken !== null) {
      validateAmount();
    }
  }, [Amount, selectedToken]);

  const Transfer = async () => {
    try {
      if (walletAddress === null) {
        toast.error("Please connect your wallet and Try Again");
        return false;
      }
      if (!Amount || Amount <= 0 || !selectedToken) {
        toast.error("Please enter all fields correctly.");
        return false;
      }

      if (PreSaleStatus === "inactive") {
        toast.error("Pre-Sale Ended");
        return false;
      }

      const tokenAmountInMeme = Number(
        (Number(TokenAmount) / Number(TokenUSDprice)).toFixed(2)
      );
      console.log(TokenAmount, tokenAmountInMeme, MinQuantity, MaxQuantity); // 5.0000000000 5 10

      if (tokenAmountInMeme < Number(MinQuantity)) {
        toast.error("Minimum Purchase Amount is " + MinQuantity + " MEME");
        return false;
      }

      if (tokenAmountInMeme > Number(MaxQuantity)) {
        toast.error("Maximum Purchase Amount is " + MaxQuantity + " MEME");
        return false;
      }

      console.log(Number(PreOrderBalance) > Number(PreOrder));
      if (Number(PreOrderBalance) > Number(PreOrder)) {
        toast.error("PreOrder Sale is Over");
        return false;
      }

      if (walletAddressType === "PHANTOM") {
        if (selectedToken.symbol === "SOL") {
          TransferSOL(walletAddress, SOL_ADMIN_ADDRESS, Amount);
        } else {
          // transferSPLToken(
          //   new PublicKey(walletAddress),
          //   new PublicKey(walletAddress),
          //   new PublicKey(selectedToken.address),
          //   Amount,
          //   selectedToken.decimals
          // );
        }
      } else if (walletAddressType === "TON") {
        TransferTON(TON_ADMIN_ADDRESS, Amount);
      } else if (walletAddressType === "TRON") {
        TransferTRON(TRON_ADMIN_ADDRESS, Amount);
      } else if (walletAddressType === "BTC") {
        TransferBTC(BTC_ADMIN_ADDRESS, Amount);
      } else {
        TransferTokens();
      }
    } catch (error) {
      console.error("Error chosing network : ", error);
    }
  };

  const TransferTokens = async () => {
    console.log(Amount, selectedToken, address, solanaWalletAddress);

    if (!address || !isConnected) {
      toast.error("Please connect your wallet and Try Again");
      return false;
    }
    if (!Amount || Amount <= 0 || !selectedToken || !address) {
      toast.error("Please enter all fields correctly.");
      return false;
    }

    if (PreSaleStatus === "inactive") {
      toast.error("Pre-Sale Ended");
      return false;
    }

    const tokenAmountInMeme = Number(
      (Number(TokenAmount) / Number(TokenUSDprice)).toFixed(2)
    );
    console.log(TokenAmount, tokenAmountInMeme, MinQuantity, MaxQuantity); // 5.0000000000 5 10

    if (tokenAmountInMeme < Number(MinQuantity)) {
      toast.error("Minimum Purchase Amount is " + MinQuantity + " MEME");
      return false;
    }

    if (tokenAmountInMeme > Number(MaxQuantity)) {
      toast.error("Maximum Purchase Amount is " + MaxQuantity + " MEME");
      return false;
    }

    if (!isConnected) {
      toast.error("Please connect the wallet.");
      return false;
    }
    console.log(Number(PreOrderBalance) > Number(PreOrder));
    if (Number(PreOrderBalance) > Number(PreOrder)) {
      toast.error("PreOrder Sale is Over");
      return false;
    }

    const TokenAddress = selectedToken.address;
    const TokenAbi = token_abi;
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = provider.getSigner();
    const contractInstance = new ethers.Contract(
      TokenAddress,
      TokenAbi,
      signer
    );

    const TransferAmount = new BigNumber(
      Amount * 10 ** selectedToken.decimals
    ).toFixed(0);
    const recipientAddress = EVM_ADMIN_ADDRESS;

    const toastId1 = toast.loading("Token transaction in progress", {
      duration: 3000,
    });
    let transferTransaction;
    setTimeout(() => {
      toast.dismiss(toastId1);
    }, 2000);
    try {
      if (
        selectedToken.symbol === "MATIC" ||
        selectedToken.symbol === "ETH" ||
        selectedToken.symbol === "BNB" ||
        selectedToken.symbol === "BASE"
      ) {
        transferTransaction = await signer.sendTransaction({
          to: recipientAddress,
          value: TransferAmount,
        });
      } else {
        transferTransaction = await contractInstance.transfer(
          recipientAddress,
          TransferAmount
        );
      }

      console.log(`${selectedToken.symbol} transaction successful`);
      const result = await transferTransaction.wait();
      const Check = {
        address: address,
        Token: selectedToken.symbol,
        amount: Amount,
        amountUSD: TokenAmount,
        CoinAmount: TokenAmount / Number(TokenUSDprice),
        CoinAmountUSD: TokenUSDprice,
        TokenHash: result.transactionHash,
        network: chainId,
        solanaWalletAddress: solanaWalletAddress,
        TokenStatus: result.status,
      };
      console.log(Check);
      if (result && result.transactionHash) {
        const CoinAmt = TokenAmount / Number(TokenUSDprice);
        const receiveTokens = await axios.get(
          `${NODE_URL}/apiv2/getMemeTokens?address=${address}&Token=${selectedToken.symbol}&amount=${Amount}&amountUSD=${TokenAmount}&CoinAmount=${CoinAmt}&CoinAmountUSD=${TokenUSDprice}&TokenHash=${result.transactionHash}&network=${chainId}&solanaWalletAddress=${solanaWalletAddress}&TokenStatus=${result.status}`
        );
        console.log(receiveTokens);
        if (receiveTokens.data.status === "success") {
          toast.success(`${selectedToken.symbol} transaction successful`, {
            id: toastId1,
          });
          toast.success(
            "Your Tokens will be Claimable Once  Presale is Over!...."
          );
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } else {
        toast.error("Transaction failed", { id: toastId1 });
      }
    } catch (error) {
      console.log(error, "error");
      const errorData = error.error && error.error.data;
      if (
        errorData &&
        errorData.code === 3 &&
        errorData.message.includes("transfer amount exceeds balance")
      ) {
        toast.error("Insufficient balance to perform this transaction");
      } else if (error.code === "UNPREDICTABLE_GAS_LIMIT") {
        toast.error("Transaction may fail or may require manual gas limit");
      } else {
        toast.error(
          "An unexpected error occurred while during this Transaction"
        );
      }
      console.error("Error performing the transaction:", error);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const wallet = new Solflare();

  //TransferSOL
  // const TransferSOL = async (senderPublicKey, recipientAddress, amount) => {
  //   try {
  //     const toastId1 = toast.loading("Token transaction in progress", {
  //       duration: 5000,
  //     });
  //     await wallet.connect();

  //     setTimeout(() => {
  //       toast.dismiss(toastId1);
  //     }, 5000);

  //     const connection = new Connection(
  //       "https://red-special-sea.solana-mainnet.quiknode.pro/71805dc676a9b32991fd57b913fd3b7b6dff75f2",
  //       "confirmed"
  //     );

  //     const transaction = new Transaction().add(
  //       SystemProgram.transfer({
  //         fromPubkey: new PublicKey(senderPublicKey),
  //         toPubkey: new PublicKey(recipientAddress),
  //         lamports: amount * 10 ** 9,
  //       })
  //     );

  //     transaction.feePayer = new PublicKey(senderPublicKey);

  //     const { blockhash } = await connection.getRecentBlockhash();
  //     transaction.recentBlockhash = blockhash;

  //     const signedTransaction = await wallet.signTransaction(transaction);

  //     const serializedTransaction = signedTransaction.serialize();

  //     const signature = await sendAndConfirmRawTransaction(
  //       connection,
  //       serializedTransaction
  //     );

  //     await connection.confirmTransaction(signature, "confirmed");
  //     console.log("Transaction successful:", signature);

  //     const Check = {
  //       address: address,
  //       Token: selectedToken.symbol,
  //       amount: Amount,
  //       amountUSD: TokenAmount,
  //       CoinAmount: TokenAmount / Number(TokenUSDprice),
  //       CoinAmountUSD: TokenUSDprice,
  //       TokenHash: signature,
  //       network: "900",
  //       solanaWalletAddress: solanaWalletAddress,
  //       TokenStatus: "success",
  //     };
  //     console.log(Check);
  //     if (signature) {
  //       const CoinAmt = TokenAmount / Number(TokenUSDprice);
  //       const receiveTokens = await axios.get(
  //         `${NODE_URL}/apiv2/getMemeTokens?address=${walletAddress}&Token=${selectedToken.symbol}&amount=${Amount}&amountUSD=${TokenAmount}&CoinAmount=${CoinAmt}&CoinAmountUSD=${TokenUSDprice}&TokenHash=${signature}&network=900&solanaWalletAddress=${solanaWalletAddress}&TokenStatus=1`
  //       );
  //       console.log(receiveTokens);
  //       if (receiveTokens.data.status === "success") {
  //         toast.success(`${selectedToken.symbol} transaction successful`, {
  //           id: toastId1,
  //         });
  //         toast.success(
  //           "Your Tokens will be Claimable Once  Presale is Over!...."
  //         );
  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 5000);
  //       }
  //     } else {
  //       toast.error("Transaction failed", { id: toastId1 });
  //     }
  //   } catch (error) {
  //     toast.error("An unexpected error occurred while during this Transaction");
  //     console.error("Error performing the transaction:", error);
  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 1000);
  //     console.error("Transfer failed:", error);
  //   }
  // }; 

  const TransferTON = async (TON_ADMIN_ADDRESS, amount) => {
    try {
      const toastId1 = toast.loading("Token transaction in progress", {
        duration: 3000,
      });
      setTimeout(() => {
        toast.dismiss(toastId1);
      }, 2000);
      let amount1 = amount * 1e9;

      const transaction = {
        messages: [
          {
            address: TON_ADMIN_ADDRESS,
            amount: amount1.toString(),
          },
        ],
      };
      const response = await tonConnectUI.sendTransaction(transaction);

      console.log(response);
      const boc = response.boc;

      const cell = Cell.fromBoc(Buffer.from(boc, "base64"))[0];
      const signature = cell.hash().toString("hex");
      console.log("Transaction Hash:", signature);

      const Check = {
        address: address,
        Token: selectedToken.symbol,
        amount: Amount,
        amountUSD: TokenAmount,
        CoinAmount: TokenAmount / Number(TokenUSDprice),
        CoinAmountUSD: TokenUSDprice,
        TokenHash: signature,
        network: "622463",
        solanaWalletAddress: solanaWalletAddress,
        TokenStatus: "success",
      };
      console.log(Check);
      if (signature) {
        const CoinAmt = TokenAmount / Number(TokenUSDprice);
        const receiveTokens = await axios.get(
          `${NODE_URL}/apiv2/getMemeTokens?address=${walletAddress}&Token=${selectedToken.symbol}&amount=${Amount}&amountUSD=${TokenAmount}&CoinAmount=${CoinAmt}&CoinAmountUSD=${TokenUSDprice}&TokenHash=${signature}&network=622463&solanaWalletAddress=${solanaWalletAddress}&TokenStatus=1`
        );
        console.log(receiveTokens);
        if (receiveTokens.data.status === "success") {
          toast.success(`${selectedToken.symbol} transaction successful`, {
            id: toastId1,
          });
          toast.success(
            "Your Tokens will be Claimable Once  Presale is Over!...."
          );
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      } else {
        toast.error("Transaction failed", { id: toastId1 });
      }
    } catch (error) {
      toast.error("An unexpected error occurred while during this Transaction");
      console.error("Error performing the transaction:", error);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      console.error("Transfer failed:", error);
    }
  };

  const TransferTRON = async (TRON_ADMIN_ADDRESS, amount) => {
    if (!window.tronWeb) {
      toast.error("Please install Tron wallet.");
      return;
    }
    try {
      const toastId1 = toast.loading("Token transaction in progress", {
        duration: 3000,
      });
      setTimeout(() => {
        toast.dismiss(toastId1);
      }, 2000);

      const amountInSun = window.tronWeb.toSun(amount);
      const transaction = await window.tronWeb.trx.sendTransaction(
        TRON_ADMIN_ADDRESS,
        amountInSun
      );

      const signature = transaction.txid;
      let status;
      if (transaction.result) {
        status = 1;
      } else {
        status = 0;
      }
      const Check = {
        address: address,
        Token: selectedToken.symbol,
        amount: Amount,
        amountUSD: TokenAmount,
        CoinAmount: TokenAmount / Number(TokenUSDprice),
        CoinAmountUSD: TokenUSDprice,
        TokenHash: signature,
        network: "1000",
        solanaWalletAddress: solanaWalletAddress,
        TokenStatus: status,
      };
      console.log(Check);
      if (transaction) {
        const CoinAmt = TokenAmount / Number(TokenUSDprice);
        const receiveTokens = await axios.get(
          `${NODE_URL}/apiv2/getMemeTokens?address=${walletAddress}&Token=${selectedToken.symbol}&amount=${Amount}&amountUSD=${TokenAmount}&CoinAmount=${CoinAmt}&CoinAmountUSD=${TokenUSDprice}&TokenHash=${signature}&network=622463&solanaWalletAddress=${solanaWalletAddress}&TokenStatus=${status}`
        );
        console.log(receiveTokens);
        if (receiveTokens.data.status === "success") {
          toast.success(`${selectedToken.symbol} transaction successful`, {
            id: toastId1,
          });
          toast.success(
            "Your Tokens will be Claimable Once  Presale is Over!...."
          );
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      } else {
        toast.error("Transaction failed", { id: toastId1 });
      }
    } catch (error) {
      toast.error("An unexpected error occurred while during this Transaction");
      console.error("Error performing the transaction:", error);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      console.error("Transfer failed:", error);
    }
  };

  //TransferBTC
  const TransferBTC = async () => {
    try {
      setQrPopup(true);
    } catch (error) {
      console.error("Transfer failed:", error);
    }
  };

  const TransferSOL = async () => {
    try {
      setQrPopup(true);
    } catch (error) {
      console.error("Transfer failed:", error);
    }
  };

  const FetchUserAddress = async () => {
    const response = await axios.get(
      `${NODE_URL}/apiv2/checkAddress?address=${walletAddress}`
    );
    let res =
      response.data.length > 0 ? response.data[0].solanaWalletAddress : null;
    console.log(walletAddress, res);
    setSolanaWalletAddress(res);
  };

  const FetchPreOrder = async () => {
    try {
      const response = await axios.get(`${NODE_URL}/apiv2/FetchPreOrder`);

      console.log(response, "response from presale");
      let res = response.data.data[0];
      if (response.status === 200) {
        setPreOrder(res.PreOrder);
        setTokenUSDprice(res.TokenUSDprice);
        setMinQuantity(res.MinAmount);
        setMaxQuantity(res.MaxAmount);
        setPreOrderBalance(
          Number(res.PreOrder) - Number(response.data.totalCoinAmount)
        );
        setPreSaleStatus(res.PresaleStatus);
        let number =
          Number(res.PreOrder) - Number(response.data.totalCoinAmount);
        let cal = (number / res.PreOrder) * 100;
        setPercentage(100 - cal);
        // setPreOrder(500000); PresaleStatus
        // setTokenUSDprice(0.01);
        // setMinQuantity(0.01);
        // setMaxQuantity(1000);
        // setPreOrderBalance(
        //   Number(500000) - Number(response.data.totalCoinAmount)
        // );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    FetchPreOrder();

    const interval = setInterval(() => {
      FetchPreOrder();
    }, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (walletAddress !== null) {
      FetchUserAddress();
      fetchTokenSupply();

      const interval = setInterval(() => {
        fetchTokenSupply();
      }, 5 * 60 * 1000);

      return () => clearInterval(interval);
    }
  }, [walletAddress]);

  const fetchTokenSupply = async () => {
    try {
      console.log(RPC_URL, TOKEN_CONTRACT, NODE_URL);
      const provider = new ethers.providers.JsonRpcProvider(RPC_URL);
      const tokenContract = new ethers.Contract(TOKEN_CONTRACT, ABI, provider);
      const totalSupply = await tokenContract.totalSupply();
      const formattedSupply = ethers.utils.formatUnits(totalSupply, 18);
      console.log("Total Supply:", formattedSupply);
      setTokenSupply(formattedSupply);
    } catch (err) {
      console.error(err);
      setTokenSupply(null);
    }
  };

  const getSolanaTransaction = async (transactionHash, fromAddress) => {
    const connection = new Connection(
      "https://red-special-sea.solana-mainnet.quiknode.pro/71805dc676a9b32991fd57b913fd3b7b6dff75f2",
      "confirmed"
    );

    let data = await connection.getConfirmedTransaction(transactionHash);
    if (!data || !data.transaction || !data.meta) {
      return { status: "failed", message: "Invalid transaction data" };
    }

    const keys = data.transaction._message.accountKeys;
    const sender = new PublicKey(keys[0]);
    const receiver = new PublicKey(keys[1]);
    console.log(sender.toString(), receiver.toString());
    console.log(fromAddress, adminWallet);

    if (sender.toString() !== fromAddress) {
      return { status: "failed", message: "Sender not found" };
    }
    if (receiver.toString() !== adminWallet) {
      return { status: "failed", message: "Receiver not found" };
    }

    const date = new Date(data.blockTime * 1000);

    let totalTransferred = 0;
    const preBalances = data.meta.preBalances;
    const postBalances = data.meta.postBalances;
    const fee = data.meta.fee;

    for (let i = 0; i < preBalances.length; i++) {
      let diff = preBalances[i] - postBalances[i];
      if (diff > fee) {
        totalTransferred = diff - fee;
        break;
      }
    }
    console.log(totalTransferred, selectedToken.decimals);

    const amountTransferred = totalTransferred / 10 ** selectedToken.decimals;
    console.log(amountTransferred);
    setAmount(amountTransferred);

    return {
      status: "success",
      Date: date,
      Amount: amountTransferred,
    };
  };

  const handleConfirm = async () => {
    try {
      const toastId1 = toast.loading("Token transaction in progress", {
        duration: 5000,
      });

      const result = await axios.get(
        `${NODE_URL}/apiv2/getcheckHash?hash=${transactionHash}`
      );

      console.log(result);

      if (result.data.status === true) {
        let hashData;
        console.log(walletAddressType);

        if (walletAddressType === "BTC") {
          hashData = await getBitcoinTransaction(transactionHash, fromAddress);
        } else if (walletAddressType === "PHANTOM") {
          hashData = await getSolanaTransaction(transactionHash, fromAddress);
        }

        console.log(hashData);

        if (hashData.status === "failed") {
          toast.error(hashData.message, { id: toastId1 });
        } else {
          const Check = {
            address: walletAddress,
            Token: selectedToken.symbol,
            amount: hashData.Amount,
            amountUSD: TokenAmount,
            CoinAmount: TokenAmount / Number(TokenUSDprice),
            CoinAmountUSD: TokenUSDprice,
            TokenHash: transactionHash,
            network: walletAddressType === "BTC" ? "1" : "900",
            solanaWalletAddress: solanaWalletAddress,
            TokenStatus: "success",
          };

          const receiveTokens = await axios.get(
            `${NODE_URL}/apiv2/getMemeTokens?address=${walletAddress}&Token=${
              selectedToken.symbol
            }&amount=${Check.amount}&amountUSD=${TokenAmount}&CoinAmount=${
              Check.CoinAmount
            }&CoinAmountUSD=${TokenUSDprice}&TokenHash=${transactionHash}&network=${
              walletAddressType === "BTC" ? "1" : "900"
            }&solanaWalletAddress=${solanaWalletAddress}&TokenStatus=1`
          );

          if (receiveTokens.data.status === "success") {
            toast.success(`${selectedToken.symbol} transaction successful`, {
              id: toastId1,
            });
            toast.success(
              "Your Tokens will be Claimable Once Presale is Over!"
            );
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          }
        }
      } else {
        toast.error("Transaction already exists", { id: toastId1 });
      }
      setQrPopup(false);
      setConfirmPopup(false);
    } catch (error) {
      toast.error("An unexpected error occurred during this Transaction");
      console.error("Error performing the transaction:", error);
      setQrPopup(false);
      setConfirmPopup(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const getBitcoinTransaction = async (transactionHash, fromAddress) => {
    const url = `https://blockstream.info/testnet/api/tx/${transactionHash}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      console.log(data);
      if (!data || !data.vin || !data.vout) {
        return { status: "failed", message: "Invalid transaction data" };
      }

      let senderFound = false;
      let receiverFound = false;
      let totalTransferred = 0;

      const inputAddress = data.vin[0].prevout.scriptpubkey_address;
      console.log(inputAddress,fromAddress)
      if (inputAddress === fromAddress) {
        senderFound = true;
      }

      data.vout.forEach((output) => {
        if (output.scriptpubkey_address === adminWallet) {
          receiverFound = true;
          totalTransferred += output.value;
        }
      });

      if (!senderFound) {
        return { status: "failed", message: "Sender not found" };
      }
      if (!receiverFound) {
        return { status: "failed", message: "Receiver not found" };
      }

      const date = new Date(data.status.block_time * 1000);

      const amountTransferred = totalTransferred / 10 ** 8;
      console.log(amountTransferred);
      return {
        status: "success",
        Date: date,
        Amount: amountTransferred,
      };
    } catch (error) {
      return { status: "failed", message: error.message };
    }
  };

  // getBitcoinTransaction(
  //   "b00999625be600e4848023e7c01cef42dd9b7814190b1be1170c283481e0dd4c",
  //   "bc1qkn9ek2t9fzw0pykt9s8dt60ecr42vv4k7ls2hj"
  // );

  const handleCopy = () => {
    navigator.clipboard.writeText(adminWallet);
    toast.success("Address copied to clipboard!");
  };

  return (
    <div
      className="bg-cover bg-center bg-no-repeat bg-[#48335b] min-h-screen"
      style={{ backgroundImage: "url('/assets/Rectangle 9399 (2).png')" }}
    >
      <Header />
      <Toaster toastOptions={{ position: "top-center" }} />
      <div className="flex justify-center py-52 lg:px-10 px-3">
        <div
          className="lg:w-[80%]  bg-cover  bg-no-repeat   pb-10 px-5"
          style={{
            backgroundImage: "url('/assets/Rectangle 9399 (3).png')",
            borderBottom: "10px solid #c7c3c3",
            borderRadius: "51px",
          }}
        >
          <div className="lg:-translate-y-52 -translate-y-44">
            <div className="flex justify-center">
              <img className="lg:w-4/12" src="/assets/Group 1 (3).png"></img>
            </div>
            <p className="font-syne text-sm text-white text-center">
              Current Phase
            </p>
            <p className="font-impact lg:text-6xl  text-white text-center">
              Presale Stage 1
            </p>
            <p className="font-syne lg:text-3xl mt-3 font-bold text-white text-center">
              1 FOMO = {TokenUSDprice} USDT
            </p>
            <div className="flex justify-center">
              <div className="bg-[#d9d9d9] rounded-2xl lg:px-10 px-5 py-5">
                <p className="font-syne lg:text-2xl  font-bold text-black text-center">
                  {Number(PreOrderBalance).toFixed(3)}{" "}
                  <span className="text-[#4da9ff]"> / {PreOrder} FOMO</span>
                </p>
                <p className="font-syne lg:text-lg mt-3 font-bold text-black text-center">
                  Remaining meters Until Stage 1
                </p>
              </div>
            </div>
            <div className="py-10 flex justify-center">
              <img
                src="/assets/Group 1 (12).png"
                alt="Rocket"
                className="lg:w-7/12"
                style={{
                  left: `calc(${Percentage}% - 10px)`,
                  top: "50%",
                  transition: "left 0.3s ease-in-out",
                }}
              />
            </div>
            <p className="font-syne lg:text-3xl  font-bold text-white text-center">
              1 FOMO = {TokenUSDprice} USDT
            </p>
            <p className="font-syne lg:text-3xl mt-10 font-bold text-white text-center">
              Step 1: Select the payment method
            </p>

            <div className="lg:w-[50%] w-[80%] mx-auto grid md:grid-cols-2 gap-10 mt-10">
              {Tokens.map((data, index) => (
                <div key={index} className="relative inline-block">
                  <div
                    className={`cursor-pointer py-4 px-8 border-2 rounded-xl flex justify-between items-center ${
                      selectedIndex === index
                        ? "bg-[#396791] border-white brightness-100"
                        : `bg-[#396791] border-gray-400 ${
                            isAnyTokenSelected
                              ? "brightness-50"
                              : "brightness-100"
                          }`
                    }`}
                    onClick={() => handleDropdown(index)}
                  >
                    <div className="flex items-center">
                      <img
                        src={data.img}
                        className="w-10 h-10"
                        alt={`${data.name} icon`}
                      />
                      <div className="ml-3">
                        <p className="font-syne text-center text-white font-bold text-lg">
                          {data.name}
                        </p>
                        <p className="font-syne text-center text-white text-xs">
                          {data.symbol}
                        </p>
                      </div>
                    </div>
                    <i
                      className="fa-solid fa-chevron-down"
                      style={{ color: "#ffffff" }}
                    ></i>
                  </div>
                  {activeDropdown === index && (
                    <div className="absolute left-0 w-full bg-gray-300 border rounded-md py-2 px-4 z-10">
                      {data.dropdownItems.map((item, itemIndex) => (
                        <div key={itemIndex} className="px-8">
                          <div
                            className="flex items-center gap-2 pt-2 cursor-pointer"
                            onClick={() => choseTokens(index, item)}
                          >
                            <img
                              src={item.img}
                              className="w-5 h-5"
                              alt={`Dropdown item ${item.symbol}`}
                            />
                            <p className="text-sm">{item.symbol}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>

            <p className="font-syne lg:text-3xl mt-10 font-bold text-white text-center">
              Step 2: Enter the amount of token you would like to purchase
            </p>

            <div className="flex flex-col lg:flex-row justify-center gap-10 py-5 mt-5">
              <div className=" bg-[#396791] py-3 px-4 border-2 border-white rounded-xl flex justify-between items-center">
                <input
                  type="text"
                  placeholder="Enter Amount"
                  className="w-[85%] text-white text-sm md:text-xl px-3 bg-transparent focus:outline-none font-semibold"
                  onChange={(e) => handleInputChange(e)}
                />
                <div className="flex items-center gap-2">
                  <img
                    src={
                      selectedToken
                        ? selectedToken.img
                        : "https://polygonscan.com/token/images/tether_32.png"
                    }
                    alt={selectedToken ? selectedToken.symbol : ""}
                    className="md:w-8 md:h-8 w-5 h-5 border-none"
                  />
                  <div>
                    <p className="font-syne text-white font-bold md:text-lg">
                      {selectedToken ? selectedToken.symbol : "USDT"}
                    </p>
                  </div>
                </div>
              </div>
              <p className="text-7xl text-white font-bold text-center lg:text-left">
                =
              </p>
              <div className="lg:w-[35%] bg-[#396791] py-4 px-3 border-2 border-white rounded-xl flex justify-between items-center">
                <p className="lg:text-5xl w-[60%] md:text-2xl text-xl font-syne font-bold text-white overflow-x-scroll overflow-y-hidden custom-scrollbar1">
                  {TokenAmount
                    ? (TokenAmount / Number(TokenUSDprice)).toFixed(2)
                    : TokenUSDprice}
                </p>

                <div className="flex items-center ml-2">
                  <p className="text-white font-syne font-bold text-xl flex items-center">
                    FOMO&nbsp;
                    <img
                      className="w-4/12"
                      src="/assets/Group 1000015069.png"
                    ></img>
                  </p>
                </div>
              </div>
            </div>

            <div className="flex justify-center mt-20">
              <button
                className="text-[#fff] text-xl  font-impact rounded-2xl px-10 py-3 bg-[#db416b]"
                onClick={Transfer}
              >
                Buy Now
              </button>
            </div>
          </div>

          {showPopup && (
            <div className="fixed inset-0   font-syne  bg-gray-800 bg-opacity-50 flex items-center justify-center">
              <div className=" bg-[#0A0F21C2] p-8 rounded-xl shadow-2xl w-1/3">
                <h2 className="text-3xl font-bold mb-6  text-amber-500  font-semibold text-center">
                  Solana Wallet
                </h2>
                <form onSubmit={handleSubmit}>
                  <div className="mb-5">
                    <input
                      type="text"
                      id="wallet"
                      placeholder="Enter Your Solana Wallet Address"
                      className="w-full px-4 py-3 border border-indigo-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      value={solanaWalletAddress}
                      onChange={(e) => setSolanaWalletAddress(e.target.value)}
                      required
                    />
                  </div>
                  <div className="flex justify-center">
                    <button
                      type="submit"
                      className="px-5 py-2 bg-[#03D2FF] text-black rounded-full shadow-md transform hover:scale-105 transition-transform duration-300"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>

      {qrPopup && (
        <div className=" z-50 fixed inset-0 flex items-center  justify-center backdrop-blur-md">
          <div className="p-6 rounded-lg shadow-lg w-11/12 max-w-4xl border bg-[#363159] flex text-white">
            <div className="flex-1 py-8 flex items-center justify-center">
              <QRCode
                className="border-4 rounded-xl"
                value={adminWallet}
                size={200}
              />
            </div>
            <div className="flex-1 p-4 pl-6 flex flex-col justify-center items-center">
              <div className="flex items-center mb-4">
                <span className=" mr-2  text-bold text-md text-center">
                  {adminWallet}
                </span>
                <ClipboardDocumentIcon
                  className="w-5 h-5  cursor-pointer hover:text-gray-800 transition"
                  onClick={handleCopy}
                  title="Copy to clipboard"
                />
              </div>
              <div className=" mb-6 text-center">
                <p className="mb-1">
                  Amount: <span className="font-bold">{Amount}</span>
                </p>
                <p>
                  Token Name:{" "}
                  <span className="font-bold">
                    {selectedToken ? selectedToken.symbol : "USDT"}
                  </span>
                </p>
              </div>
              <div className="flex mt-6 justify-between w-full max-w-xs">
                <button
                  className="bg-[#F1456E] text-white px-4 py-2 rounded-lg shadow-md hover:bg-[#D93D57] transition w-full mr-2"
                  onClick={() => setConfirmPopup(true)}
                >
                  Paid
                </button>
                <button
                  className="bg-gray-300 text-gray-800 px-4 py-2 rounded-lg shadow-md hover:bg-gray-400 transition w-full ml-2"
                  onClick={() => setQrPopup(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {confirmPopup && (
        <div className=" z-50 fixed inset-0 flex items-center justify-center  p-2  backdrop-blur-md">
          <div className="relative w-full md:w-[50%] xl:w-[45%] border border-black p-6 rounded-xl bg-gradient-to-b from-[#D9D9D9] to-[#F97998]/60 shadow-lg  max-w-xlg">
            <button
              className="absolute text-2xl top-4 right-4 text-[#57345D] hover:text-gray-900 transition"
              onClick={() => {
                setQrPopup(false);
                setConfirmPopup(false);
              }}
            >
              &times;
            </button>
            <h2 className="text-black text-lg font-semibold mb-4 text-center">
              Confirm Payment
            </h2>
            <div className="mt-4 ">
              <label className="text-black text-lg font-semibold">
                Enter the wallet Address
              </label>
              <input
                type="text"
                placeholder="From Address"
                value={fromAddress}
                onChange={(e) => setFromAddress(e.target.value)}
                className="border-2 border-[#57345D] p-2 w-full mt-1 rounded-lg mb-2 focus:outline-none transition"
              />
            </div>
            <div className="mt-4 ">
              <label className="text-black text-lg font-semibold">
                Enter the Transaction Hash
              </label>
              <input
                type="text"
                placeholder="Transaction Hash"
                value={transactionHash}
                onChange={(e) => setTransactionHash(e.target.value)}
                className="border-2 border-[#57345D] p-2 w-full rounded-lg  mt-1 mb-4 focus:outline-none transition"
              />
            </div>
            <div className="flex justify-center">
              <button
                className="px-4 py-2 bg-[#F1456E] rounded-lg shadow-lg mt-4 text-white hover:bg-[#D93D57] transition"
                onClick={handleConfirm}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
