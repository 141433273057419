import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import Landing from './Components/Landing';
import Header from './Components/Header';
import Aboutus from './Components/About';
import Presale from './Components/Presale';
import NotFound from './Components/NotFound';
import Stake from './Components/Stake';
import TransHistory from './Components/TransHistory';
import Claim from './Components/Claim';
import AdminChat from './Admin/AdminChat';
import LoginPage from './Admin/Login';
import DashboardPage from './Admin/Dashboard';
import NotificationPage from './Admin/Notification';
import Platformhistory from './Admin/platformhistory';
import PollResults from './Admin/PollResults';
import ManagePoll from './Admin/ManagePoll';
import Airdrop from './Admin/Airdrop';
import ProtectedRoute from './ProtectedRoute';
import Settings from './Admin/Settings';
import Support from './Components/Support';

import UserDashboard from './Admin/UserDashboard';
import UserDetails from './Admin/UserDetails';
import AdminDashboard from './Admin/AdminDashboard'
import TokenSale from './Admin/TokenSale'
import Faq from './Components/FAQ';

function Routers() {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/Header" element={<Header />} />
      <Route path="/About-us" element={<Aboutus />} />
      <Route path='/sale/pre_sale_of_fomo_tokens/action=Pre_sale' element={<Presale/>}/>
      <Route path='/Stake/stake_of_fomo_tokens/action=stake' element={<Stake/>}/>
      <Route path='/Fomo_tokens_trans_history/q=trans_history' element={<TransHistory/>}/>
      <Route path='/Fomo_tokens_Stake_history_claim_tokens' element={<Claim/>}/>
      <Route path='/FOMO_tokens_Support' element={<Support/>}/>
      <Route path='/FAQ' element={<Faq/>}/>

      <Route path='/Admin/Support' element={
        <ProtectedRoute>
        <AdminChat />
        </ProtectedRoute>
        } />
      <Route exact path="/Admin/login" element={<LoginPage />} />
      <Route path="/Admin/dashboard" element={ <ProtectedRoute><DashboardPage /></ProtectedRoute>} />
      <Route path="/Admin/notification" element={ <ProtectedRoute><NotificationPage /></ProtectedRoute>} />
      <Route path="/Admin/platformhistory" element={ <ProtectedRoute><Platformhistory /></ProtectedRoute>} />
      <Route path="/Admin/notification/poll-results" element={ <ProtectedRoute><PollResults /></ProtectedRoute>} />
      <Route path="/Admin/notification/managepoll" element={ <ProtectedRoute><ManagePoll /></ProtectedRoute>} />
      <Route path="/Admin/airdrop" element={ <ProtectedRoute><Airdrop /></ProtectedRoute>} />
      <Route path="/Admin/Settings" element={ <ProtectedRoute><Settings /></ProtectedRoute>} />
      <Route path='/Admin/Support' element={ <ProtectedRoute><AdminChat/></ProtectedRoute>}/>
      <Route path="*" element={<NotFound />} />

      <Route path="/admin/userdashboard" element={ <ProtectedRoute><UserDashboard/></ProtectedRoute>}/>
      <Route path="/admin/userdetails" element={ <ProtectedRoute><UserDetails/></ProtectedRoute>}/>
      <Route path="/admin/admindashboard" element={ <ProtectedRoute><AdminDashboard/></ProtectedRoute>}/>
      <Route path="/admin/TokenSale" element={ <ProtectedRoute><TokenSale/></ProtectedRoute>}/>
    </Routes>
  );
}

export default Routers;