import React from "react";
import Header from "./Header";
import { useNavigate } from "react-router-dom";

const Aboutus = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`/sale/pre_sale_of_fomo_tokens/action=Pre_sale`);
  };

  return (
    <div
      className="font-san min-h-screen overflow-hidden overflow-y-auto bg-cover bg-center bg-no-repeat text-white relative"
      style={{ backgroundImage: 'url("/assets/bg2.jpeg")' }}
    >
      <div className="h-full bg-black bg-opacity-40">
        <div style={{ backgroundImage: 'url("/assets/Rectangle 9399 (1).png")' }}>
          {/* <img src="/assets/Rectangle 9399.png" className=" object-cover " /> */}
          <Header/>

          <div className="h-full right-0 md:p-10 p-3 ">
            <div className="sec-1 py-10">
              <h1
                className="md:text-7xl text-4xl text-center font-bold p-2"
                style={{
                  textShadow:
                    "1px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000",
                }}
              >
                Foktory of Memes - Where Your Memes Mint Millions
              </h1>

              <p className="md:w-[70%] mx-auto mt-10 text-center text-sm">
                At Foktory of Memes, we turn laughter into loot! Here’s the
                deal: you share your funniest meme and the FOMO holders get
                cheeky to vote on which ones should become a coin. Watch out for
                the launch and your pocket—it's about to get fatter than a
                Thanksgiving turkey  Remember, no idea is too silly! 
              </p>

              <div className=" mx-auto mt-10 flex md:flex-row flex-col gap-10 justify-center  ">
                <button
                  className="text-2xl font-bold bg-[#DB416B] rounded-lg py-2 px-14 "
                  style={{
                    textShadow:
                      "1px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000",
                  }}
                  onClick={handleBack}
                >
                  {" "}
                  Join presale
                </button>
                <button
                  className="text-2xl font-bold bg-transparent border rounded-lg py-2 px-14 "
                  style={{
                    textShadow:
                      "1px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000",
                  }}
                >
                  {" "}
                  White paper
                </button>
              </div>
            </div>

            <div className="sec-2 py-10">
              <div className="bg-[#D9D9D91F] rounded-md p-7 shadow-lg shadow-white/30">
                <div className="flex sm:flex-row flex-col justify-center sm:pl-28 ">
                  <p
                    className="text-5xl text-center font-bold"
                    style={{
                      textShadow:
                        "1px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000",
                    }}
                  >
                    About Us
                  </p>
                  <div className="flex justify-end sm:translate-y-3 -translate-y-6 translate-x-10  sm:-translate-x-12 ">
                    <img
                      src="/assets/Group 48096136 (2).png"
                      className=" scale-75 "
                    />
                  </div>
                </div>

                <div>
                  <p className="text-2xl font-bold py-2">
                    Nitty Gritty Of the New Meme Coin Creation 
                  </p>
                  <div className="">
                    <p>1. Idea Generation: </p>
                    <ul className="list-disc px-5">
                      <li className="">
                        Meme Magic: Dive into the dankest corners of the
                        internet to find the freshest, funniest memes that are
                        ripe for coinification. 
                      </li>
                      <li className="">
                        {" "}
                        Community Shenanigans: Let FOMO holders pitch and vote
                        on the wackiest meme coin ideas.  
                      </li>
                    </ul>

                    <p className="mt-2">2. Development: </p>
                    <ul className="list-disc pl-5">
                      <li className="">
                        Smart Contract Wizardry: Our team of geeky wizards will
                        code the bejeebers out of new meme coins, 
                      </li>
                      <li className="">
                        Design and Branding: Each new coin gets a snazzy look
                        and catchy name to make it the belle of the blockchain
                        ball. 
                      </li>
                    </ul>
                    <p className="mt-2">3. Launch: </p>
                    <ul className="list-disc pl-5">
                      <li className="">
                        Presale Hoopla: Start with a presale that gets everyone
                        as excited as a cat with a laser pointer. 
                      </li>
                      <li className="">
                        Hype Train: Launch a marketing blitz that'll make even
                        the most stoic face crack a smile (A viral dance
                        challenge? Iykyk)   Listing Shenanigans: Get our coins
                        listed on exchanges faster than you can say "to the
                        moon!" 
                      </li>
                    </ul>
                  </div>
                  <p className="text-2xl font-bold py-2">
                    Profit Reinvestment 
                  </p>
                  <div className="px-3">
                    <p>1. Revenue Streams: </p>
                    <ul className="list-disc pl-5">
                      <li>
                        Initial Sales: Cash in on the presale and initial coin
                        rush.  {" "}
                      </li>
                      <li>
                        Transaction Fees: Collect tiny bits from each
                        transaction {" "}
                      </li>
                      <li>
                        Crazy Collaborations: Team up with other meme-tastic
                        projects for mutual profit and giggles.
                      </li>
                    </ul>
                     <p>2.Reinvestment Mechanism: </p>
                    <ul className="list-disc pl-5">
                      <li>
                        {" "}
                        Buybacks: Use profits to buy back FOMO tokens, making
                        them as valuable as a limited-edition holographic
                        Charizard. {" "}
                      </li>
                      <li>
                        Staking Rewards: Loyal stakers are showered with
                        rewards.    
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;