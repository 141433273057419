import React, { useState, useEffect, useContext } from "react";
import Header from "./Header";
import axios from "axios";
import { NODE_URL, Contract_details, Network } from "./Config";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import toast from "react-hot-toast";
import { WalletContext } from "./WalletContext";

export default function TransHistory() {
  const { walletAddress, walletAddressType } = useContext(WalletContext);
  console.log(walletAddress, walletAddressType);
  const { address, chainId, isConnected } = useWeb3ModalAccount();

  const ConvertTime = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDateTime = date.toISOString().slice(0, 19).replace("T", " ");
    return formattedDateTime;
  };
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const FetchHistory = async () => {
      if (walletAddress !== null) {
        await axios
          .get(
            `${NODE_URL}/apiv2/getWalletTransactions?userAddress=${walletAddress}`,
            {
              headers: {
                "x-api-key": "nqojwskkskksluyhucgnxqggcewpachi",
              },
            }
          )
          .then((res) => {
            console.log(res);
            if (res.data.status == true) {
              setTransactions(res.data.data);
            } else {
              toast.error("unable to fetch History");
              return false;
            }
          });
      } else {
        console.error("Please connect Your Wallet");
        return false;
      }
    };
    FetchHistory();
  }, [walletAddress]);

  const getChain = (id, type, hash = null) => {
    // console.log(id, type, hash);
    let URL, SYMBOL;
    switch (id) {
      case Contract_details.POLYGON_CHAIN_ID:
        URL = `${Contract_details.POLYGON_EXPLORER_TX}${hash}`;
        SYMBOL = "POLYGON";
        break;
      case Contract_details.ETH_CHAIN_ID:
        URL = `${Contract_details.ETH_EXPLORER_TX}${hash}`;
        SYMBOL = "ETHEREUM";
        break;
      case Contract_details.BSC_CHAIN_ID:
        URL = `${Contract_details.BSC_EXPLORER_TX}${hash}`;
        SYMBOL = "BINANCE";
        break;
      case Contract_details.SOL_CHAIN_ID:
        URL = `${Contract_details.SOL_EXPLORER_TX}${hash}`;
        SYMBOL = "SOLANA";
        break;
      case Contract_details.TON_CHAIN_ID:
        URL = `${Contract_details.TON_EXPLORER_TX}${hash}`;
        SYMBOL = "TON";
        break;
      case Contract_details.TRON_CHAIN_ID:
        URL = `${Contract_details.TRON_EXPLORER_TX}${hash}`;
        SYMBOL = "TRON";
        break;
      case Contract_details.BTC_CHAIN_ID:
        URL = `${Contract_details.BTC_EXPLORER_TX}${hash}`;
        SYMBOL = "BTC";
        break;
      default:
        URL = `${Contract_details.BASE_EXPLORER_TX}${hash}`;
        SYMBOL = "BASE";
        break;
    }
    if (type == "Network") {
      return SYMBOL;
    } else {
      return URL;
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = transactions.slice(
    indexOfFirstEntity,
    indexOfLastEntity
  );
  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentEntities.length === entitiesPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  return (
    <div
      className="font-poppins bg-cover bg-center bg-no-repeat text-white"
      style={{ backgroundImage: 'url("/assets/bg2.jpeg")' }}
    >
      <div
        className="bg-black bg-opacity-40 min-h-screen overflow-hidden overflow-y-auto pb-10"
        style={{ backgroundImage: 'url("/assets/Rectangle 9399 (1).png")' }}
      >
        <Header />
        <div className="md:w-[90%] mx-auto border-2 border-[#787AA0] bg-[#291A58] backdrop-blur-[1px] rounded-3xl py-5 px-10 mt-5">
          <div className="flex justify-between pb-5">
            <div>
              <h1
                className="text-xl font-bold font-impact tracking-wider"
                style={{
                  WebkitTextStrokeWidth: "0.5px",
                  WebkitTextStrokeColor: "black",
                }}
              >
                Transaction History
              </h1>
            </div>
            <div className="flex items-center">
              <p className="text-end text-sm px-3 mb-2">
                Show no of entity
                <select
                  className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                  onChange={handleDropdownChange}
                  value={entitiesPerPage}
                >
                  <option className="text-black" value="5">
                    5
                  </option>
                  <option className="text-black" value="10">
                    10
                  </option>
                  <option className="text-black" value="20">
                    20
                  </option>
                </select>
              </p>
            </div>
          </div>
          <div className="pb-5 rounded-xl overflow-hidden overflow-x-auto">
            <table className="w-full text-center">
              <thead>
                <tr>
                  <th className="">Tokens Purchased</th>
                  <th className="">Date of transaction</th>
                  <th className="">Payment Network</th>
                  <th className="">Payment Mode</th>
                  <th className="">Amount Spent</th>
                  <th className="">Token Purchased</th>
                  <th className=""> Purchase Hash</th>
                  <th className=""> Payment Status</th>
                  <th className=""> Token Trans Hash</th>
                  <th className=""> Token Trans Status</th>
                </tr>
              </thead>
              <tbody>
                {currentEntities.map((transaction, index) => (
                  <tr
                    key={index}
                    className="h-16 text-sm md:text-base text-center rounded-md"
                  >
                    <td className="px-5 md:px-0 ">FOMO</td>
                    <td className={`px-5 md:px-0 font-bold`}>
                      {ConvertTime(transaction.createdAt)}
                    </td>
                    <td className="px-5 md:px-0 ">
                      {getChain(transaction.chainId, "Network")}
                    </td>
                    <td className="px-5 md:px-0 ">{transaction.Token}</td>
                    <td className="px-5 md:px-0 ">{transaction.TokenAmount}</td>
                    <td className="px-5 md:px-0 ">
                      {Number(transaction.CoinAmount).toFixed(3)}
                    </td>
                    <td className="px-5 md:px-0 ">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={getChain(
                          transaction.chainId,
                          "hash",
                          transaction.TokenHash
                        )}
                      >
                        {transaction.TokenHash
                          ? transaction.TokenHash.substring(0, 10) + "..."
                          : ""}
                      </a>
                    </td>
                    <td
                      className={`px-5 md:px-0 `}
                      style={{
                        color:
                          transaction.TokenStatus == "success"
                            ? "#00ff00"
                            : "#ff0000",
                      }}
                    >
                      <p>{transaction.TokenStatus}</p>
                    </td>
                    <td className="px-5 md:px-0">
                      {transaction.CoinHash ? (
                        <a
                          href={`${
                            Contract_details.BASE_EXPLORER_TX +
                            transaction.CoinHash
                          }`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {`${transaction.CoinHash.substring(0, 10)}...`}
                        </a>
                      ) : (
                        <span
                          style={{
                            color: "orange",
                          }}
                        >
                          Pending
                        </span>
                      )}
                    </td>
                    <td
                      className={`px-5 md:px-0 `}
                      style={{
                        color:
                          transaction.CoinStatus == "success"
                            ? "#00ff00"
                            : !transaction.CoinHash
                            ? "orange"
                            : "#ff0000",
                      }}
                    >
                      {!transaction.CoinStatus
                        ? "Pending"
                        : transaction.CoinStatus}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-2 flex justify-between text-xs px-3">
            <button
              className="bg-white/30 rounded-md px-5 py-1"
              onClick={handlePrevClick}
              disabled={currentPage === 1}
            >
              Prev
            </button>
            <button
              className="bg-white/30 rounded-md px-5 py-1"
              onClick={handleNextClick}
              disabled={currentEntities.length < entitiesPerPage}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
