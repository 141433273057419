// import React, { useState } from 'react'
// import Header from './Header';

// export default function Claim() {

//     const transactions = [
//         { TransactionHash: "0x123456789abcdef", Token: +14.02, Date: "10/05/2024", TransactionType: "Debit" },
//         { TransactionHash: "0xabcdef123456789", Token: -7.25, Date: "11/05/2024", TransactionType: "Credit" },
//         { TransactionHash: "0x987654321fedcba", Token: +30.15, Date: "12/05/2024", TransactionType: "Debit" },
//         { TransactionHash: "0xfedcba987654321", Token: -12.50, Date: "13/05/2024", TransactionType: "Credit" },
//         { TransactionHash: "0x456789abcdef123", Token: +8.00, Date: "14/05/2024", TransactionType: "Debit" }
//     ];

//     const [currentPage, setCurrentPage] = useState(1);
//     const [entitiesPerPage, setEntitiesPerPage] = useState(5);

//     const indexOfLastEntity = currentPage * entitiesPerPage;
//     const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
//     const currentEntities = transactions.slice(indexOfFirstEntity, indexOfLastEntity);
//     const emptyRowCount = entitiesPerPage - currentEntities.length;

//     const handlePrevClick = () => {
//         setCurrentPage(currentPage - 1);
//     };
//     const handleNextClick = () => {
//         if (currentEntities.length === entitiesPerPage) {
//             setCurrentPage(currentPage + 1);
//         }
//     };

//     const handleDropdownChange = (e) => {
//         setEntitiesPerPage(parseInt(e.target.value));
//         setCurrentPage(1);
//     };

//     return (
//         <div className='font-poppins bg-cover bg-center bg-no-repeat text-white' style={{ backgroundImage: 'url("/assets/bg2.jpeg")' }}>
//             <div className='bg-black bg-opacity-40 min-h-screen overflow-hidden  ' style={{ backgroundImage: 'url("/assets/Rectangle 9399 (1).png")' }}>
//             <Header/>
//                 <div className='pb-5 w-[90%] mx-auto flex justify-end'>
//                     <div className='p-7 bg-cover bg-center flex gap-5' style={{ backgroundImage: 'url("/assets/backgroundCard.png")' }}>
//                         <div>
//                             <p className='text-sm'>Balance</p>
//                             <p className='text-xl mt-1 font-bold'>45.500,12</p>
//                         </div>
//                         <div>
//                             <img src='/assets/Wallet.png'></img>
//                         </div>
//                     </div>
//                 </div>
//                 <div className='mx-auto md:w-[90%] flex flex-col md:flex-row gap-10'>
//                     <div className='md:w-[60%] flex flex-col gap-3 border-2 border-[#787AA0] bg-[#291A58] backdrop-blur-[1px] rounded-3xl py-5'>
//                         <div className='flex justify-between px-10 2xl:px-14 border-b border-white/40 pb-3'>
//                             <div>
//                                 <p className='text-xs'>Available to stake</p>
//                                 <p className='text-xl font-bold mt-2'>0.0 ETH</p>
//                             </div>
//                             <div className='flex items-center'>
//                                 <div className='rounded-md bg-black/40 px-5 h-8 text-xs flex items-center'>0xjyerfgi.........3ufhl2o3ifj</div>
//                             </div>
//                         </div>
//                         <div className='flex justify-between px-10 2xl:px-14'>
//                             <div>
//                                 <p className='text-xs'>Available to stake</p>
//                                 <p className='text-xl font-bold mt-2'>0.0 ETH</p>
//                             </div>
//                             <div className='w-[20%]'>
//                                 <p className='text-xs'>Lido APR</p>
//                                 <p className='text-xl font-bold mt-2'>3.9%</p>
//                             </div>
//                         </div>
//                         <div className='bg-[#170944] mx-7 p-5 2xl:px-10 rounded-md flex flex-col gap-5'>
//                             <div className='border border-white/50 rounded-md flex'>
//                                 <input className='w-[70%] bg-transparent py-2 outline-none  px-5  font-bold ' placeholder='Amount'></input>
//                                 <div className='my-auto text-end px-5 w-[30%] text-white/70'>MAX</div>
//                             </div>
//                             <div>
//                                 <button className='bg-[#DB416B] py-2 rounded-md font-impact w-full text-2xl' style={{
//                                     WebkitTextStrokeWidth: '0.75px',
//                                     WebkitTextStrokeColor: 'black',
//                                 }}>Deposit</button>
//                             </div>
//                             <div className='grid gap-1'>
//                             <p className='flex justify-between text-sm px-3'>You will receive <span className='font-bold w-[20%] text-center'>0</span></p>
//                             <p className='flex justify-between text-sm px-3'>Exchange rate <span className='font-bold w-[20%] text-center'>0</span></p>
//                             <p className='flex justify-between text-sm px-3'>Transaction Cost <span className='font-bold w-[20%] text-center'>0</span></p>
//                             <p className='flex justify-between text-sm px-3'>Reward fee <span className='font-bold w-[20%] text-center'>0</span></p>
//                             </div>
//                         </div>
//                     </div>
//                     <div className='md:w-[40%] grid content-between border-2 border-[#787AA0] bg-[#291A58] backdrop-blur-[1px] rounded-3xl py-10'>
//                         <div className='border-b border-white/30 font-bold flex justify-between pb-4 px-5'>
//                             <p className='text-lg'>FOMO statistics</p>
//                             <button className='text-[#DB416B]  text-sm '>View on ethereum</button>
//                         </div>
//                         <div className='px-7 grid gap-8'>
//                             <p className='flex justify-between'>Annual percentage rate <span className='font-bold'>0</span></p>
//                             <p className='flex justify-between'>Total staked with FOMO <span className='font-bold'>0</span></p>
//                             <p className='flex justify-between'>Stakers <span className='font-bold'>0</span></p>
//                             <p className='flex justify-between'>Steth market cap <span className='font-bold'>0</span></p>
//                         </div>
//                         <div className='px-5'>
//                             <button className='bg-[#DB416B] py-2 rounded-md font-impact w-full text-2xl' style={{
//                                 WebkitTextStrokeWidth: '0.75px',
//                                 WebkitTextStrokeColor: 'black',
//                             }}>Claim</button>
//                         </div>
//                     </div>
//                 </div>
//                 <div className='md:w-[90%] mx-auto border-2 border-[#787AA0] bg-[#291A58] backdrop-blur-[1px] rounded-3xl py-5 px-10 mt-10'>
//                     <div className='flex justify-between pb-5'>
//                         <div>
//                             <h1 className='text-xl font-bold font-impact tracking-wider' style={{
//                                 WebkitTextStrokeWidth: '0.5px',
//                                 WebkitTextStrokeColor: 'black',
//                             }}>Transaction History</h1>
//                         </div>
//                         <div className='flex items-center'>
//                             <p className='text-end text-sm px-3 mb-2'>Show no of entity
//                                 <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
//                                     <option className='text-black' value="5">5</option>
//                                     <option className='text-black' value="7">7</option>
//                                     <option className='text-black' value="10">10</option>
//                                 </select>
//                             </p>
//                         </div>
//                     </div>
//                     <div className='pb-5 rounded-xl overflow-hidden overflow-x-auto'>
//                         <table className='w-full text-center'>
//                             <thead>
//                                 <tr>
//                                     <th className='py-2 rounded-tl-xl px-3'>Transaction Hash</th>
//                                     <th className=''>Tokens</th>
//                                     <th className=''>Date of transaction</th>
//                                     <th className=''>Transaction Type</th>
//                                     <th className='py-2 rounded-tr-xl'></th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {currentEntities.map((transaction, index) => (
//                                     <tr key={index} className='h-16 text-sm md:text-base text-center rounded-md'>
//                                         <td className='px-5 md:px-0 '>{transaction.TransactionHash}</td>
//                                         <td className={`px-5 md:px-0 font-bold ${transaction.Token <= 0 ? 'text-[#FF0000]' : 'text-[#1ECB4F] '}`}>{transaction.Token}</td>
//                                         <td className='px-5 md:px-0 '>{transaction.Date}</td>
//                                         <td className='px-5 md:px-0 '>{transaction.TransactionType}</td>
//                                         <td className='px-5 md:px-0 '><img src={`assets/Graph${index % 2 + 1}.png`} className='mx-auto w-[80%]'></img></td>
//                                     </tr>
//                                 ))}
//                                 {/* Add empty rows if needed */}
//                                 {Array.from({ length: emptyRowCount }).map((_, index) => (
//                                     <tr key={`empty-${index}`} className='h-16'>
//                                         <td>&nbsp;</td>
//                                         <td>&nbsp;</td>
//                                         <td>&nbsp;</td>
//                                         <td>&nbsp;</td>
//                                         <td>&nbsp;</td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </table>
//                     </div>
//                     <div className='mt-2 flex justify-between text-xs px-3'>
//                         <button
//                             className='bg-white/30 rounded-md px-5 py-1'
//                             onClick={handlePrevClick}
//                             disabled={currentPage === 1}
//                         >
//                             Prev
//                         </button>
//                         <button
//                             className='bg-white/30 rounded-md px-5 py-1'
//                             onClick={handleNextClick}
//                             disabled={currentEntities.length < entitiesPerPage}
//                         >
//                             Next
//                         </button>
//                     </div>
//                 </div>
//                 {/* <div className='w-full modal-overlay fixed inset-0 absolute top-20 2xl:h-[1121.5px] lg:h-[1129px] h-[1570px] bg-black bg-opacity-10 backdrop-blur-md'>
//                     <div className='flex justify-center pt-20'>
//                        <img className='w-4/12' src='/assets/coming-soon.png'></img>
//                    </div>
//                 </div>  */}
//             </div>
//         </div>
//     )
// }

import React, { useEffect, useState, useContext } from "react";
import Header from "./Header";
import {
  CLAIM_CONTRACT,
  claimABI,
  NODE_URL,
  Contract_details,
  SignGenerate,
  ADMIN_KEY,
} from "./Config";
import axios from "axios";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers5/react";
import toast, { Toaster } from "react-hot-toast";
import $ from "jquery";
import { ethers, Wallet } from "ethers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faCheckCircle,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { WalletContext } from "./WalletContext";

function Claim() {
  const { walletAddress, walletAddressType } = useContext(WalletContext);
  console.log(walletAddress, walletAddressType);

  const [transactions, settransactions] = useState([]);
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const [claimStatus, setclaimStatus] = useState(false);
  const [claimAmount, setclaimAmount] = useState(0);

  useEffect(() => {
    if (walletAddress !== null) {
      fetchClaimHistory();
    }
  }, [walletAddress]);

  const fetchClaimHistory = async () => {
    try {
      console.log(walletAddress);
      const result = await axios.get(
        `${NODE_URL}/apiv2/getClaimHistory?address=${walletAddress}`
      );
      if (result.data.status == true) {
        settransactions(result.data.data);
      } else {
        settransactions([]);
      }
    } catch (err) {
      settransactions([]);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = transactions.slice(
    indexOfFirstEntity,
    indexOfLastEntity
  );
  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };
  const handleNextClick = () => {
    if (currentEntities.length === entitiesPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const [currentStep, setCurrentStep] = useState(1);

  const getButtonClass = (step) => {
    return currentStep >= step
      ? "bg-black text-white"
      : "bg-black/70 text-white";
  };

  const getLineClass = (step) => {
    return currentStep >= step ? "bg-black" : "bg-gray-300";
  };

  const getTextClass = (step) => {
    return currentStep >= step ? "text-white text-lg font-bold " : "text-white";
  };

  const handleStepClick = (step) => {
    setCurrentStep(step);
  };

  useEffect(() => {
    const fetchData = () => {
      fetchPresaleStatus();
      getClaimTokenDetails();
    };

    fetchData();
    if (walletAddress !== null) {
      fetchData();
    }
  }, [walletAddress]);

  const getClaimTokenDetails = async () => {
    try {
      if (walletAddress !== null) {
        console.log("coming");
        const result = await axios.get(
          `${NODE_URL}/apiv2/getClaimDetails?wallet=${walletAddress}`
        );
        console.log(result, "result of claim details");
        if (result.data.status == true) {
          setclaimAmount(result.data.totalPurchased);
        } else {
          setclaimAmount(0);
        }
      } else {
        // toast.error('Please connect your Wallet')
        setclaimAmount(0);
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchPresaleStatus = async () => {
    try {
      const result = await axios.get(`${NODE_URL}/apiv2/fetchPresaleStatus`);
      console.log(result, "result of pre sale status");
      if (result.data.status == true && result.data.result == "inactive") {
        setclaimStatus(true);
      } else {
        setclaimStatus(false);
      }
    } catch (err) {
      console.log(err);
      setclaimStatus(false);
    }
  };
  async function switchToBaseNetwork(chainId) {
    // if(walletAddressType === 'PHANTOM'){
    //   toast.error("Change the wallet");
    //   return false;
    // }
    console.log(chainId, Contract_details.BASE_CHAIN_ID);
    if (chainId === 8453) {
      return;
    } else {
      try {
        await walletProvider.request({
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: ethers.utils.hexValue(8453),
            },
          ],
        });
        toast.success("Network changed successfully");
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await walletProvider.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: ethers.utils.hexValue(
                    Contract_details.BASE_CHAIN_ID
                  ),
                  chainName: Contract_details.BASE_NAME,
                  nativeCurrency: {
                    name: Contract_details.BASE_NAME,
                    symbol: "ETH",
                    decimals: 18,
                  },
                  rpcUrls: [Contract_details.BASE_RPC],
                  blockExplorerUrls: [Contract_details.BASE_EXPLORER],
                },
                // {
                //   chainId: ethers.utils.hexValue(84532),
                //   chainName: "BASE SEPOLIA",
                //   nativeCurrency: {
                //     name: "BASE SEPOLIA",
                //     symbol: "ETH",
                //     decimals: 18,
                //   },
                //   rpcUrls: ["https://base-sepolia-rpc.publicnode.com"],
                //   blockExplorerUrls: ["https://sepolia.basescan.org/"],
                // },
              ],
            });
            toast.success("Network added and switched successfully");
          } catch (addError) {
            toast.error("Failed to add the network");
            console.error("Error adding the network:", addError);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        } else {
          toast.error("Failed to switch the network");
          console.error("Error switching the network:", switchError);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    }
  }
  const claimTokens = async () => {
    try {
      if (claimAmount <= 0) {
        toast.error(
          "You didnt have Anything to Claim..please purchase FOMO and Claim here"
        );
        return false;
      }
      $("#claim-Btn").css("cursor", "not-allowed");
      const claimContract = CLAIM_CONTRACT;
      const provider = new ethers.providers.Web3Provider(walletProvider);
      console.log(chainId, "chainId");
      // await switchToBaseNetwork(chainId);
      const signer = provider.getSigner();
      const contractInstance = new ethers.Contract(
        claimContract,
        claimABI,
        signer
      );
      let result = (claimAmount * 10 ** 18).toString();
      console.log(contractInstance);
      const sign = await SignGenerate(address, address, claimAmount);
      console.log(sign);
      // const gasLimit = await contractInstance.estimateGas(address,result,sign);
      // console.log(gasLimit);
      const gasPrice = await provider.getGasPrice();
      console.log(gasPrice);

      const response = await contractInstance.buyToken(address, result, sign, {
        gasPrice: gasPrice,
        gasLimit: 300000,
      });
      const responseHash = await response.wait();
      console.log(responseHash, "response");
      if (responseHash.status == 1 && responseHash.transactionHash) {
        const claiming = await axios.get(
          `${NODE_URL}/apiv2/updateClaimings?status=1&txnHash=${responseHash.transactionHash}&Amount=${claimAmount}&address=${address}`
        );
        console.log(claiming, "claiming response");
        if (claiming.data.status == true) {
          toast.success("FOMO Token  successfully claimed");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          toast.error("Something went wrong..please try again later");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } else {
        toast.error("Something went wrong..please try again later");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (err) {
      console.log(err);
      $("#claim-Btn").css("cursor", "pointer");
      toast.error("Something went wrong please try again later");
      return false;
    }
  };
  const SignGenerate = async (recipient, caller, amount) => {
    const provider = new ethers.providers.Web3Provider(walletProvider);
    let nonce = await getRandomNonce();
    let result = (amount * 10 ** 18).toString();
    let sign_wallet = new Wallet(ADMIN_KEY, provider);
    let signMessage = await generateSignhash(recipient, result, nonce);
    console.log(signMessage);
    let signature = await sign_wallet.signMessage(signMessage);
    signature = ethers.utils.splitSignature(signature);
    signature = [signature.v, signature.r, signature.s, nonce];
    return signature;
  };
  async function generateSignhash(account, amount, nonce) {
    let _signMessage = ethers.utils.solidityKeccak256(
      ["uint256", "address", "address", "uint256", "uint256"],
      [0, account, account, amount, nonce]
    );
    _signMessage = ethers.utils.arrayify(_signMessage);
    return _signMessage;
  }
  async function getRandomNonce() {
    return Math.floor(new Date().getTime() / 1000);
  }

  return (
    <div>
      <div
        className="font-poppins bg-cover bg-center bg-no-repeat text-white "
        style={{ backgroundImage: 'url("/assets/bg2.jpeg")' }}
      >
        <div
          className="bg-black bg-opacity-40 min-h-screen overflow-hidden pb-10 "
          style={{ backgroundImage: 'url("/assets/Rectangle 9399 (1).png")' }}
        >
          <Header />
          <Toaster toastOptions={{ position: "top-center" }} />
          <div className="flex justify-center mt-20">
            <div className="md:w-[40%] grid content-between border-2 border-[#787AA0] bg-[#291A58] backdrop-blur-[1px] rounded-3xl py-10">
              <div className="border-b border-white/30 font-bold flex justify-between pb-4 px-5">
                <p className="text-lg">FOMO Token Claim</p>
                <a className="text-[#DB416B]  text-sm ">View on Base Network</a>
              </div>
              <div className="px-7 grid gap-8 mt-2">
                <p className="flex justify-between">
                  Total FOMO Tokens Purchased{" "}
                  <span className="font-bold">
                    {claimAmount.toFixed(2)} FOMO
                  </span>
                </p>
                <p className="flex justify-between">
                  Presale Status{" "}
                  <span
                    style={{ color: claimStatus == true ? "green" : "red" }}
                    className="font-bold "
                  >
                    {" "}
                    {claimStatus ? "CLOSED" : "ACTIVE"}
                  </span>
                </p>
                <p className="flex justify-between">
                  Claiming Status{" "}
                  <span
                    style={{
                      color:
                        claimStatus == true && parseInt(claimAmount) > 0
                          ? "green"
                          : "red",
                    }}
                    className="font-bold"
                  >
                    {claimStatus && claimAmount > 0
                      ? "CLAIMABLE"
                      : "NON-CLAIMABLE"}
                  </span>
                </p>
                <p className="flex justify-between">
                  Final Claimable Tokens
                  <span className="font-bold">
                    {claimAmount.toFixed(2)} FOMO
                  </span>
                </p>
              </div>
              <div className="px-5 mt-5">
                <button
                  className="bg-[#DB416B] py-2 rounded-full font-impact w-full text-2xl"
                  id="claim-Btn"
                  disabled={claimStatus === false || claimAmount <= 0}
                  style={{
                    WebkitTextStrokeWidth: "0.75px",
                    WebkitTextStrokeColor: "black",
                  }}
                  onClick={
                    chainId === 8453 ? claimTokens : () => switchToBaseNetwork()
                  }
                >
                  {chainId === 8453 ? "Claim" : "Switch Network"}
                </button>

                {claimStatus === false ? (
                  <div className="text-red-500 mt-2 text-center">
                    The presale is not over yet.
                  </div>
                ) : (
                  claimAmount <= 0 && (
                    <p className="text-red-500 mt-2 text-center">
                      You don't have enough tokens to claim.
                    </p>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="md:w-[85%] w-[95%] mx-auto ">
            <div className="flex md:flex-row flex-col gap-5 md:gap-0 items-center text-lg justify-between mt-10">
              <p className="text-xl font-bold px-5">Transaction History</p>
              <div className="flex items-center">
                <p className="text-end text-sm px-3 mb-2">
                  Show no of entity
                  <select
                    className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                    onChange={handleDropdownChange}
                    value={entitiesPerPage}
                  >
                    <option className="text-black" value="5">
                      5
                    </option>
                    <option className="text-black" value="7">
                      7
                    </option>
                    <option className="text-black" value="10">
                      10
                    </option>
                  </select>
                </p>
              </div>
            </div>

            <div className="w-full bg-[#291A58B2] p-3 rounded-xl mt-5 overflow-hidden overflow-x-auto overflow-y-auto">
              <table className="w-full text-center">
                <thead>
                  <tr>
                    <th className="py-2 rounded-tl-xl px-3">
                      Transaction Hash
                    </th>
                    <th className="">Tokens Claimed</th>
                    <th className="">Date of transaction</th>
                    <th className="">Transaction Status</th>
                    <th className="py-2 rounded-tr-xl"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntities.map((transaction, index) => (
                    <tr
                      key={index}
                      className="h-16 text-sm md:text-base text-center rounded-md"
                    >
                      <td className="px-5 ">
                        <a
                          target="_blank"
                          className="text-sm font-bold"
                          href={`${
                            Contract_details.BASE_EXPLORER_TX +
                            transaction.adminTransactionHash
                          }`}
                        >
                          {transaction.adminTransactionHash}
                        </a>
                      </td>
                      <td className={`px-5 md:px-0 font-bold`}>
                        {transaction.totalQuantity
                          ? Number(transaction.totalQuantity).toFixed(3)
                          : 0}
                      </td>
                      <td className="px-5 md:px-0 ">{transaction.createdAt}</td>
                      <td
                        className="px-5 md:px-0 "
                        style={{
                          color:
                            transaction.status == "success"
                              ? "#00ff00"
                              : "#ff0000",
                        }}
                      >
                        {transaction.status}
                      </td>
                      <td className="px-5 md:px-0 "></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className=" flex justify-between px-5 text-xs pt-3">
                <button
                  className="bg-black/50 rounded-md px-5 py-1 mr-3 z-10"
                  onClick={handlePrevClick}
                  disabled={currentPage === 1}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button
                  className="bg-black/50 rounded-md px-5 py-1 z-10"
                  onClick={handleNextClick}
                  disabled={currentEntities.length < entitiesPerPage}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Claim;
