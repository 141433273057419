import React, { useState, useEffect, useRef } from "react";
import "animate.css";
import { NavLink } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";

export default function AdminHeader() {
  const [isDropdown, setDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const handleDropdown = () => {
    setDropdown((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setDropdown(false);
    }
  };

  useEffect(() => {
    if (isDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdown]);



  const [loggedIn, setLoggedIn] = useState(false);

 
const Logout = async() => {
    window.sessionStorage.removeItem('adminLoggedIn')
    window.location.href = '/admin/login'
}
  return (
    <div>
       <div className="hidden lg:block">
        <div className="flex justify-between bg-transparent border border-white items-center">
          <div className="w-[15%]  py-5"></div>
          <div className="w-[80%] flex justify-around gap-5 py-5">
            <NavLink
              to="/Admin/Dashboard"
              className="text-white text-xl font-architect"
            >
              Dashboard
            </NavLink>
            {/* <NavLink
              to="/Admin/notification"
              className="text-white text-xl font-architect"
            >
              Bot-Settings
            </NavLink> */}
            <NavLink
              to="/Admin/platformhistory"
              className="text-white text-xl font-architect"
            >
              History
            </NavLink>
            {/* <NavLink
              to="/Admin/notification/poll-results"
              className="text-white text-xl font-architect"
            >
              Poll-Results
            </NavLink> */}
            {/* <NavLink to="/Admin/notification/managepoll" className="text-white text-xl font-architect">
              Poll-Settings
            </NavLink> */}
            {/* <NavLink
              to="/Admin/airdrop"
              className="text-white text-xl font-architect"
            >
              Airdrop
            </NavLink> */}
            <NavLink
              to="/Admin/Settings"
              className="text-white text-xl font-architect"
            >
              Token-Settings
            </NavLink>
            <NavLink
              to="/Admin/Support"
              className="text-white text-xl font-architect"
            >
              Support
            </NavLink>
            
          </div>
          <div className="w-[15%] flex justify-center border border-white py-5 ">
            <button
              onClick={Logout}
              className="text-white text-xl font-architect rounded-xl px-3 py-1 border border-white hover:shadow-md hover:shadow-white"
            >
              Logout
            </button>
          </div>
        </div>
      </div>

      <div className="block lg:hidden">
        <div className="flex justify-between px-10 py-10">
          <div></div>
          <div className="ease-in-out duration-300">
            <button onClick={handleDropdown} ref={buttonRef}>
              <i className="fas fa-bars text-white fa-2xl"></i>
            </button>
          </div>
          {isDropdown && (
            <div
              className="dropdown-content text-black z-50 w-[15rem] bg-black absolute right-5 mt-2 top-20 animate__animated animate__fadeInUp flex justify-center"
              ref={dropdownRef}
            >
              <div className=" flex flex-col font-architect gap-3 text-xl  text-white rounded-lg p-5">
                <NavLink
                  to="/Admin/Dashboard"
                  onClick={() => setDropdown(false)}
                >
                  Dashboard
                </NavLink>
                {/* <NavLink
                  to="/Admin/notification"
                  onClick={() => setDropdown(false)}
                >
                  Bot-Settings
                </NavLink> */}
                <NavLink
                  to="/Admin/platformhistory"
                  onClick={() => setDropdown(false)}
                >
                  History
                </NavLink>
                {/* <NavLink
                  to="/Admin/notification/poll-results"
                  onClick={() => setDropdown(false)}
                >
                  Poll-Results
                </NavLink> */}
                {/* <NavLink to="/Admin/notification/managepoll" onClick={() => setDropdown(false)}>
                  Poll-Settings
                </NavLink> */}
                <NavLink to="/Admin/airdrop" onClick={() => setDropdown(false)}>
                  Airdrop
                </NavLink>
                <NavLink
                  to="/Admin/Settings"
                  onClick={() => setDropdown(false)}
                >
                  Token-Settings
                </NavLink>
                <NavLink
              to="/Admin/Support"
              onClick={() => setDropdown(false)}
            >
              Support
            </NavLink>
                <div>
                  <button onClick={Logout} className="text-white text-xl font-architect rounded-xl px-3 py-1 border border-white hover:shadow-md hover:shadow-white">
                    Logout
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
