import React, { useEffect, useState, useContext } from "react";
import Header from "./Header";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers5/react";
import {
  BASE_API_KEY,
  Contract_details,
  NODE_URL,
  STAKE_CONTRACT,
  stakeABI,
  TOKEN_CONTRACT,
} from "./Config";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { BigNumber, ethers } from "ethers";
import ABI from "./ERC20Token.json";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faCheckCircle,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { WalletContext } from "./WalletContext";

export default function Stake() {
  const { walletAddress, walletAddressType } = useContext(WalletContext);
  console.log(walletAddress, walletAddressType);

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [tokenBalance, settokenBalance] = useState(0);
  const [stakeReward, setstakeReward] = useState(0);
  const [stakeAmount, setstakeAmount] = useState(0);
  const [stakedUser, setstakedUser] = useState(false);
  const [rewardAmount, setrewardAmount] = useState(0.0);
  const [transactions, settransactions] = useState([]);
  const [stakedStatus, setstakedStatus] = useState(false);

  const getTokenBalance = async () => {
    try {
      console.log("coming", walletAddress);
      if (walletAddress) {
        const bal = await axios.get(
          `https://api-sepolia.basescan.org/api?module=account&action=tokenbalance&contractaddress=${TOKEN_CONTRACT}&address=${walletAddress}&tag=latest&apikey=${BASE_API_KEY}`
        );
        console.log(bal.data);
        if (bal.data.status === '1') {
          console.log(bal.data.result / 10 ** 18);
          settokenBalance(bal.data.result / 10 ** 18);
        }
      } else {
        settokenBalance(0);
        setstakeAmount(0);
        setstakeReward(0);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTokenBalance();
    fetchStakeDetails();
    fetchtransactionHistory();
    if (walletAddress !== null) {
      checkStakeStatus();
    } 
  }, [walletAddress]);

  async function fetchtransactionHistory() {
    try {
      if (walletAddress) {
        const data = await axios.get(
          `${NODE_URL}/apiv2/getStakeTrans?address=${walletAddress}`
        );
        if (data.data.status === true) {
          settransactions(data.data.data);
        } else {
          settransactions([]);
        }
      } else {
        settransactions([]);
      }
    } catch (err) {
      settransactions([]);
    }
  }

  async function switchToBaseNetwork(chainId) {
    if (chainId === 84532) {
      return;
    } else {
      try {
        await walletProvider.request({
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: ethers.utils.hexValue(84532),
            },
          ],
        });
        toast.success("Network changed successfully");
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await walletProvider.request({
              method: "wallet_addEthereumChain",
              params: [
                // {
                //   chainId: ethers.utils.hexValue(Contract_details.BASE_CHAIN_ID),
                //   chainName: Contract_details.BASE_NAME,
                //   nativeCurrency: {
                //     name: Contract_details.BASE_NAME,
                //     symbol: 'ETH',
                //     decimals: 18,
                //   },
                //   rpcUrls: [Contract_details.BASE_RPC],
                //   blockExplorerUrls: [Contract_details.BASE_EXPLORER],
                // },
                {
                  chainId: ethers.utils.hexValue(84532),
                  chainName: "BASE SEPOLIA",
                  nativeCurrency: {
                    name: "BASE SEPOLIA",
                    symbol: "ETH",
                    decimals: 18,
                  },
                  rpcUrls: ["https://base-sepolia-rpc.publicnode.com"],
                  blockExplorerUrls: ["https://sepolia.basescan.org/"],
                },
              ],
            });
            toast.success("Network added and switched successfully");
          } catch (addError) {
            toast.error("Failed to add the network");
            console.error("Error adding the network:", addError);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        } else {
          toast.error("Failed to switch the network");
          console.error("Error switching the network:", switchError);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    }
  }

  const stakeTokens = async () => {
    try {
      if (walletAddress === null) {
        toast.error("please connect your wallet");
        return false;
      }
      if (stakedStatus === true) {
        toast.error("withdraw the staked Amount");
        return false;
      }
      if (tokenBalance < stakeAmount) {
        toast.error(
          `Your FOMO token balance is ${tokenBalance.toFixed(
            2
          )} which is Less than the Entered Staking Amount`
        );
        return false;
      }
      if (stakeAmount < 100) {
        toast.error("Minimum staking Amount is 100 FOMO");
        return false;
      }
      $("#stake-Btn").prop("disabled", true).css("cursor", "not-allowed");
      const provider = new ethers.providers.Web3Provider(walletProvider);
      await switchToBaseNetwork(chainId);
      const signer = await provider.getSigner();
      const TokencontractInstance = new ethers.Contract(
        TOKEN_CONTRACT,
        ABI,
        signer
      );
      const tokenApprove = await TokencontractInstance.approve(
        STAKE_CONTRACT,
        (stakeAmount * 10 ** 18).toString()
      );
      const approveResult = await tokenApprove.wait();
      console.log(approveResult);
      if (approveResult.status === 1) {
        const StakecontractInstance = new ethers.Contract(
          STAKE_CONTRACT,
          stakeABI,
          signer
        );
        console.log(StakecontractInstance, "stake instnce");
        const gasPrice = await provider.getGasPrice();
        console.log("Gas Price:", gasPrice.toString());
        const Amount = ethers.utils.parseUnits(stakeAmount.toString(), 18);
        let gasLimit;
        try {
          gasLimit = await StakecontractInstance.estimateGas.stake(Amount);
        } catch (error) {
          console.warn(
            "Gas estimation failed, using default gas limit:",
            error
          );
          gasLimit = 300000;
        }
        console.log("Gas Limit:", gasLimit.toString());
        const transaction = await StakecontractInstance.stake(Amount, {
          gasLimit,
          gasPrice,
        });
        const response = await transaction.wait();
        if (response.status === 1) {
          console.log(response, "stake response");
          const stakeResponse = await axios.get(
            `${NODE_URL}/apiv2/StakeTokens?stakedAmount=${stakeAmount}&address=${walletAddress}&stakeHash=${response.transactionHash}`
          );
          if (stakeResponse.data.status === true) {
            toast.success(
              `Staking Process Completed for ${stakeAmount} FOMO tokens`
            );
            setTimeout(() => {
              // window.location.reload();
            }, 1000);
          } else {
            toast.error(
              "Something went wrong while processing..try again later"
            );
            setTimeout(() => {
              // window.location.reload();
            }, 1000);
          }
        } else {
          toast.error("Something went wrong while processing..try again later");
          setTimeout(() => {
            // window.location.reload();
          }, 1000);
        }
        console.log(response, "response");
      } else {
        toast.error("Something went wrong while processing..try again later");
        setTimeout(() => {
          // window.location.reload();
        }, 1000);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong while Staking..please try again later");
      setTimeout(() => {
        // window.location.reload();
      }, 1000);
    }
  };

  const fetchStakeDetails = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        Contract_details.BASE_RPC
      );
      const contractInstance = new ethers.Contract(
        STAKE_CONTRACT,
        stakeABI,
        provider
      );
      const rewards = await contractInstance.getUserDetails(walletAddress);
      console.log(rewards);
      console.log(parseInt(rewards.rewardAmount) / 10 ** 18,parseInt(rewards[0].withdrawAmount) / 10 ** 18)
      console.log(parseInt(rewards[0].amount) / 10 ** 18,parseInt(rewards[0].initialTime) / 10 ** 18,parseInt(rewards[0].rewardAmount) / 10 ** 18)

      if (rewards && rewards.rewardAmount !== 0) { 
        const stakereward = parseInt(rewards.rewardAmount) / 10 ** 18;
        console.log(stakereward, "reward");
        setrewardAmount(stakereward); 
        setstakedUser(true);
      } else {
        setstakedUser(false);
        setrewardAmount(0.0);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const checkStakeStatus = async () => {
    const fetchStakedAmount = await axios.get(
      `${NODE_URL}/apiv2/fetchStakedAmount?address=${walletAddress}`
    );
    console.log(fetchStakedAmount);
    if (fetchStakedAmount.data.status === true) {
      setstakedStatus(true);
    } else {
      setstakedStatus(false);
    }
  };

  console.log(stakeAmount);
  console.log(stakedUser, rewardAmount);

  const withdrawTokens = async () => {
    try {
      if (walletAddress === null) {
        toast.error("please connect your wallet");
        return false;
      }
      if (!stakedUser) {
        toast.error("You are not a staked user");
        return false;
      }
      const fetchStakedAmount = await axios.get(
        `${NODE_URL}/apiv2/fetchStakedAmount?address=${walletAddress}`
      );
      let WithdrawStakeAmount;
      if (fetchStakedAmount.data.status === true) {
        WithdrawStakeAmount = fetchStakedAmount.data.data.stakeAmount;
      } else {
        WithdrawStakeAmount = 0;
      }
      console.log(WithdrawStakeAmount, "stakedAmiount");
      $("#withdraw-Btn").prop("disabled", true).css("cursor", "not-allowed");

      const provider = new ethers.providers.Web3Provider(walletProvider);
      await switchToBaseNetwork(chainId);
      const signer = await provider.getSigner();
      const TokencontractInstance = new ethers.Contract(
        STAKE_CONTRACT,
        stakeABI,
        signer
      );
      await fetchStakeDetails();
      const gasPrice = await provider.getGasPrice();
      const gasLimit = 100000;
      const tokenApprove = await TokencontractInstance.withdraw(
        (WithdrawStakeAmount * 10 ** 18).toString(),{
          gasLimit,
          gasPrice,
        });
      const approveResult = await tokenApprove.wait();
      console.log(approveResult);
      if (approveResult.status === 1) {
        const withdraw = await axios.get(
          `${NODE_URL}/apiv2/withdrawStake?address=${walletAddress}&withdrawHash=${approveResult.transactionHash}&rewardAmount=${rewardAmount}`
        );
        if (withdraw.data.status === true) {
          toast.success("Rewards withdrawn Successfully");
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
        } else {
          toast.error("Something went wrong while stake rewards withdraw");
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
        }
      } else {
        toast.error("You are not a staked user");
        $("#withdraw-Btn").prop("disabled", false).css("cursor", "pointer");
        return false;
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong while stake rewards withdraw");
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = transactions.slice(
    indexOfFirstEntity,
    indexOfLastEntity
  );
  const emptyRowCount = entitiesPerPage - currentEntities.length;
  
  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentEntities.length === entitiesPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const [currentStep, setCurrentStep] = useState(1);

  const getButtonClass = (step) => {
    return currentStep >= step
      ? "bg-black text-white"
      : "bg-black/70 text-white";
  };

  const getLineClass = (step) => {
    return currentStep >= step ? "bg-black" : "bg-gray-300";
  };

  const getTextClass = (step) => {
    return currentStep >= step ? "text-white text-lg font-bold " : "text-white";
  };

  const handleStepClick = (step) => {
    setCurrentStep(step);
  };

  return (
    <div className="bg-[#DB416B]/40 font-poppins bg-cover bg-center bg-no-repeat text-white min-h-screen">
      <Header />
      <Toaster toastOptions={{ position: "top-center" }} />

      <div className="bg-[#DB416B]/40 backdrop-blur-[2px]  min-h-screen ">
        <div className="pb-5 w-[90%] mx-auto flex justify-end">
          <div
            className="p-7 bg-cover bg-center flex gap-5"
            style={{ backgroundImage: 'url("/assets/backgroundCard.png")' }}
          >
            <div>
              <p className="text-sm">Balance</p>
              <p className="text-lg mt-1 font-bold">
                {tokenBalance.toFixed(2)} FOMO
              </p>
            </div>
            <div>
              <img src="/assets/Wallet.png"></img>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-center gap-10 px-10">
          <div className="mx-auto md:w-[35%] flex flex-col gap-7 border-2 border-[#787AA0] bg-[#291A58]/85 backdrop-blur-[1px] rounded-3xl p-10">
            <div className="border border-white/50 rounded-md flex">
              <div className="flex items-center px-5 w-[30%]">Enter Amount</div>
              <input
                type="text"
                min="100"
                className="w-[70%] bg-transparent py-2 outline-none px-5 text-end font-bold placeholder:text-white"
                onChange={(e) => {
                  const value = e.target.value;
                  const regex = /^[0-9]*\.?[0-9]*$/;
                  if (value === "" || value === "0") {
                    setstakeAmount(value);
                  } else if (regex.test(value)) {
                    console.log(value * 0.14);
                    const Rewards = (value * 14) / 100;
                    setstakeReward(Number(value) + Number(Rewards));
                    setstakeAmount(value);
                  }
                }}
                value={stakeAmount}
              />
            </div>
            <div className="flex">
              <div className="flex items-center justify-center w-[50%]">
                <p className="text-lg">Staking Rewards - 14 % APY</p>
              </div>
              <div className="text-center w-[50%] text-s">
                <p>
                  You will get {stakeReward ? stakeReward.toFixed(2) : 0.0} FOMO
                  / year
                </p>
                {/* <p>Ends - <span>10/07/2024</span></p> */}
              </div>
            </div>
            {/* <div>
                    <p className='text-lg text-end md:w-[85%] mx-auto'>Value <span className='px-3'>=</span><span className='font-bold'>99USDT</span></p>
                </div> */}
            <div>
              <button
                className="bg-[#DB416B] py-2 rounded-md font-impact w-full text-2xl"
                id="stake-Btn"
                onClick={stakeTokens}
                disabled={tokenBalance < 100}
                style={{
                  WebkitTextStrokeWidth: "0.75px",
                  WebkitTextStrokeColor: "black",
                }}
              >
                Stake
              </button>
            </div>
          </div>
          <div className="mx-auto md:w-[35%] flex flex-col gap-7 border-2 border-[#787AA0] bg-[#291A58]/85 backdrop-blur-[1px] rounded-3xl p-10">
            {/* <div className='border border-white/50 rounded-md flex'>
                    <div className='flex items-center px-5 w-[30%]'>Enter Amount</div>
                    <input
                type="text" min="100"
                className="w-[70%] bg-transparent py-2 outline-none px-5 text-end font-bold placeholder:text-white" 
                onChange={(e) => {
                    const value = e.target.value;
                    const regex = /^[0-9]*\.?[0-9]*$/;
                    if (value === "" || value === "0") {
                        setstakeAmount(value); 
                    } else if (regex.test(value)) {
                        console.log(value * 0.14)
                        const Rewards = value * 14/100;
                        setstakeReward(Number(value) + Number(Rewards))
                        setstakeAmount(value); 
                    }
                }}
                value={stakeAmount}
                />              
                </div> */}
            <div className="flex">
              <div className="flex items-center justify-center w-[50%]">
                <p className="text-lg">Staking Rewards - 14 % APY</p>
              </div>
              <div className="text-center w-[50%] text-s">
                {stakedUser ? (
                  <p>
                    You will get{" "}
                    <span className="text-blue-500">
                      {rewardAmount ? rewardAmount.toFixed(4) : 0.0}
                    </span>{" "}
                    FOMO if you withdraw Now
                  </p>
                ) : (
                  <p>
                    You are Not a Staked User..please Stake first and try
                    Withdraw
                  </p>
                )}
                {/* <p>Ends - <span>10/07/2024</span></p> */}
              </div>
            </div>
            {/* <div>
                    <p className='text-lg text-end md:w-[85%] mx-auto'>Value <span className='px-3'>=</span><span className='font-bold'>99USDT</span></p>
                </div> */}
            <div>
              <button
                className="bg-[#DB416B] py-2 rounded-md font-impact w-full text-2xl"
                id="withdraw-Btn"
                onClick={withdrawTokens}
                disabled={!setstakedUser}
                style={{
                  WebkitTextStrokeWidth: "0.75px",
                  WebkitTextStrokeColor: "black",
                }}
              >
                Withdraw Tokens
              </button>
            </div>
          </div>
        </div>
        <div className="px-3 lg:px-10">
          <div className="w-full bg-[#291A58B2] rounded-xl mt-5 overflow-hidden overflow-x-auto overflow-y-auto ">
            <table className="w-full text-center">
              <thead>
                <tr>
                  <th className="lg:text-xl text-sm py-2 rounded-tl-xl px-3">
                    Stake TxnHash
                  </th>
                  <th className="lg:text-xl text-sm">Tokens Staked</th>
                  <th className="lg:text-xl text-sm">Date of transaction</th>
                  <th className="lg:text-xl text-sm">Staked Status</th>
                  <th className="lg:text-xl text-sm">Stake Rewards</th>
                  <th className="lg:text-xl text-sm">Total Returns</th>
                  <th className="lg:text-xl text-sm">Withdraw Hash</th>
                  <th className="lg:text-xl text-sm">Withdraw Status</th>

                  <th className="py-2 rounded-tr-xl"></th>
                </tr>
              </thead>
              <tbody>
                {currentEntities.map((transaction, index) => (
                  <tr
                    key={index}
                    className="h-16 text-sm md:text-base text-center rounded-md"
                  >
                    <td className="px-5 md:px-0 ">
                      <a
                        target="_blank"
                        className="text-blue-400"
                        href={`${
                          Contract_details.BASE_EXPLORER_TX +
                          transaction.stakeHash
                        }`}
                      >
                        {transaction.stakeHash.substring(0, 10)}...
                      </a>
                    </td>
                    <td className={`px-5 md:px-0 font-bold`}>
                      {transaction.stakeAmount ? transaction.stakeAmount : 0}
                    </td>
                    <td className="px-5 md:px-0 ">{transaction.createdAt}</td>
                    <td
                      className="px-5 md:px-0 "
                      style={{
                        color:
                          transaction.stakeStatus == "success"
                            ? "green"
                            : "red",
                      }}
                    >
                      {transaction.stakeStatus}
                    </td>
                    <td className="px-5 md:px-0 ">
                      {transaction.rewards
                        ? Number(transaction.rewards).toFixed(4)
                        : 0}
                    </td>
                    <td className="px-5 md:px-0 ">
                      {transaction.rewards
                        ? (
                            Number(transaction.rewards) +
                            Number(transaction.stakeAmount)
                          ).toFixed(4)
                        : 0}
                    </td>
                    <td className="px-5 md:px-0 ">
                      <a
                        target="_blank"
                        className="text-blue-400"
                        href={`${
                          transaction.withdrawHash
                            ? Contract_details.BASE_EXPLORER_TX +
                              transaction.withdrawHash
                            : "#"
                        }`}
                      >
                        {transaction.withdrawHash
                          ? transaction.withdrawHash.substring(0, 10) + "..."
                          : "PENDING"}
                      </a>
                    </td>
                    <td
                      className="px-5 md:px-0 "
                      style={{
                        color:
                          transaction.withdrawStatus == "success"
                            ? "green"
                            : "orange",
                      }}
                    >
                      {transaction.withdrawStatus == "success"
                        ? "SUCCESS"
                        : "PENDING"}
                    </td>

                    <td className="px-5 md:px-0 "></td>
                  </tr>
                ))}
                {Array.from({ length: emptyRowCount }).map((_, index) => (
                  <tr key={`empty-${index}`} className="h-16">
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className=" flex justify-end px-5 text-xs pt-3">
            <button
              className="bg-black/50 rounded-md px-5 py-1 mr-3 z-10"
              onClick={handlePrevClick}
              disabled={currentPage === 1}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <button
              className="bg-black/50 rounded-md px-5 py-1 z-10"
              onClick={handleNextClick}
              disabled={currentEntities.length < entitiesPerPage}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </div>
        {/* <div className='w-full modal-overlay fixed inset-0 absolute top-20 2xl:h-[1121.5px] lg:h-[1129px] h-[1570px] bg-black bg-opacity-30 backdrop-blur-xl'>
                <div className='flex justify-center pt-20'>
                   <img className='lg:w-4/12' src='/assets/coming-soon.png'></img>
               </div>
            </div>  */}
      </div>

      <div className="w-full lg:h-[858px] h-[1250px] modal-overlay fixed inset-0 absolute  top-[90px] z-10 bg-black bg-opacity-30 backdrop-blur-xl ">
        <div className="flex justify-center pt-32">
          <img className="lg:w-4/12" src="/assets/coming-soon.png"></img>
        </div>
      </div>
    </div>
  );
}
