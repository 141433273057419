import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import "animate.css";
import "../App.css";

const Header_admin = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleWidth = () => {
    setExpanded(!expanded);
  };

  const [showOptions, setShowOptions] = useState(false);

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const [isDropdown, setDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const handleDropdown = () => {
    setDropdown((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setDropdown(false);
    }
  };

  useEffect(() => {
    if (isDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdown]);

  const Logout = async () => {
    window.sessionStorage.removeItem("adminLoggedIn");
    window.location.href = "/admin/login";
  };

  return (
    <>
      <div
        className={` ${
          expanded ? "w-[5%]" : "w-[100%]"
        } flex flex-col justify-between ease-in-out duration-500 bg-no-repeat bg-cover  bg-center  font-inter min-h-screen lg:block hidden`}
        style={{ backgroundImage: "url('/assets/fomo-adminside.png')" }}
      >
        <Toaster toastOptions={{ position: "top-right" }} />
        <div>
          <div className="flex justify-end pt-5 relative"></div>
          <div className="px-3 mt-5">
            <div className="flex flex-col gap-3 items-center">
              <img
                src="/assets/Bitmap.png"
                className=" w-14 h-14 rounded-full"
              />
              <div className="flex flex-col gap-1 overflow-hidden">
                <p className=" text-white text-sm text-center font-poppins font-bold">
                  ADMIN
                </p>
                <p className=" text-white text-sm text-center font-poppins font-bold">
                  admin@fomo.com
                </p>
              </div>
            </div>
          </div>

          <hr className=" my-3 mx-3" />

          <div className="px-5 text-xs">
            <ul className="flex flex-col justify-center mt-2 ">
              <li className="hover:bg-[#2e2e31] hover:rounded-lg px-1 py-3 flex items-center gap-3 text-xs ">
                <a
                  href="/Admin/admindashboard"
                  className="text-white font-poppins overflow-hidden font-semibold"
                >
                  Master Admin
                </a>
              </li>

              <li className="hover:bg-[#2e2e31] hover:rounded-lg px-1 py-3 flex items-center gap-3 text-xs ">
                <a
                  href="/Admin/Userdashboard"
                  className="text-white font-poppins overflow-hidden font-semibold"
                >
                  User Management
                </a>
              </li>

              <li className="hover:bg-[#2e2e31] hover:rounded-lg px-1 py-3 flex items-center gap-3 text-xs ">
                <a
                  href="/Admin/Settings"
                  className="text-white font-poppins overflow-hidden font-semibold"
                >
                Token Sale
                </a>
              </li>

              <li className="hover:bg-[#2e2e31] hover:rounded-lg px-1 py-3 flex items-center gap-3 text-xs ">
                <a
                  href="/Admin/Support"
                  className="text-white font-poppins overflow-hidden font-semibold"
                >
                  Chat App
                </a>
              </li>
              <li className="hover:bg-[#2e2e31] hover:rounded-lg px-1 py-3 flex items-center gap-3 text-xs ">
                <a
                  href="/Admin/Notification"
                  className="text-white font-poppins overflow-hidden font-semibold"
                >
                  Bot-Settings
                </a>
              </li>
            </ul>
          </div>
          <hr className="border-[#4a3aff] my-5 mx-3" />
          <div className="px-2  ">
            <button
              className=" text-white rounded-md px-3 mt-5  w-40 py-2 bg-[#DB416B] font-semibold font-impact tracking-wider text-stroke"
              style={{
                WebkitTextStrokeColor: "black",
                WebkitTextStrokeWidth: "0.5px",
              }}
              onClick={Logout}
            >
              Log Out
            </button>
          </div>
        </div>

        <ReactTooltip anchorId="Dashboard" place="top" content="Dashboard" />

        <ReactTooltip anchorId="Audience" place="top" content="Audience" />

        <ReactTooltip anchorId="Posts" place="top" content="Posts" />

        <ReactTooltip anchorId="Schedules" place="top" content="Schedules" />

        <ReactTooltip anchorId="Income" place="top" content="Income" />

        <ReactTooltip anchorId="Settings" place="top" content="Settings" />

        <ReactTooltip anchorId="Logout" place="top" content="Logout" />
      </div>
      <div
        className="block lg:hidden  w-[100%] "
        style={{ backgroundImage: "url('/assets/Rectangle 39938.png')" }}
      >
        <div className="flex justify-between px-10 py-10">
          <div></div>
          <div className="ease-in-out duration-300">
            <button onClick={handleDropdown} ref={buttonRef}>
              <i className="fas fa-bars text-white fa-2xl"></i>
            </button>
          </div>
          {isDropdown && (
            <div
              className="dropdown-content text-black z-50 w-[15rem] bg-black absolute right-5 mt-2 top-20 animate__animated animate__fadeInUp flex justify-center"
              ref={dropdownRef}
            >
              <div className=" flex flex-col font-architect gap-3 text-xl  text-white rounded-lg p-5">
                <NavLink
                  to="/Admin/admindashboard"
                  onClick={() => setDropdown(false)}
                >
                  Admin Dashboard
                </NavLink>

                <NavLink
                  to="/Admin/Userdashboard"
                  onClick={() => setDropdown(false)}
                >
                  User Management
                </NavLink>

                <NavLink
                  to="/Admin/Settings"
                  onClick={() => setDropdown(false)}
                >
                  Token Sale
                </NavLink>
                <NavLink to="/Admin/Support" onClick={() => setDropdown(false)}>
                  Chat App
                </NavLink>
                <div>
                  <button
                    className="text-white text-xl bg-[#DB416B] font-architect rounded-xl px-3 py-1 border border-white hover:shadow-md hover:shadow-white text-stroke"
                    style={{
                      WebkitTextStrokeColor: "black",
                      WebkitTextStrokeWidth: "0.5px",
                    }}
                    onClick={Logout}
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Header_admin;