// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUser, faLock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

// const Login = () => {
//   const [password, setPassword] = useState('');
//   const [visible, setVisible] = useState(false);
//   const [email, setEmail] = useState('');
//   const [errors, setErrors] = useState({ email: '', password: '' });

//   const predefinedEmail = 'fabcglobal@gmail.com';
//   const predefinedPassword = '12345';

//   const navigate = useNavigate();

//   const handlePasswordVisibility = () => {
//     setVisible(!visible);
//   };

//   const validate = () => {
//     const emailError = email ? '' : 'Please provide your email address or username';
//     const passwordError = password ? '' : 'Please provide your password';
//     setErrors({ email: emailError, password: passwordError });

//     if (email === predefinedEmail && password === predefinedPassword) {
//       navigate('/admin/dashboard');
//     } else {
//       setErrors({ email: 'Invalid email or password', password: 'Invalid email or password' });
//     }
//   };

//   return (
//     <div
//       className="bg-black relative bg-no-repeat bg-center bg-cover min-h-screen"
//       style={{ backgroundImage: 'url("/assets/bcg-image.png")' }}
//     >
//       <div className='flex justify-center items-center mx-auto bg-gradient-rb from-[#C9C9C9] to-[#C4C4C4] py-10'>
//         <div className='xl:w-[40%] lg:w-[50%] sm:w-3/4 w-full md:px-10 px-2 sm:px-4 justify-center flex items-center'>
//           <div className='flex flex-col lg:w-[90%] md:w-[95%] w-full justify-center items-center h-auto p-6 rounded-lg bg-gradient-to-b from-[#C9C9C9]/10 to-[#C4C4C4]/20 border-2'>
//             <p className='text-2xl font-bold font-Artemus text-white text-start inline-block w-full underline decoration-white'>LogIn</p>
//             <p className='text-lg font-bold mt-4 text-white text-start inline-block w-full font-Anton SC'>Welcome Onboard with us!.</p>
//             <div className='mt-8 sm:text-sm text-xs w-full'>
//               <label className='text-white font-Artemus tracking-wider font-bold'>Username</label>
//               <div className='relative mt-2 w-full'>
//                 <input
//                   className='w-full focus:outline-none rounded-md bg-[#D9D9D921] pl-14 p-2 text-[#bcbfc6] font-bold border border-[#7c7c7c]'
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                   placeholder='Enter Your Username'
//                 />
//                 <div className='absolute top-0 left-0 flex justify-center items-center h-[100%] w-[60px]'>
//                   <FontAwesomeIcon icon={faUser} size="lg" style={{ color: "#bcbfc6" }} />
//                 </div>
//               </div>
//               {errors.email && <p className='text-red-500'>{errors.email}</p>}
//             </div>

//             <div className='mt-8 sm:text-sm text-xs w-full'>
//               <label className='text-white font-Artemus tracking-wider font-bold'>Password</label>
//               <div className='relative mt-2 w-full'>
//                 <input
//                   className='w-full focus:outline-none rounded-md bg-[#D9D9D921] pl-14 p-2 text-[#bcbfc6] font-bold border border-[#7c7c7c]'
//                   placeholder='Enter Your Password'
//                   type={visible ? "text" : "password"}
//                   value={password}
//                   onChange={(e) => setPassword(e.target.value)}
//                 />
//                 <div className='absolute top-0 left-0 flex justify-center items-center h-[100%] w-[60px]'>
//                   <FontAwesomeIcon icon={faLock} size="lg" style={{ color: "#bcbfc6" }} />
//                 </div>
//                 <button onClick={handlePasswordVisibility} className='absolute top-0 right-0 flex justify-center items-center h-[100%] w-[60px]'>
//                   {visible ? <FontAwesomeIcon icon={faEye} size="lg" style={{ color: "#bcbfc6" }} /> : <FontAwesomeIcon icon={faEyeSlash} size="lg" style={{ color: "#bcbfc6" }} />}
//                 </button>
//               </div>
//               {errors.password && <p className='text-red-500'>{errors.password}</p>}
//             </div>

//             <div className='flex items-center justify-end mt-2 md:w-[85%] sm:w-[95%] w-full'>
//               <div className='flex justify-center items-center md:text-sm text-xs gap-3'>
//                 <p className='text-white font-bold'>Forgot Password?</p>
//               </div>
//             </div>

//             <div className='flex flex-col justify-center items-center mt-10 w-full'>
//               <button onClick={validate} className='bg-[#00FFC2D9] text-black w-full flex justify-center items-center sm:py-3 py-2 border rounded-xl border-none md:text-xl text-sm font-bold uppercase font-Artemus'>
//                 LogIn
//               </button>
//             </div>

//             <div className='mt-4 w-full flex items-center justify-center'>
//               <p className='text-slate-200 text-sm flex items-center font-bold'>New to Login? <a href='#' className='text-white mx-2 font-bold'>Register</a> Here</p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faLock,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setvisible] = useState(false);

  const handlepassword = () => {
    setvisible(!visible);
  };

  console.log(email, password);

  const handleLogin = async () => {
    if (!email) {
      toast.error("Please Enter the Email");
    }
    if (!password) {
      toast.error("Please Enter the Password");
    }
    try {
      if(email == 'admin@fomo.com' && password == 'Fomo@123'){
        toast.success("Login Successfull");
        sessionStorage.setItem("adminLoggedIn", true);
        setTimeout(() => {
          window.location.href = "/Admin/admindashboard";
        }, 1000);
      }
      else {
        toast.error("Invalid Credentials");
      }
    } catch (error) {
      toast.error("Invalid Credentials");
      console.error("Error during login:", error);
    }
  };



  return (
    <div
      className="bg-black relative bg-no-repeat bg-center bg-cover min-h-screen"
      style={{ backgroundImage: 'url("/assets/bcg-image.png")' }}
    >
      <Toaster toastOptions={{ position: "top-center" }} />
      <div className="flex justify-center items-center mx-auto bg-gradient-rb from-[#C9C9C9] to-[#C4C4C4] py-10 pt-36">
        <div className=" lg:w-[35%] sm:w-3/4 w-full md:px-10 px-2 sm:px-4 justify-center flex items-center">
          <div className="flex flex-col lg:w-[90%] md:w-[95%] w-full justify-center items-center h-auto p-6 rounded-lg bg-gradient-to-b from-[#C9C9C9]/10 to-[#C4C4C4]/20 border-2">
            <p className="text-2xl font-bold font-Artemus text-white text-start inline-block w-full underline decoration-white">
              LogIn
            </p>
            <p className="text-lg font-bold mt-4 text-white text-start inline-block w-full font-Anton SC">
              Welcome Onboard with us!.
            </p>
            <div className="mt-8 sm:text-sm text-xs w-full">
              <label className="text-white font-Artemus tracking-wider font-bold">
                Email ID
              </label>
              <div className="relative mt-2 w-full">
                <input
                  className="w-full focus:outline-none rounded-md bg-[#D9D9D921] pl-14 p-2 text-white font-bold border border-[#7c7c7c]"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter Your Username"
                />
                <div className="absolute top-0 left-0 flex justify-center items-center h-[100%] w-[60px]">
                  <FontAwesomeIcon
                    icon={faUser}
                    size="lg"
                    style={{ color: "#bcbfc6" }}
                  />
                </div>
              </div>
            </div>

            <div className="mt-8 sm:text-sm text-xs w-full">
              <label className="text-white font-Artemus tracking-wider font-bold">
                Password
              </label>
              <div className="relative mt-2 w-full">
                <input
                  className="w-full focus:outline-none rounded-md bg-[#D9D9D921] pl-14 p-2 text-white font-bold border border-[#7c7c7c]"
                  placeholder="Enter Your Password"
                  type={visible ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className="absolute top-0 left-0 flex justify-center items-center h-[100%] w-[60px]">
                  <FontAwesomeIcon
                    icon={faLock}
                    size="lg"
                    style={{ color: "#bcbfc6" }}
                  />
                </div>
                <button
                  onClick={handlepassword}
                  className="absolute top-0 right-0 flex justify-center items-center h-[100%] w-[60px]"
                >
                  {visible ? (
                    <FontAwesomeIcon
                      icon={faEye}
                      size="lg"
                      style={{ color: "#bcbfc6" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faEyeSlash}
                      size="lg"
                      style={{ color: "#bcbfc6" }}
                    />
                  )}
                </button>
              </div>
            </div>

            <div className="flex flex-col justify-center items-center mt-10 w-full">
              <button
                onClick={handleLogin}
                className="bg-[#db416b] text-white w-full flex justify-center items-center sm:py-3 py-2 border rounded-xl border-none md:text-xl text-sm  uppercase font-impact"
              >
                LogIn
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
