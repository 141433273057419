import React, { useState, useEffect } from "react";

const initialDate = new Date("2024-09-06T00:00:00"); // Set your initial target date here

const Countdown = () => {
  const getInitialTargetTime = () => {
    const storedTargetTime = localStorage.getItem("targetTime");
    return storedTargetTime ? new Date(storedTargetTime) : initialDate;
  };

  const [targetTime, setTargetTime] = useState(getInitialTargetTime);

  const calculateTimeLeft = (targetDate) => {
    const currentTime = new Date(); // Use current system time
    const difference = targetDate - currentTime;

    if (difference <= 0) {
      return { days: "00", hours: "00", minutes: "00", seconds: "00" };
    } else {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      return {
        days: days.toString().padStart(2, "0"),
        hours: hours.toString().padStart(2, "0"),
        minutes: minutes.toString().padStart(2, "0"),
        seconds: seconds.toString().padStart(2, "0"),
      };
    }
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetTime));

  useEffect(() => {
    const updateCountdown = () => {
      const newTimeLeft = calculateTimeLeft(targetTime);

      if (
        newTimeLeft.days === "00" &&
        newTimeLeft.hours === "00" &&
        newTimeLeft.minutes === "00" &&
        newTimeLeft.seconds === "00"
      ) {
        const newTargetTime = new Date(targetTime.getTime() + 3 * 24 * 60 * 60 * 1000); // Extend by 3 days from target time
        setTargetTime(newTargetTime);
        localStorage.setItem("targetTime", newTargetTime); // Save new targetTime to localStorage
      } else {
        setTimeLeft(newTimeLeft);
      }
    };

    const interval = setInterval(updateCountdown, 1000); // Update every second
    return () => clearInterval(interval); // Clear interval on component unmount
  }, [targetTime]);
  return (
    <div className="flex justify-center items-center space-x-2 md:space-x-4 px-5 md:px-7 py-5">
      {Object.entries(timeLeft).map(([unit, value], index, array) => (
        <div className="flex">
          <div
            key={index}
            className="flex flex-col items-center bg-white border border-black  w-[50px] md:w-[60px] p-1"
            style={{ boxShadow: "5px 5px black" }}
          >
            <div className="flex space-x-1 ">
              {value.split("").map((char, i) => (
                <div
                  key={i}
                  className={`font-syne font-semibold  text-black rounded-md  font-digital md:text-3xl  flex justify-center items-center `}
                >
                  {char}
                </div>
              ))}
            </div>
            <div className="text-xs 2xl:text-sm font-medium font-share text-black">
              {unit.toUpperCase()}
            </div>
          </div>
          <div>
            {index < array.length - 1 && (
              <div className="text-3xl font-bold h-full ml-2 md:ml-5 flex items-center justify-center">
                :
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Countdown;
