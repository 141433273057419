import React, { useState } from "react";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "./Header";

const Faq = () => {
  const faq = [
    {
      question: "What is the Faktory of Memes Org. (FOMO)?",
      answer:
        "The Faktory of Memes Org. (FOMO) is a unique and playful ecosystem where we blend the power of memes with the magic of cryptocurrency. We're all about creating, minting, and launching a variety of meme coins inspired by the hottest trends on the internet. But it doesn't stop there—we reinvest profits back into our main token, FOMO, to ensure our community benefits as much as possible. Think of us as the meme coin factory that never stops having fun!",
    },
    {
      question: "How does FOMO work?",
      answer:
        "Well, it's as simple as this: We create the hottest, most ridiculous memes on the internet, sprinkle a little crypto magic on them, and—voilà!—a new meme coin is born. Then we sell these coins to folks like you who can't resist a good meme (or a good investment). Profits get reinvested into making more memes, buying back FOMO tokens, and keeping the whole operation going. It's basically a meme-making machine that never runs out of juice. And yes, we're aware of how absurdly awesome that sounds.",
    },
    {
      question: "How can I get involved in FOMO?",
      answer:
        "It’s easy! Start by buying our main token, FOMO, during one of our presale events. Once you're part of the FOMO family, you can participate in voting for new meme coin ideas, stake your tokens to earn rewards, and join in on all the fun in our community events. We welcome all creative minds, so come on in and make some memes with us!",
    },
    {
      question: "What makes FOMO different from other meme coins?",
      answer:
        "FOMO isn’t just another meme coin; it’s a whole ecosystem dedicated to creating and launching new meme coins on a regular basis. We reinvest profits into the community, involve our holders in decision-making, and create a fun and engaging environment for everyone involved. Plus, with our unique cast of characters and our commitment to creativity, FOMO stands out as a leader in the meme coin space.",
    },
    {
      question:
        "How do I buy FOMO tokens?",
      answer:
        "You can purchase FOMO tokens during our presale events, which are announced on our website and social media channels. After the presale, FOMO tokens will be available on various decentralized and centralized exchanges. Be sure to follow us for updates so you don’t miss out!",
    },
    {
      question: "What are the benefits of holding FOMO tokens?",
      answer:
        "Besides bragging rights? Holding FOMO tokens gives you a front-row seat to the future of meme coins. You get to vote on what memes we turn into coins, stake your tokens for rewards, and be part of a community that’s all about having fun and making money. Plus, as we reinvest profits into the ecosystem, the value of your tokens could go up—because who doesn’t love seeing their digital wallet get fatter?",
    },
    {
      question: "How can I stay updated on new projects and announcements?",
      answer:
        "Stay in the loop by following us on social media, joining our Telegram or Discord channels, and regularly visiting our website. We’re always sharing the latest news, upcoming events, and exciting developments in the FOMO ecosystem. Don’t miss out—be part of the conversation!",
    },
    {
      question: "Can I suggest a meme for a new coin?",
      answer:
        "Of course! We’re always on the lookout for the next viral sensation. If you’ve got a meme or trend you think should be immortalized as a coin, drop us a line! If the community loves it, we might just turn your idea into the next big thing in crypto. And who knows, you might become a meme coin legend in the process. Our community members can propose ideas, and if your suggestion gains enough support, it could become the next big hit in the FOMO ecosystem and your projects marketing will be funded buy Gizmo.We hope this F.A.Q. section answers all your questions about the Faktory of Memes Org. If you have more questions or just want to chat, feel free to reach out through our community channels. We’re always here to help and to share a laugh!"

    },
  ];

  const [expandedIndex, setExpandedIndex] = useState(-1);

  const toggleExpand = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(-1);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <div
      className="font-san bg-center bg-cover w-full h-screen overflow-hidden overflow-y-auto bg-no-repeat text-white"
      style={{ backgroundImage: 'url("/assets/bg2.jpeg")' }}
    >
      <div className=" bg-[#363159] bg-opacity-70">

        <div
          className="bg-no-repeat bg-cover min-h-screen "
          style={{ backgroundImage: "url(../assets/Rectangle_9399.png)" }}
        >
        <Header/>

          <div className="flex justify-center items-center px-10 md:gap-5">
            <p
              className=" sm:text-4xl text-2xl text-center font-bold"
              style={{
                textShadow:
                  "1px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000",
              }}
            >
              Frequently Ask Questions
            </p>
            <img src="../assets/image_182.png" className="sm:w-20 w-16" />
          </div>

          <div className="md:w-[75%] w-[90%] mx-auto bg-[#291a5898] rounded-xl sm:px-10 px-5 mt-5 pb-10 pt-5 ">
            {faq.map((data, index) => (
              <div
                key={index}
                onClick={() => toggleExpand(index)}
                className={` duration-500 p-3 transform transition ease-in-out border-b-2 cursor-pointer ${
                  expandedIndex === index ? "h-full" : "h-full"
                }`}
              >
                <div className="flex ">
                  <div className=" flex gap-5 items-center">
                    <div className="flex items-center justify-center">
                      <button className="font-bold">
                        {expandedIndex === index ? (
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className={`transform transition-transform duration-500 text-sm`}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className={`transform transition-transform duration-500 text-sm`}
                          />
                        )}
                      </button>
                    </div>
                    <h1 className="font-bold py-2">{data.question}</h1>
                  </div>
                </div>
                <div
                  className={`duration-300 ease-in-out overflow-hidden ${
                    expandedIndex === index
                      ? "pb-3 max-h-[300px] px-10 pt-2  rounded-b-md"
                      : "max-h-0"
                  }`}
                >
                  <p className="text-sm font-medium text-[#EFEFEB] duration-500">
                    {data.answer}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;