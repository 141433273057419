import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useNavigate,
} from "react-router-dom";
import axios from "axios";
import AdminHeader from "./AdminHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import Layout from "./Layout";
import toast, { Toaster } from "react-hot-toast";
import { TELEGRAM_URL1, TELEGRAM_URL2, NODE_URL } from "../Components/Config";

export default function Notification() {
  const [channelId, setChannelId] = useState('1274995781966893076');
  const [scheduleDate, setScheduleDate] = useState("");
  const [scheduleTime, setScheduleTime] = useState("");
  const [recurring, setRecurring] = useState(false);
  const [embedTitle, setEmbedTitle] = useState("");
  const [embedTitleUrl, setEmbedTitleUrl] = useState("");
  const [embedDescription, setEmbedDescription] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [footerIcon, setFooterIcon] = useState("");
  const [footerText, setFooterText] = useState("");
  const [fields, setFields] = useState([]);
  const [embeddedText, setEmbeddedText] = useState("");

  // Poll state variables
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState(["", ""]);
  const [dateTime, setDateTime] = useState("");

  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [cronTime, setCronTime] = useState("");
  const [message, setMessage] = useState("");
  const [pollOptions, setPollOptions] = useState([""]);
  const [announcement, setAnnouncement] = useState({
    message: "",
    date: "",
    time: "",
  });
  const [poll, setPoll] = useState({ question: "", date: "", time: "" });
  const [cryptoTime, setCryptoTime] = useState("");
  const navigate = useNavigate();

  // Announcement functions
  const handleAddField = () => {
    const newField = { fieldTitle: "", fieldContent: "" };
    setFields([...fields, newField]);
  };

  const handleRemoveField = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };

  const handleFieldChange = (index, fieldTitle, fieldContent) => {
    const updatedFields = [...fields];
    updatedFields[index] = {
      ...updatedFields[index],
      fieldTitle,
      fieldContent,
    };
    setFields(updatedFields);
  };

  const telesubmitAnnouncement = () => {
    axios
      .post(`${TELEGRAM_URL1}/apiv7/postAnnouncement`, announcement)
      .then((response) => {
        console.log(response.data.message);
        toast.success("Announcement sent successfully!");
        setAnnouncement({
          message: "",
          date: "",
          time: "",
        });
      })
      .catch((error) => {
        console.error("There was an error submitting the announcement:", error);
        toast.error("Error during sending announcement");
      });
  };

  const resetForm = () => {
    setChannelId("");
    setScheduleDate("");
    setScheduleTime("");
    setRecurring(false);
    setEmbedTitle("");
    setEmbedTitleUrl("");
    setEmbedDescription("");
    setThumbnailUrl("");
    setImageUrl("");
    setFooterIcon("");
    setFooterText("");
    setFields([]);
    setEmbeddedText("");
  };

  const handleAnnouncementChange = (e) => {
    const { name, value } = e.target;
    setAnnouncement({ ...announcement, [name]: value });
  };

  const handleCryptoTimeChange = (e) => {
    setCryptoTime(e.target.value);
  };

  const submitCryptoTime = () => {
    axios
      .post(`${TELEGRAM_URL2}/apiv8/settime`, { time: cryptoTime })
      .then((response) => {
        console.log("Crypto time scheduled successfully:", response.data);
        toast.success("The Time scheduled successfully");
        setCryptoTime("");
      })
      .catch((error) => {
        console.error("Error scheduling crypto time:", error);
        toast.error("Error during scheduling");
      });
  };

  const handleAnnouncementSubmit = async (e) => {
    e.preventDefault();
    const schedule = `${scheduleDate} ${scheduleTime}`;
    const newAnnouncement = {
      channelId: "1274995781966893076",
      schedule,
      recurring: recurring ? 1 : 0,
      embedTitle,
      embedTitleUrl,
      embedDescription,
      thumbnailUrl,
      imageUrl,
      footerIcon,
      footerText,
      fields,
      embeddedText,
    };
    console.log(newAnnouncement.channelId);

    try {
      const response = await axios.post(
        `${NODE_URL}/apiv2/announcements/create`,
        newAnnouncement
      );
      if (response.status === 200) {
        toast.success("Discord daily market data Updated");
      } else {
        toast.error("Error in Discord daily market data updating");
      }
      resetForm();
    } catch (error) {
      alert("Error creating announcement");
      console.error("Error:", error);
    }
  };

  // Poll functions
  const createPoll = async () => {
    try {
      const response = await axios.post("http://localhost:3008/polls", {
        question,
        options,
        dateTime,
      });
      console.log("Poll created:", response.data);
      setQuestion("");
      setOptions(["", ""]);
      setDateTime("");
    } catch (error) {
      console.error("Error creating poll:", error);
    }
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const addOption = () => {
    setOptions([...options, ""]);
  };

  const removeOption = (index) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

  const handlePollSubmit = (e) => {
    e.preventDefault();
    createPoll();
  };

  const fetchMarketData = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${NODE_URL}/apiv2/fetch-market-data`);
      setStatusMessage(response.data);
    } catch (error) {
      setStatusMessage("Failed to fetch market data.");
    }
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${NODE_URL}/apiv2/set-daily-market-time`,
        { cronTime }
      );
      setMessage(response.data);
      if (response.status === 200) {
        toast.success("Discord daily market data Updated");
        setCronTime('')
      } else {
        toast.error("Error in Discord daily market data updating");
      }
    } catch (error) {
      console.error("Error scheduling market update:", error);
      setMessage("Failed to schedule market update.");
    }
  };

  const setDailyMarketTime = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "http://localhost:3010/api/set-daily-market-time",
        { cronTime }
      );
      setStatusMessage(response.data);
    } catch (error) {
      setStatusMessage("Failed to set daily market time.");
    }
    setLoading(false);
  };

  const handleCronTimeChange = (event) => {
    const date = new Date(event.target.value);
    const minutes = date.getMinutes();
    const hours = date.getHours();
    const dayOfMonth = date.getDate();
    const month = date.getMonth() + 1;
    const dayOfWeek = date.getDay();

    setCronTime(`${minutes} ${hours} ${dayOfMonth} ${month} ${dayOfWeek}`);
  };

  const submitAnnouncement = () => {
    axios
      .post("http://localhost:3010/postAnnouncement", announcement)
      .then((response) => {
        console.log("Announcement scheduled successfully:", response.data);
        // Optionally, you can reset the form or display a success message
      })
      .catch((error) => {
        console.error("Error scheduling announcement:", error);
        // Handle error (e.g., show error message to user)
      });
  };

  const managePolls = () => {
    navigate("poll-results");
  };

  const handlePollChange = (e) => {
    const { name, value } = e.target;
    setPoll((prevPoll) => ({
      ...prevPoll,
      [name]: value,
    }));
  };

  const handlePollOptionChange = (index, value) => {
    const newPollOptions = [...pollOptions];
    newPollOptions[index] = value;
    setPollOptions(newPollOptions);
  };

  const addPollOption = () => {
    setPollOptions((prevOptions) => [...prevOptions, ""]);
  };

  const removeLastPollOption = () => {
    if (pollOptions.length > 2) {
      setPollOptions((prevOptions) => prevOptions.slice(0, -1));
    }
  };

  const submitPoll = () => {
    const pollData = {
      ...poll,
      choices: pollOptions,
    };

    axios
      .post("http://localhost:3003/api/startpoll", pollData)
      .then((response) => {
        alert("Poll submitted successfully!");
        setPoll({ question: "", date: "", time: "" });
        setPollOptions(["", ""]);
      })
      .catch((error) => {
        console.error("Error submitting poll:", error);
        alert("Failed to submit poll");
      });
  };

  return (
    <div className="flex flex-col lg:flex-row">
      <Layout></Layout>
      <div
        className="w-[100%] bg-no-repeat bg-center bg-cover"
        style={{ backgroundImage: "url('/assets/bg-fomo.jpeg')" }}
      >
        <div className="p-6 h-screen overflow-hidden overflow-y-auto">
          <h1 className="text-4xl font-impact mb-8 text-[#050812]">
            Telegram Bots
          </h1>
          <div className="bg-dark p-6 rounded-lg shadow-lg mb-8 bg-[#050812] border border-[#a83b66]">
            <h2 className="text-2xl font-semibold mb-4 text-white">
              Announcement Bot
            </h2>
            <textarea
              className="w-full p-2 mb-4 focus:outline-none bg-white rounded bg-input text-black"
              name="message"
              placeholder="Enter your announcement message here..."
              value={announcement.message}
              onChange={handleAnnouncementChange}
            />
            <div className="flex space-x-4">
              <input
                type="date"
                className="p-2 bg-white rounded bg-input text-black"
                name="date"
                value={announcement.date}
                onChange={handleAnnouncementChange}
              />
              <input
                type="time"
                className="p-2 bg-white rounded bg-input text-black"
                name="time"
                value={announcement.time}
                onChange={handleAnnouncementChange}
              />
            </div>
            <button
              className="mt-4 bg-[#a83b66] text-white font-impact p-2 rounded"
              onClick={telesubmitAnnouncement}
            >
              Submit
            </button>
          </div>

          {/* Poll Bot Section */}

          {/* <div className="bg-dark p-6 rounded-lg shadow-lg mb-8 bg-[#050812] border border-[#a83b66]">
            <h2 className="text-2xl font-semibold mb-4 text-white">Poll Bot</h2>
            <div className="flex space-x-4">
              <textarea
                className="w-1/2 p-2 mb-4 bg-white rounded bg-input text-black"
                name="question"
                placeholder="Enter your poll question here..."
                value={poll.question}
                onChange={handlePollChange}
              />
              <div className="w-1/2">
                {pollOptions.map((option, index) => (
                  <div key={index} className="flex items-center mb-2">
                    <input
                      type="text"
                      className="w-full p-2 bg-white rounded bg-input text-black"
                      value={option}
                      onChange={(e) =>
                        handlePollOptionChange(index, e.target.value)
                      }
                      placeholder={`Option ${index + 1}`}
                    />
                  </div>
                ))}
                <div className="flex space-x-2">
                  <button
                    className="bg-[#a83b66] text-white font-impact p-2 rounded mb-4 bg-[#01daa8]"
                    onClick={addPollOption}
                  >
                    + Add Option
                  </button>
                  <button
                    className="bg-[#a83b66] text-white font-impact p-2 rounded mb-4 bg-[#01daa8]"
                    onClick={removeLastPollOption}
                  >
                    - Remove Option
                  </button>
                </div>
              </div>
            </div>
            <div className="flex space-x-4">
              <input
                type="date"
                className="p-2 bg-white rounded bg-input text-black"
                name="date"
                value={poll.date}
                onChange={handlePollChange}
              />
              <input
                type="time"
                className="p-2 bg-white rounded bg-input text-black"
                name="time"
                value={poll.time}
                onChange={handlePollChange}
              />
            </div>
            <button
              className="mt-4 bg-[#a83b66] text-white font-impact p-2 rounded bg-[#01daa8]"
              onClick={submitPoll}
            >
              Submit Poll
            </button>
            <button
              className="mt-4 ml-2 bg-[#a83b66] text-white font-impact p-2 rounded bg-[#01daa8]"
              onClick={managePolls}
            >
              Manage Polls
            </button>
          </div> */}

          {/* Crypto Bot Section */}

          <div className="bg-dark p-6 rounded-lg shadow-lg bg-[#050812] border border-[#a83b66]">
            <h2 className="text-2xl font-semibold mb-4 text-white">
              Market Data Bot
            </h2>
            <input
              type="time"
              className="w-[20%] p-2 mb-4 bg-white rounded bg-input text-black"
              value={cryptoTime}
              onChange={handleCryptoTimeChange}
            />
            <br></br>
            <button
              className="bg-[#a83b66] text-white font-impact p-2 rounded mb-4"
              onClick={submitCryptoTime}
            >
              Submit Time
            </button>
          </div>

          <br></br>

          {/* Discord Bots */}

          <h1 className="text-4xl font-impact mb-8 text-[#050812]">
            Discord Bots
          </h1>

          <form
            onSubmit={handleAnnouncementSubmit}
            className="bg-dark p-6 rounded-lg shadow-lg mb-8 bg-[#050812] border border-[#a83b66] text-white"
          >
            <h2 className="text-2xl font-bold mb-4">Announcement Bot</h2>
            <div className="mb-4">
              <label className="block mb-1">Embedded Text:</label>
              <input
                type="text"
                value={embeddedText}
                onChange={(e) => setEmbeddedText(e.target.value)}
                className=" bg-white w-full px-3 py-2 text-black rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="flex mb-4">
              <div className="w-1/2 pr-2">
                <label className="block mb-1">Schedule Date:</label>
                <input
                  type="date"
                  value={scheduleDate}
                  onChange={(e) => setScheduleDate(e.target.value)}
                  required
                  className="bg-white text-black w-full px-3 py-2  rounded-lg focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="w-1/2 pl-2">
                <label className="block mb-1">Schedule Time:</label>
                <input
                  type="time"
                  value={scheduleTime}
                  onChange={(e) => setScheduleTime(e.target.value)}
                  required
                  className="bg-white w-full px-3 py-2 text-black rounded-lg focus:outline-none focus:border-blue-500"
                />
              </div>
            </div>
            <div className="flex mb-4">
              <div className="w-1/2 pr-2">
                <label className="block mb-1">Embed Title:</label>
                <input
                  type="text"
                  value={embedTitle}
                  onChange={(e) => setEmbedTitle(e.target.value)}
                  className="bg-white w-full px-3 py-2 text-black rounded-lg focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="w-1/2 pl-2">
                <label className="block mb-1">Embed Title URL:</label>
                <input
                  type="text"
                  value={embedTitleUrl}
                  onChange={(e) => setEmbedTitleUrl(e.target.value)}
                  className="bg-white w-full px-3 py-2 text-black rounded-lg focus:outline-none focus:border-blue-500"
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block mb-1">Embed Description:</label>
              <textarea
                value={embedDescription}
                onChange={(e) => setEmbedDescription(e.target.value)}
                className="bg-white w-full px-3 py-2 text-black rounded-lg focus:outline-none focus:border-blue-500"
              ></textarea>
            </div>
            <div className="flex mb-4">
              <div className="w-1/2 pr-2">
                <label className="block mb-1">Thumbnail URL:</label>
                <input
                  type="text"
                  value={thumbnailUrl}
                  onChange={(e) => setThumbnailUrl(e.target.value)}
                  className="bg-white w-full px-3 py-2 text-black rounded-lg focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="w-1/2 pl-2">
                <label className="block mb-1">Image URL:</label>
                <input
                  type="text"
                  value={imageUrl}
                  onChange={(e) => setImageUrl(e.target.value)}
                  className="bg-white w-full px-3 py-2 text-black rounded-lg focus:outline-none focus:border-blue-500"
                />
              </div>
            </div>
            <div className="flex mb-4">
              <div className="w-1/2 pr-2">
                <label className="block mb-1">Footer Icon:</label>
                <input
                  type="text"
                  value={footerIcon}
                  onChange={(e) => setFooterIcon(e.target.value)}
                  className="bg-white w-full px-3 py-2 text-black rounded-lg focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="w-1/2 pl-2">
                <label className="block mb-1">Footer Text:</label>
                <input
                  type="text"
                  value={footerText}
                  onChange={(e) => setFooterText(e.target.value)}
                  className="bg-white w-full px-3 py-2 text-black rounded-lg focus:outline-none focus:border-blue-500"
                />
              </div>
            </div>
            <button
              type="button"
              onClick={handleAddField}
              className="bg-[#a83b66] text-white font-impact py-2 px-4 rounded-lg mr-2"
            >
              Add Field
            </button>

            {fields.map((field, index) => (
              <div key={index} className="mt-4">
                <label className="block mb-1">Field Title:</label>
                <input
                  type="text"
                  value={field.fieldTitle}
                  onChange={(e) =>
                    handleFieldChange(index, e.target.value, field.fieldContent)
                  }
                  className="w-full bg-white px-3 py-2  rounded-lg focus:outline-none focus:border-blue-500 mb-2"
                />
                <label className="block mb-1">Field Content:</label>
                <input
                  type="text"
                  value={field.fieldContent}
                  onChange={(e) =>
                    handleFieldChange(index, field.fieldTitle, e.target.value)
                  }
                  className="w-full bg-white px-3 py-2  rounded-lg focus:outline-none focus:border-blue-500"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveField(index)}
                  className="bg-red-500 hover:bg-red-600 text-white py-1 px-2 rounded-lg mt-2"
                >
                  Remove Field
                </button>
              </div>
            ))}

            <button
              type="submit"
              className="bg-[#a83b66] text-white font-impact py-2 px-4 rounded-lg mt-4"
            >
              Create Announcement
            </button>
          </form>

          {/* <form
            onSubmit={handlePollSubmit}
            className="bg-dark p-6 rounded-lg shadow-lg mb-8 bg-[#050812] border border-[#a83b66] text-white"
          >
            <h2 className="text-2xl text-white font-bold mb-4">Poll Bot</h2>
            <div className="flex justify-between gap-3">
              <textarea
                type="textarea"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                required
                className="w-1/2 p-2 mb-4 bg-white rounded bg-input text-black"
                placeholder="Enter your poll question here..."
              />

              <div className="w-1/2">
                {options.map((option, index) => (
                  <div key={index} className="mb-2 flex items-center">
                    <input
                      type="text"
                      value={option}
                      onChange={(e) =>
                        handleOptionChange(index, e.target.value)
                      }
                      required
                      className="w-full p-2 bg-white rounded bg-input text-black"
                      placeholder={`Option ${index + 1}`}
                    />
                  </div>
                ))}
                <div className="flex space-x-2">
                  <button
                    className="bg-[#a83b66] text-white font-impact p-2 rounded mb-4 bg-[#01daa8]"
                    onClick={addOption}
                  >
                    + Add Option
                  </button>
                  <button
                    className="bg-[#a83b66] text-white font-impact p-2 rounded mb-4 bg-[#01daa8]"
                    onClick={removeOption}
                  >
                    - Remove Option
                  </button>
                </div>
              </div>
            </div>

            <div className="mb-4">
              <label className="block mb-1">Poll Date and Time:</label>
              <input
                type="datetime-local"
                value={dateTime}
                onChange={(e) => setDateTime(e.target.value)}
                className=" bg-white px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>

            <div className="flex gap-3">
              <button
                type="submit"
                className="bg-[#01daa8] text-black py-2 px-4 rounded-lg"
              >
                Submit Poll
              </button>
              <Link to="/Admin/notification/managepoll">
                <button
                  type="button"
                  className="bg-[#01daa8] text-black py-2 px-4 rounded-lg"
                >
                  Manage Polls
                </button>
              </Link>
            </div>
          </form> */}

          <div className="bg-dark p-6 rounded-lg shadow-lg mb-8 bg-[#050812] border border-[#a83b66] text-white">
            <h1 className="text-2xl text-white font-bold mb-4">
              Market Data Bot
            </h1>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <input
                  type="datetime-local"
                  id="cronTime"
                  value={cronTime}
                  onChange={(e) => setCronTime(e.target.value)}
                  className="bg-white text-black px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
                  required
                />
              </div>
              <button
                type="submit"
                className="bg-[#a83b66] text-white font-impact mt-5 py-2 px-4 rounded-lg"
              >
                Schedule Update
              </button>
            </form>
            {message && (
              <p className="mt-4 text-sm text-green-600">{message}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
