import Header from "./Header";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowTrendUp,
  faCircle,
  faSignal,
} from "@fortawesome/free-solid-svg-icons";
import { Url } from "url";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useEffect, useState, useRef, useContext } from "react";
import Countdown from "./Countdown";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { token_abi } from "../ABI";
import { ethers } from "ethers";
import { BigNumber } from "bignumber.js";
import toast, { Toaster } from "react-hot-toast";
import { WalletContext } from "./WalletContext";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import QRCode from "qrcode.react";
import axios from "axios";

import {
  Contract_details,
  NODE_URL,
  RPC_URL,
  TOKEN_CONTRACT,
  SOL_ADMIN_ADDRESS,
  EVM_ADMIN_ADDRESS,
  TON_ADMIN_ADDRESS,
  TRON_ADMIN_ADDRESS,
  BTC_ADMIN_ADDRESS,
} from "./Config";
import {
  PublicKey,
  Connection,
  Transaction,
  TransactionInstruction,
  SystemProgram,
  clusterApiUrl,
  sendAndConfirmRawTransaction,
} from "@solana/web3.js";
import { Buffer } from "buffer";
const { Cell } = require("ton");

export default function Landing() {
  const { walletAddress, walletAddressType } = useContext(WalletContext);

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [tonConnectUI] = useTonConnectUI();

  const initialDate = new Date("2024-09-06T00:00:00");

  const getInitialTargetTime = () => {
    const storedTargetTime = localStorage.getItem("targetTime");
    return storedTargetTime ? new Date(storedTargetTime) : initialDate;
  };

  const [targetTime, setTargetTime] = useState(getInitialTargetTime);

  const calculateTimeLeft = (targetDate) => {
    const currentTime = new Date();
    const difference = targetDate - currentTime;

    if (difference <= 0) {
      return { days: "00", hours: "00", minutes: "00", seconds: "00" };
    } else {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      return {
        days: days.toString().padStart(2, "0"),
        hours: hours.toString().padStart(2, "0"),
        minutes: minutes.toString().padStart(2, "0"),
        seconds: seconds.toString().padStart(2, "0"),
      };
    }
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetTime));

  useEffect(() => {
    const updateCountdown = () => {
      const newTimeLeft = calculateTimeLeft(targetTime);

      if (
        newTimeLeft.days === "00" &&
        newTimeLeft.hours === "00" &&
        newTimeLeft.minutes === "00" &&
        newTimeLeft.seconds === "00"
      ) {
        const newTargetTime = new Date(
          targetTime.getTime() + 3 * 24 * 60 * 60 * 1000
        );
        setTargetTime(newTargetTime);
        localStorage.setItem("targetTime", newTargetTime);
      } else {
        setTimeLeft(newTimeLeft);
      }
    };

    const interval = setInterval(updateCountdown, 1000);
    return () => clearInterval(interval);
  }, [targetTime]);

  const [Amount, setAmount] = useState(0);
  const [TokenAmount, setTokenAmount] = useState(0);
  const [selectedToken, setSelectedToken] = useState(null);
  const [selectedNetwork, setSelectedNetwork] = useState({});
  const [TokenList, setTokenList] = useState([]);

  const [PreOrder, setPreOrder] = useState(null);
  const [TokenUSDprice, setTokenUSDprice] = useState(null);
  const [MinQuantity, setMinQuantity] = useState(null);
  const [MaxQuantity, setMaxQuantity] = useState(null);
  const [PreSaleStatus, setPreSaleStatus] = useState(null);
  const [Purchased, setPurchased] = useState(0);

  const [PreOrderBalance, setPreOrderBalance] = useState(null);
  const [Percentage, setPercentage] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [solanaWalletAddress, setSolanaWalletAddress] = useState(null);

  const [adminWallet, setAdminWallet] = useState(null);
  const [hasShownWalletToast, setHasShownWalletToast] = useState(false);
  const [hasShownInputToast, setHasShownInputToast] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const isAnyTokenSelected = selectedIndex !== null;
  const walletToastTimeoutRef = useRef(null);
  const inputToastTimeoutRef = useRef(null);

  const [fromAddress, setFromAddress] = useState("");
  const [transactionHash, setTransactionHash] = useState("");
  const [qrPopup, setQrPopup] = useState("");
  const [confirmPopup, setConfirmPopup] = useState("");

  const [NetworkDropdown, setNetworkDropdown] = useState(false);
  const [TokenDropdown, setTokenDropdown] = useState(false);
  const [filteredNetworks, setFilteredNetworks] = useState([]);

  const [Text, setText] = useState(false);

  const networks = {
    ethereum: {
      chainId: 1,
      rpcUrls: [Contract_details.ETH_RPC],
      chainName: "Ethereum Mainnet",
      nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
      blockExplorerUrls: [Contract_details.ETH_EXPLORER],
    },
    polygon: {
      chainId: 137,
      rpcUrls: [Contract_details.POLYGON_RPC],
      chainName: "Polygon Mainnet",
      nativeCurrency: { name: "Matic", symbol: "MATIC", decimals: 18 },
      blockExplorerUrls: [Contract_details.POLYGON_EXPLORER],
    },
    base: {
      chainId: 8453,
      rpcUrls: [Contract_details.BASE_RPC],
      chainName: "Base Mainnet",
      nativeCurrency: { name: "Base Coin", symbol: "BASE", decimals: 18 },
      blockExplorerUrls: [Contract_details.BASE_EXPLORER],
    },
    bsc: {
      chainId: 56,
      rpcUrls: [Contract_details.BSC_RPC],
      chainName: "Binance Smart Chain",
      nativeCurrency: { name: "Binance Coin", symbol: "BNB", decimals: 18 },
      blockExplorerUrls: [Contract_details.BSC_EXPLORER],
    },
  };

  const NetworkList = [
    {
      name: "ETHEREUM",
      symbol: "ETH",
      img: "https://etherscan.io/images/svg/brands/ethereum-original.svg",
      chainId: "1",
    },
    {
      name: "BSC",
      symbol: "BSC",
      img: "https://bscscan.com/assets/bsc/images/svg/logos/token-light.svg?v=24.7.2.0",
      chainId: "56",
    },
    {
      name: "BASE",
      symbol: "ETH",
      img: "https://basescan.org/assets/base/images/svg/logos/chain-light.svg?v=24.7.2.0",
      chainId: "8453",
    },
    {
      name: "POLYGON",
      symbol: "MATIC",
      img: "https://polygonscan.com/assets/poly/images/svg/logos/token-light.svg?v=24.6.3.0",
      chainId: "137",
    },
    {
      name: "SOLANA",
      symbol: "SOL",
      img: "https://cryptologos.cc/logos/solana-sol-logo.png",
      chainId: "900",
    },
    {
      name: "TON",
      symbol: "TON",
      img: "https://cryptologos.cc/logos/toncoin-ton-logo.png",
      chainId: "622463",
    },
    {
      name: "TRON",
      symbol: "TRX",
      img: "https://cryptologos.cc/logos/tron-trx-logo.png",
      chainId: "1000",
    },
    {
      name: "BITCOIN",
      symbol: "BTC",
      img: "https://cryptologos.cc/logos/bitcoin-btc-logo.png",
      chainId: "10",
    },
  ];

  const TokensList = {
    1: [
      {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
        img: "https://etherscan.io/images/svg/brands/ethereum-original.svg",
        address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      },
      {
        name: "Tether USD",
        symbol: "USDT",
        decimals: Contract_details.ETH_USDT_DECIMAL,
        img: "https://etherscan.io/token/images/tethernew_32.png",
        address: Contract_details.ETH_USDT_CONTRACT,
      },
      {
        name: "USDC",
        symbol: "USDC",
        decimals: Contract_details.ETH_USDC_DECIMAL,
        img: "https://etherscan.io/token/images/centre-usdc_28.png",
        address: Contract_details.ETH_USDC_CONTRACT,
      },
      {
        name: "SHIBA INU",
        symbol: "SHIB",
        decimals: Contract_details.ETH_SHIB_DECIMAL,
        img: "https://etherscan.io/token/images/shibatoken_32.png",
        address: Contract_details.ETH_SHIB_CONTRACT,
      },
    ],
    56: [
      {
        name: "BNB",
        symbol: "BNB",
        address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        decimals: 18,
        img: "https://bscscan.com/assets/bsc/images/svg/logos/token-light.svg?v=24.7.2.1",
      },
      {
        name: "Binance-Peg USD Coin",
        symbol: "BUSD",
        address: Contract_details.BSC_BUSD_CONTRACT,
        decimals: Contract_details.BSC_BUSD_DECIMAL,
        img: "https://bscscan.com/token/images/busd_32_2.png",
      },
    ],
    8453: [
      {
        name: "BASE",
        symbol: "BASE",
        address: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
        decimals: 18,
        img: "https://basescan.org/assets/base/images/svg/logos/chain-light.svg?v=24.7.2.0",
      },
      {
        name: "USD Coin",
        symbol: "USDC",
        address: Contract_details.BASE_USDC_CONTRACT,
        decimals: Contract_details.BASE_USDC_DECIMAL,
        img: "https://basescan.org/token/images/centre-usdc_28.png",
      },
    ],
    137: [
      {
        name: "Matic Token",
        symbol: "MATIC",
        address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
        decimals: 18,
        img: "https://polygonscan.com/assets/poly/images/svg/logos/token-light.svg?v=24.7.2.0",
      },
      {
        name: "(PoS) Tether USD",
        symbol: "USDT",
        address: Contract_details.POLYGON_USDT_CONTRACT,
        decimals: Contract_details.POLYGON_USDT_DECIMAL,
        img: "https://polygonscan.com/token/images/tether_32.png",
      },
      {
        symbol: "USDC",
        address: Contract_details.POLYGON_USDC_CONTRACT,
        decimals: Contract_details.POLYGON_USDC_DECIMAL,
        name: "USD Coin",
        img: "https://polygonscan.com/token/images/usdc_32.png",
      },
    ],
    900: [
      {
        name: "SOL",
        symbol: "SOL",
        decimals: 9,
        img: "https://solscan.io/_next/static/media/solPriceLogo.76eeb122.png",
        address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      },
    ],
    622463: [
      {
        name: "TON",
        symbol: "TON",
        decimals: 9,
        img: "https://cryptologos.cc/logos/toncoin-ton-logo.png",
        address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      },
    ],
    1000: [
      {
        name: "TRON",
        symbol: "TRX",
        address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
        decimals: 6,
        img: "https://static.tronscan.org/production/logo/trx.png",
      },
      {
        name: "Tether USD",
        symbol: "USDT",
        address: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
        decimals: 6,
        img: "https://static.tronscan.org/production/logo/usdtlogo.png",
      },
    ],
    10: [
      {
        name: "BTC",
        symbol: "BTC",
        address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
        decimals: 8,
        img: "https://btcscan.org/img/icons/Bitcoin-menu-logo.svg",
      },
    ],
  };

  const getAllTokens = () => {
    const tokenMap = {};
    Object.values(TokensList)
      .flat()
      .forEach((token) => {
        if (!tokenMap[token.symbol]) {
          tokenMap[token.symbol] = token;
        }
      });
    return Object.values(tokenMap);
  };
  let TokenTotal = getAllTokens();

  const handleNetworkDropdown = () => {
    setNetworkDropdown(!NetworkDropdown);
  };

  const handleTokenDropdown = () => {
    setTokenDropdown(!TokenDropdown);
  };

  const choseTokens = (index, token) => {
    const availableNetworks = Object.keys(TokensList)
      .filter((chainId) => {
        return TokensList[chainId].some((t) => t.symbol === token.symbol);
      })
      .map((chainId) => {
        return NetworkList.find((network) => network.chainId === chainId);
      });

    console.log(availableNetworks);
    setFilteredNetworks(availableNetworks);
    setSelectedToken(token);
    setTokenDropdown(false);
    setSelectedNetwork({});
  };

  const choseNetworkList = async (index, network) => {
    if (!selectedToken) {
      toast.error("No token selected.");
      console.error("No token selected.");
      return;
    }

    if (walletAddress === null) {
      toast.error("Connect the wallet");
      console.error("No token selected.");
      return;
    }
    let selected = NetworkList.find((n) => n.chainId === network.chainId);

    if (!selected) {
      console.error("Selected network not found.");
      return;
    }

    const chain = selected.chainId;
    console.log(walletAddressType, chain, chainId, selected);

    let evmChains = [1, 137, 56, 8453];

    const isEVM = walletAddressType === "EVM";
    const isChainMatch = chain === chainId;
    const isPhantomSOL =
      walletAddressType === "PHANTOM" && selected.symbol === "SOL";
    const isTON = walletAddressType === "TON" && selected.symbol === "TON";
    const isTRON = walletAddressType === "TRON" && selected.symbol === "TRX";
    const isBTC = walletAddressType === "BTC" && selected.symbol === "BTC";

    if (isEVM && isChainMatch) {
      setSelectedNetwork(selected);
    } else if (isEVM && !isChainMatch && evmChains.includes(Number(chain))) {
      let result = await switchNetwork(Number(chain));
      if (result) {
        setSelectedNetwork(selected);
      } else {
        setSelectedNetwork(null);
      }
    } else if (isPhantomSOL || isTON || isTRON || isBTC) {
      setSelectedNetwork(selected);
    } else {
      toast.error(`Switch to ${selected.name} Wallet`);
      setSelectedNetwork(null);
    }

    // Now, set the selected token in the selected network
    const token = TokensList[chain].find(
      (t) => t.symbol === selectedToken.symbol
    );
    if (token) {
      setSelectedToken(token);
    }

    setNetworkDropdown(false);
  };

  const switchNetwork = async (chainId) => {
    const network = Object.values(networks).find(
      (net) => net.chainId === chainId
    );

    if (!network) {
      console.error("Network not supported.");
      toast.error("Network not supported.");
      return false;
    }

    try {
      await walletProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: ethers.utils.hexValue(chainId) }],
      });

      toast.success(`Switched to ${network.chainName}`);
      return true;
    } catch (switchError) {
      console.error("Switch Error:", switchError);

      if (switchError.code === 4902) {
        try {
          await walletProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: ethers.utils.hexValue(chainId),
                chainName: network.chainName,
                nativeCurrency: network.nativeCurrency,
                rpcUrls: network.rpcUrls,
                blockExplorerUrls: network.blockExplorerUrls,
              },
            ],
          });
          toast.success(`Added and switched to ${network.chainName}`);
          return true;
        } catch (addError) {
          console.error("Add Error:", addError);
          toast.error("Failed to add network.");
          return false;
        }
      } else {
        toast.error("Failed to switch network.");
        return false;
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValidAddress = isValidSolanaAddress(solanaWalletAddress);
    console.log(isValidAddress, walletAddress, solanaWalletAddress);
    if (isValidAddress) {
      const res = await axios.post(`${NODE_URL}/apiv2/addSolanaAddress`, {
        address: walletAddress,
        solanaWalletAddress: solanaWalletAddress,
      });
      if (res.data && res.data.status === "success") {
        console.log("Solana Wallet Address:", solanaWalletAddress, res.data);
        setShowPopup(false);
        Transfer();
      } else {
        console.error("Error : ", res);
        toast.error("Re-enter Solana Wallet Address");
      }
    } else {
      console.error("Invalid Solana Wallet Address");
      toast.error("Invalid Solana Wallet Address");
    }
  };

  const isValidSolanaAddress = (address) => {
    try {
      new PublicKey(address);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;

    if (isNaN(value)) {
      if (!hasShownInputToast) {
        toast.error("Please enter numerical values only");
        setHasShownInputToast(true);

        if (inputToastTimeoutRef.current) {
          clearTimeout(inputToastTimeoutRef.current);
        }
        inputToastTimeoutRef.current = setTimeout(() => {
          setHasShownInputToast(false);
        }, 5000);
      }
      return;
    }

    if (walletAddress === null && !hasShownWalletToast) {
      toast.error("Please connect the wallet");
      setHasShownWalletToast(true);

      if (walletToastTimeoutRef.current) {
        clearTimeout(walletToastTimeoutRef.current);
      }
      walletToastTimeoutRef.current = setTimeout(() => {
        setHasShownWalletToast(false);
      }, 5000);
    }
    console.log(value);
    setAmount(value);
  };

  useEffect(() => {
    return () => {
      if (walletToastTimeoutRef.current) {
        clearTimeout(walletToastTimeoutRef.current);
      }
      if (inputToastTimeoutRef.current) {
        clearTimeout(inputToastTimeoutRef.current);
      }
    };
  }, []);

  const validateAmount = async () => {
    let Token;
    let value;
    if (Amount > 0) {
      value = Amount;
    } else {
      value = 0;
    }
    if (selectedToken.symbol === "WMATIC") {
      Token = "MATIC";
    } else if (selectedToken.symbol === "USDC.e") {
      Token = "USDC";
    } else {
      Token = selectedToken.symbol;
    }

    const response = await axios.get(
      `https://min-api.cryptocompare.com/data/price?api_key=830c94225fed427911d9c8e4ea29b220d78e466ee4f597fa8ecce33ee602355c&fsym=${Token}&tsyms=USD`
    );
    const data = response.data;

    if (data.Response === "Error") {
      console.error("Error in API response:", data.Message);
      return;
    }

    const conversionRate = data ? data.USD : 0;
    console.log("Conversion Rate:", conversionRate);

    setTokenAmount((value * conversionRate).toFixed(4));

    console.log((value * conversionRate).toFixed(4) / Number(TokenUSDprice), Number(MaxQuantity))
    if ((value * conversionRate).toFixed(4) / Number(TokenUSDprice) > Number(MaxQuantity)) {
      setText(true)
    } else {
      setText(false)
    }
  };

  useEffect(() => {
    if (selectedToken !== null) {
      validateAmount();
    }
  }, [Amount, selectedToken]);

  useEffect(() => {
    console.log(walletAddressType);
    if (walletAddressType !== null) {
      if (walletAddressType === "PHANTOM") {
        setAdminWallet(SOL_ADMIN_ADDRESS);
      } else if (walletAddressType === "TON") {
        setAdminWallet(TON_ADMIN_ADDRESS);
      } else if (walletAddressType === "TRON") {
        setAdminWallet(TRON_ADMIN_ADDRESS);
      } else if (walletAddressType === "BTC") {
        setAdminWallet(BTC_ADMIN_ADDRESS);
      } else {
        setAdminWallet(EVM_ADMIN_ADDRESS);
      }
    } else {
      setAdminWallet(null);
    }
  }, [walletAddress, walletAddressType]);

  const Transfer = async () => {
    try {
      if (walletAddress === null) {
        toast.error("Please connect your wallet and Try Again");
        return false;
      }
      if (!Amount || Amount <= 0 || !selectedToken) {
        toast.error("Please enter all fields correctly.");
        return false;
      }

      if (PreSaleStatus === "inactive") {
        toast.error("Pre-Sale Ended");
        return false;
      }

      const tokenAmountInMeme = Number(
        (Number(TokenAmount) / Number(TokenUSDprice)).toFixed(2)
      );
      console.log(TokenAmount, tokenAmountInMeme, MinQuantity, MaxQuantity); // 5.0000000000 5 10

      if (tokenAmountInMeme < Number(MinQuantity)) {
        toast.error("Minimum Purchase Amount is " + MinQuantity + " FOMO");
        return false;
      }

      if (tokenAmountInMeme > Number(MaxQuantity)) {
        toast.error("Maximum Purchase Amount is " + MaxQuantity + " FOMO");
        return false;
      }

      console.log(Number(PreOrderBalance) > Number(PreOrder));
      if (Number(PreOrderBalance) > Number(PreOrder)) {
        toast.error("PreOrder Sale is Over");
        return false;
      }

      console.log(selectedNetwork)
      if (selectedNetwork === null) {
        toast.error("Please select the Network");
        return;
      }


      if (walletAddressType === "PHANTOM" && selectedNetwork.name === "SOLANA") {
        if (selectedToken.symbol === "SOL") {
          TransferSOL(walletAddress, SOL_ADMIN_ADDRESS, Amount);
        } else {
          // transferSPLToken(
          //   new PublicKey(walletAddress),
          //   new PublicKey(walletAddress),
          //   new PublicKey(selectedToken.address),
          //   Amount,
          //   selectedToken.decimals
          // );
        }
      } else if (walletAddressType === "TON" && selectedNetwork.name === "TON") {
        TransferTON(TON_ADMIN_ADDRESS, Amount);
      } else if (walletAddressType === "TRON" && selectedNetwork.name === "TRON") {
        TransferTRON(TRON_ADMIN_ADDRESS, Amount);
      } else if (walletAddressType === "BTC" && selectedNetwork.name === "BITCOIN") {
        TransferBTC(BTC_ADMIN_ADDRESS, Amount);
      } else if (
        walletAddressType === "EVM" &&
        ["ETHEREUM", "POLYGON", "BSC", "BASE"].includes(selectedNetwork.name)
      ) {
        TransferTokens();
      } else {
        toast.error("Choose the correct Network");
        return;
      }
    } catch (error) {
      console.error("Error chosing network : ", error);
    }
  };

  const TransferTokens = async () => {
    console.log(Amount, selectedToken, address, solanaWalletAddress);

    if (!address || !isConnected) {
      toast.error("Please connect your wallet and Try Again");
      return false;
    }
    if (!Amount || Amount <= 0 || !selectedToken || !address) {
      toast.error("Please enter all fields correctly.");
      return false;
    }

    if (PreSaleStatus === "inactive") {
      toast.error("Pre-Sale Ended");
      return false;
    }

    const tokenAmountInMeme = Number(
      (Number(TokenAmount) / Number(TokenUSDprice)).toFixed(2)
    );
    console.log(TokenAmount, tokenAmountInMeme, MinQuantity, MaxQuantity); // 5.0000000000 5 10

    if (tokenAmountInMeme < Number(MinQuantity)) {
      toast.error("Minimum Purchase Amount is " + MinQuantity + " FOMO");
      return false;
    }

    if (tokenAmountInMeme > Number(MaxQuantity)) {
      toast.error("Maximum Purchase Amount is " + MaxQuantity + " FOMO");
      return false;
    }

    if (!isConnected) {
      toast.error("Please connect the wallet.");
      return false;
    }
    console.log(Number(PreOrderBalance) > Number(PreOrder));
    if (Number(PreOrderBalance) > Number(PreOrder)) {
      toast.error("PreOrder Sale is Over");
      return false;
    }

    const TokenAddress = selectedToken.address;
    const TokenAbi = token_abi;
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = provider.getSigner();
    const contractInstance = new ethers.Contract(
      TokenAddress,
      TokenAbi,
      signer
    );

    const TransferAmount = new BigNumber(
      Amount * 10 ** selectedToken.decimals
    ).toFixed(0);
    const recipientAddress = EVM_ADMIN_ADDRESS;

    const toastId1 = toast.loading("Token transaction in progress", {
      duration: 3000,
    });
    let transferTransaction;
    setTimeout(() => {
      toast.dismiss(toastId1);
    }, 2000);
    try {
      if (
        selectedToken.symbol === "MATIC" ||
        selectedToken.symbol === "ETH" ||
        selectedToken.symbol === "BNB" ||
        selectedToken.symbol === "BASE"
      ) {
        transferTransaction = await signer.sendTransaction({
          to: recipientAddress,
          value: TransferAmount,
        });
      } else {
        transferTransaction = await contractInstance.transfer(
          recipientAddress,
          TransferAmount
        );
      }

      console.log(`${selectedToken.symbol} transaction successful`);
      const result = await transferTransaction.wait();
      const Check = {
        address: address,
        Token: selectedToken.symbol,
        amount: Amount,
        amountUSD: TokenAmount,
        CoinAmount: TokenAmount / Number(TokenUSDprice),
        CoinAmountUSD: TokenUSDprice,
        TokenHash: result.transactionHash,
        network: chainId,
        solanaWalletAddress: solanaWalletAddress,
        TokenStatus: result.status,
      };
      console.log(Check);
      if (result && result.transactionHash) {
        const CoinAmt = TokenAmount / Number(TokenUSDprice);
        const receiveTokens = await axios.get(
          `${NODE_URL}/apiv2/getMemeTokens?address=${address}&Token=${selectedToken.symbol}&amount=${Amount}&amountUSD=${TokenAmount}&CoinAmount=${CoinAmt}&CoinAmountUSD=${TokenUSDprice}&TokenHash=${result.transactionHash}&network=${chainId}&solanaWalletAddress=${solanaWalletAddress}&TokenStatus=${result.status}`
        );
        console.log(receiveTokens);
        if (receiveTokens.data.status === "success") {
          toast.success(`${selectedToken.symbol} transaction successful`, {
            id: toastId1,
          });
          toast.success(
            "Your Tokens will be Claimable Once  Presale is Over!...."
          );
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } else {
        toast.error("Transaction failed", { id: toastId1 });
      }
    } catch (error) {
      console.log(error, "error");
      const errorData = error.error && error.error.data;
      if (
        errorData &&
        errorData.code === 3 &&
        errorData.message.includes("transfer amount exceeds balance")
      ) {
        toast.error("Insufficient balance to perform this transaction");
      } else if (error.code === "UNPREDICTABLE_GAS_LIMIT") {
        toast.error("Transaction may fail or may require manual gas limit");
      } else {
        toast.error(
          "An unexpected error occurred while during this Transaction"
        );
      }
      console.error("Error performing the transaction:", error);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const TransferTON = async (TON_ADMIN_ADDRESS, amount) => {
    try {
      const toastId1 = toast.loading("Token transaction in progress", {
        duration: 3000,
      });
      setTimeout(() => {
        toast.dismiss(toastId1);
      }, 2000);
      let amount1 = amount * 1e9;

      const transaction = {
        messages: [
          {
            address: TON_ADMIN_ADDRESS,
            amount: amount1.toString(),
          },
        ],
      };
      const response = await tonConnectUI.sendTransaction(transaction);

      console.log(response);
      const boc = response.boc;

      const cell = Cell.fromBoc(Buffer.from(boc, "base64"))[0];
      const signature = cell.hash().toString("hex");
      console.log("Transaction Hash:", signature);

      const Check = {
        address: address,
        Token: selectedToken.symbol,
        amount: Amount,
        amountUSD: TokenAmount,
        CoinAmount: TokenAmount / Number(TokenUSDprice),
        CoinAmountUSD: TokenUSDprice,
        TokenHash: signature,
        network: "622463",
        solanaWalletAddress: solanaWalletAddress,
        TokenStatus: "success",
      };
      console.log(Check);
      if (signature) {
        const CoinAmt = TokenAmount / Number(TokenUSDprice);
        const receiveTokens = await axios.get(
          `${NODE_URL}/apiv2/getMemeTokens?address=${walletAddress}&Token=${selectedToken.symbol}&amount=${Amount}&amountUSD=${TokenAmount}&CoinAmount=${CoinAmt}&CoinAmountUSD=${TokenUSDprice}&TokenHash=${signature}&network=622463&solanaWalletAddress=${solanaWalletAddress}&TokenStatus=1`
        );
        console.log(receiveTokens);
        if (receiveTokens.data.status === "success") {
          toast.success(`${selectedToken.symbol} transaction successful`, {
            id: toastId1,
          });
          toast.success(
            "Your Tokens will be Claimable Once  Presale is Over!...."
          );
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      } else {
        toast.error("Transaction failed", { id: toastId1 });
      }
    } catch (error) {
      toast.error("An unexpected error occurred while during this Transaction");
      console.error("Error performing the transaction:", error);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      console.error("Transfer failed:", error);
    }
  };

  const TransferTRON = async (TRON_ADMIN_ADDRESS, amount) => {
    if (!window.tronWeb) {
      toast.error("Please install Tron wallet.");
      return;
    }
    try {
      const toastId1 = toast.loading("Token transaction in progress", {
        duration: 3000,
      });
      setTimeout(() => {
        toast.dismiss(toastId1);
      }, 2000);

      const amountInSun = window.tronWeb.toSun(amount);
      const transaction = await window.tronWeb.trx.sendTransaction(
        TRON_ADMIN_ADDRESS,
        amountInSun
      );

      const signature = transaction.txid;
      let status;
      if (transaction.result) {
        status = 1;
      } else {
        status = 0;
      }
      const Check = {
        address: address,
        Token: selectedToken.symbol,
        amount: Amount,
        amountUSD: TokenAmount,
        CoinAmount: TokenAmount / Number(TokenUSDprice),
        CoinAmountUSD: TokenUSDprice,
        TokenHash: signature,
        network: "1000",
        solanaWalletAddress: solanaWalletAddress,
        TokenStatus: status,
      };
      console.log(Check);
      if (transaction) {
        const CoinAmt = TokenAmount / Number(TokenUSDprice);
        const receiveTokens = await axios.get(
          `${NODE_URL}/apiv2/getMemeTokens?address=${walletAddress}&Token=${selectedToken.symbol}&amount=${Amount}&amountUSD=${TokenAmount}&CoinAmount=${CoinAmt}&CoinAmountUSD=${TokenUSDprice}&TokenHash=${signature}&network=622463&solanaWalletAddress=${solanaWalletAddress}&TokenStatus=${status}`
        );
        console.log(receiveTokens);
        if (receiveTokens.data.status === "success") {
          toast.success(`${selectedToken.symbol} transaction successful`, {
            id: toastId1,
          });
          toast.success(
            "Your Tokens will be Claimable Once  Presale is Over!...."
          );
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      } else {
        toast.error("Transaction failed", { id: toastId1 });
      }
    } catch (error) {
      toast.error("An unexpected error occurred while during this Transaction");
      console.error("Error performing the transaction:", error);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      console.error("Transfer failed:", error);
    }
  };

  const TransferBTC = async () => {
    try {
      setQrPopup(true);
    } catch (error) {
      console.error("Transfer failed:", error);
    }
  };

  const TransferSOL = async () => {
    try {
      setQrPopup(true);
    } catch (error) {
      console.error("Transfer failed:", error);
    }
  };

  const FetchUserAddress = async () => {
    const response = await axios.get(
      `${NODE_URL}/apiv2/checkAddress?address=${walletAddress}`
    );
    let res =
      response.data.length > 0 ? response.data[0].solanaWalletAddress : null;
    setSolanaWalletAddress(res);
  };

  const FetchPreOrder = async () => {
    try {
      const response = await axios.get(`${NODE_URL}/apiv2/FetchPreOrder`);

      let res = response.data.data[0];
      if (response.status === 200) {
        setPreOrder(res.PreOrder);
        setTokenUSDprice(res.TokenUSDprice);
        setMinQuantity(res.MinAmount);
        setMaxQuantity(res.MaxAmount);
        setPreOrderBalance(
          Number(res.PreOrder) - Number(response.data.totalCoinAmount)
        );
        setPurchased(response.data.totalCoinAmount);
        setPreSaleStatus(res.PresaleStatus);
        let number =
          Number(res.PreOrder) - Number(response.data.totalCoinAmount);
        let cal = (number / res.PreOrder) * 100;
        setPercentage(100 - cal);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    FetchPreOrder();

    const interval = setInterval(() => {
      FetchPreOrder();
    }, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (walletAddress !== null) {
      FetchUserAddress();
    }
  }, [walletAddress]);

  const getSolanaTransaction = async (transactionHash, fromAddress) => {
    const connection = new Connection(
      "https://red-special-sea.solana-mainnet.quiknode.pro/71805dc676a9b32991fd57b913fd3b7b6dff75f2",
      "confirmed"
    );

    let data = await connection.getConfirmedTransaction(transactionHash);
    if (!data || !data.transaction || !data.meta) {
      return { status: "failed", message: "Invalid transaction data" };
    }

    const keys = data.transaction._message.accountKeys;
    const sender = new PublicKey(keys[0]);
    const receiver = new PublicKey(keys[1]);
    console.log(sender.toString(), receiver.toString());
    console.log(fromAddress, adminWallet);

    if (sender.toString() !== fromAddress) {
      return { status: "failed", message: "Sender not found" };
    }
    if (receiver.toString() !== adminWallet) {
      return { status: "failed", message: "Receiver not found" };
    }

    const date = new Date(data.blockTime * 1000);

    let totalTransferred = 0;
    const preBalances = data.meta.preBalances;
    const postBalances = data.meta.postBalances;
    const fee = data.meta.fee;

    for (let i = 0; i < preBalances.length; i++) {
      let diff = preBalances[i] - postBalances[i];
      if (diff > fee) {
        totalTransferred = diff - fee;
        break;
      }
    }
    console.log(totalTransferred, selectedToken.decimals);

    const amountTransferred = totalTransferred / 10 ** selectedToken.decimals;
    console.log(amountTransferred);
    setAmount(amountTransferred);

    return {
      status: "success",
      Date: date,
      Amount: amountTransferred,
    };
  };

  const handleConfirm = async () => {
    try {
      const toastId1 = toast.loading("Token transaction in progress", {
        duration: 5000,
      });

      const result = await axios.get(
        `${NODE_URL}/apiv2/getcheckHash?hash=${transactionHash}`
      );

      console.log(result);

      if (result.data.status === true) {
        let hashData;
        console.log(walletAddressType);

        if (walletAddressType === "BTC") {
          hashData = await getBitcoinTransaction(transactionHash, fromAddress);
        } else if (walletAddressType === "PHANTOM") {
          hashData = await getSolanaTransaction(transactionHash, fromAddress);
        }

        console.log(hashData);

        if (hashData.status === "failed") {
          toast.error(hashData.message, { id: toastId1 });
        } else {
          const Check = {
            address: walletAddress,
            Token: selectedToken.symbol,
            amount: hashData.Amount,
            amountUSD: TokenAmount,
            CoinAmount: TokenAmount / Number(TokenUSDprice),
            CoinAmountUSD: TokenUSDprice,
            TokenHash: transactionHash,
            network: walletAddressType === "BTC" ? "1" : "900",
            solanaWalletAddress: solanaWalletAddress,
            TokenStatus: "success",
          };

          const receiveTokens = await axios.get(
            `${NODE_URL}/apiv2/getMemeTokens?address=${walletAddress}&Token=${selectedToken.symbol
            }&amount=${Check.amount}&amountUSD=${TokenAmount}&CoinAmount=${Check.CoinAmount
            }&CoinAmountUSD=${TokenUSDprice}&TokenHash=${transactionHash}&network=${walletAddressType === "BTC" ? "1" : "900"
            }&solanaWalletAddress=${solanaWalletAddress}&TokenStatus=1`
          );

          if (receiveTokens.data.status === "success") {
            toast.success(`${selectedToken.symbol} transaction successful`, {
              id: toastId1,
            });
            toast.success(
              "Your Tokens will be Claimable Once Presale is Over!"
            );
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          }
        }
      } else {
        toast.error("Transaction already exists", { id: toastId1 });
      }
      setQrPopup(false);
      setConfirmPopup(false);
    } catch (error) {
      toast.error("An unexpected error occurred during this Transaction");
      console.error("Error performing the transaction:", error);
      setQrPopup(false);
      setConfirmPopup(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const getBitcoinTransaction = async (transactionHash, fromAddress) => {
    const url = `https://blockstream.info/testnet/api/tx/${transactionHash}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      console.log(data);
      if (!data || !data.vin || !data.vout) {
        return { status: "failed", message: "Invalid transaction data" };
      }

      let senderFound = false;
      let receiverFound = false;
      let totalTransferred = 0;

      const inputAddress = data.vin[0].prevout.scriptpubkey_address;
      console.log(inputAddress, fromAddress);
      if (inputAddress === fromAddress) {
        senderFound = true;
      }

      data.vout.forEach((output) => {
        if (output.scriptpubkey_address === adminWallet) {
          receiverFound = true;
          totalTransferred += output.value;
        }
      });

      if (!senderFound) {
        return { status: "failed", message: "Sender not found" };
      }
      if (!receiverFound) {
        return { status: "failed", message: "Receiver not found" };
      }

      const date = new Date(data.status.block_time * 1000);

      const amountTransferred = totalTransferred / 10 ** 8;
      console.log(amountTransferred);
      return {
        status: "success",
        Date: date,
        Amount: amountTransferred,
      };
    } catch (error) {
      return { status: "failed", message: error.message };
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(adminWallet);
    toast.success("Address copied to clipboard!");
  };

  const settings = {
    infinite: true,
    speed: 5000,
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    rtl: true,
    autoplaySpeed: 250,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const settings2 = {
    infinite: true,
    speed: 5000,
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div>
      <div className="sec-1 font-fuzzy text-white relative">
        <video
          autoPlay
          loop
          muted
          playsinline
          className="absolute inset-0 w-full h-full object-cover"
        >
          <source src="/assets/Main.mp4" type="video/mp4" />
        </video>
        <div className="relative z-10">
          <Header />
        </div>
        <div className="relative h-full py-5">
          <div className="flex justify-center">
            <p className="text-center">
              <img
                className=""
                src="/assets/2024-07-23-FAKTORY-OF-MEMES-Org- (1).gif"
              ></img>
            </p>
          </div>
          <div className="flex justify-center">
            <p className="text-white font-medium font-fuzzy text-center 2xl:text-lg w-[80%]">
              To become the Willy Wonka's Chocolate Factory of meme coins,
              creating a whimsical, fun, and profitable universe where every
              meme coin is a golden ticket to laughter and wealth.
            </p>
          </div>
          <div className="flex flex-col md:flex-row md:w-[90%] gap-10 md:gap-0 mx-auto mt-10">
            <div className="md:w-[50%] grid content-center">
              <div
                className="font-Share bg-white border-2 border-black text-black md:w-[80%] 2xl:w-[75%] mx-auto h-full px-3 md:px-10 pb-10"
                style={{ boxShadow: "5px 5px black" }}
              >
                <div className="flex justify-center">
                  <div className="flex justify-center items-center">
                    <p className="text-xl pt-5 font-syne font-semibold">
                      Buy FOMO Presale
                    </p>
                  </div>
                </div>
                <Countdown />
                <div className='flex justify-between pb-0.5'>
                  <p>Total Sales</p>
                  <p>Total Volume</p>
                </div>
                <div>
                  <div className="w-full h-2 bg-black/20 rounded">
                    <div
                      className={`h-2 rounded bg-[#F23985]`}
                      style={{ width: `${Percentage}%` }}
                    ></div>
                  </div>
                  <div className="flex justify-between mt-1.5 font-Share ">
                    <p>{Purchased.toFixed()}</p>
                    <p>{PreOrder}</p>
                  </div>
                  <p className="text-center font-Share">
                    <span className="text-[#F23985] font-semibold">1 </span>{" "}
                    FOMO ={" "}
                    <span className="text-[#F23985] font-semibold">
                      {TokenUSDprice}
                    </span>{" "}
                    USD
                  </p>

                  <p className="">Select Token</p>
                  <div className="relative">
                    <div
                      style={{ boxShadow: "5px 5px black" }}
                      className={`cursor-pointer py-2 px-5 w-full border border-black flex justify-between items-center ${selectedIndex !== null
                        ? "bg-white  brightness-100"
                        : `bg-white ${isAnyTokenSelected
                          ? "brightness-50"
                          : "brightness-100"
                        }`
                        }`}
                      onClick={handleTokenDropdown}
                    >
                      <div className="flex items-center">
                        {selectedToken ? (
                          <>
                            <img
                              src={selectedToken.img}
                              alt={selectedToken.symbol}
                              className="md:w-8 md:h-8 w-5 h-5 border-none"
                            />
                            <div className="ml-3">
                              <p className="text-center font-bold text-lg">
                                {selectedToken.symbol}
                              </p>
                            </div>
                          </>
                        ) : (
                          <p className="text-center  text-lg">Choose Token</p>
                        )}
                      </div>

                      <i
                        className="fa-solid fa-chevron-down"
                        style={{ color: "black" }}
                      ></i>
                    </div>

                    {TokenDropdown && (
                      <div className="absolute z-30 bg-white border border-black/50 left-0 w-full h-[200px] overflow-y-auto custom-scrollbar1">
                        {TokenTotal.length > 0 ? (
                          TokenTotal.map((data, index) => (
                            <div
                              key={index}
                              className={`px-5 py-2 hover:bg-black/20`}
                            >
                              <div
                                className="flex items-center gap-5 cursor-pointer "
                                onClick={() => choseTokens(index, data)}
                              >
                                <img
                                  src={data.img}
                                  className="w-5 h-5"
                                  alt={`Dropdown item ${data.symbol}`}
                                />
                                <p className="text-sm">{data.symbol}</p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="px-5 py-2 text-sm text-gray-400">
                            Select the Network
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col md:flex-row gap-5 mt-3">
                    <div className="md:w-[50%]">
                      <p className="">Select Network</p>
                      <div className="relative inline-block w-full">
                        <div
                          style={{ boxShadow: "5px 5px black" }}
                          className="cursor-pointer py-2 px-3 w-full flex justify-between items-center bg-white border border-black"
                          onClick={handleNetworkDropdown}
                        >
                          <div className="flex items-center">
                            {selectedNetwork?.img ? (
                              <>
                                <img
                                  src={selectedNetwork.img}
                                  className="w-6 h-6"
                                  alt={`${selectedNetwork.name} icon`}
                                />
                                <div className="ml-3">
                                  <p className="text-center font-bold text-lg">
                                    {selectedNetwork.name}
                                  </p>
                                </div>
                              </>
                            ) : (
                              <p className="text-center text-lg">
                                Choose Network
                              </p>
                            )}
                          </div>
                          <i
                            className="fa-solid fa-chevron-down"
                            style={{ color: "black" }}
                          ></i>
                        </div>

                        {NetworkDropdown && (
                          <div className="absolute z-20 bg-white border border-black/50 left-0 w-full">
                            {filteredNetworks.map((data, index) => (
                              <div
                                key={index}
                                className={`px-5 py-2 hover:bg-black/20 `}
                              >
                                <div
                                  className="flex items-center gap-5 cursor-pointer"
                                  onClick={() => choseNetworkList(index, data)}
                                >
                                  <img
                                    src={data.img}
                                    className="w-5 h-5"
                                    alt={`Dropdown item ${data.name}`}
                                  />
                                  <p className="text-sm">{data.name}</p>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <p className=" mt-3">
                        You Receive:{" "}
                        <span>
                          {TokenAmount
                            ? (TokenAmount / Number(TokenUSDprice)).toFixed(2)
                            : TokenUSDprice}{" "}
                          <span className="font-normal">FOMO</span>
                        </span>
                      </p>
                    </div>

                    <div className="md:w-[50%]">
                      <p className="">Enter Quantity</p>
                      <input
                        style={{ boxShadow: "5px 5px black" }}
                        className="w-[100%]  mb-3 text-lg bg-white px-3 py-2 border border-black  outline-none placeholder:text-black"
                        placeholder="0"
                        onChange={(e) => handleInputChange(e)}
                      ></input>
                      {Text === true && (
                        <span className="text-red-600 font-semibold">
                          The amount entered should be less than the maximum.
                        </span>
                      )}
                    </div>
                  </div>


                </div>
                <div className="flex justify-center mt-5">
                  <a className=" rounded-md bg-[#F23985] w-full border border-black font-medium h-12  py-2  flex items-center justify-center">
                    <button className="text-white " onClick={Transfer}>
                      Buy Now
                    </button>
                  </a>
                </div>
              </div>
              {/*
              <p
                className="font-syne text-[#D9D9D9]/15 font-extrabold text-5xl md:text-[96px] lg:translate-x-[45%] mt-7 md:mt-10 md:h-[5rem]"
                style={{
                  WebkitTextStrokeWidth: "1px",
                  WebkitTextStrokeColor: "#FFFFFF4D",
                }}
              >
                GIZMO
              </p>
              */}
            </div>
            <div className="md:w-[50%] flex">
              <div>
                <img src="assets/Group 1000015067.png"></img>
              </div>
              <div>
                <img src="assets/Group 1000015066.png"></img>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#F64570]/50 font-bold backdrop-blur-3xl py-6 flex justify-around ">
          <h1
            className="text-center text-white text-xl"
            style={{
              WebkitTextStrokeWidth: "0.5px",
              WebkitTextStrokeColor: "black",
            }}
          >
            GIZMO
          </h1>
          <h1
            className="text-center text-white text-xl"
            style={{
              WebkitTextStrokeWidth: "0.5px",
              WebkitTextStrokeColor: "black",
            }}
          >
            GIZMO
          </h1>
          <h1
            className="text-center text-white text-xl"
            style={{
              WebkitTextStrokeWidth: "0.5px",
              WebkitTextStrokeColor: "black",
            }}
          >
            GIZMO
          </h1>
          <h1
            className="text-center text-white text-xl hidden md:block"
            style={{
              WebkitTextStrokeWidth: "0.5px",
              WebkitTextStrokeColor: "black",
            }}
          >
            GIZMO
          </h1>
          <h1
            className="text-center text-white text-xl hidden md:block"
            style={{
              WebkitTextStrokeWidth: "0.5px",
              WebkitTextStrokeColor: "black",
            }}
          >
            GIZMO
          </h1>
          <h1
            className="text-center text-white text-xl hidden md:block"
            style={{
              WebkitTextStrokeWidth: "0.5px",
              WebkitTextStrokeColor: "black",
            }}
          >
            GIZMO
          </h1>
        </div>
      </div>

      <div
        className="sec-2 bg-[#363159] pb-20 pt-10 bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: "url('/assets/Rectangle 22713.png')" }}>
        <div>
          <p className="text-white font-medium font-fuzzy text-center 2xl:text-lg w-[90%] mx-auto pb-5">
            To churn out meme coins with the same vigor as a popcorn machine at
            a movie theater, ensuring every coin is a blockbuster hit. We aim to
            tickle the crypto community's funny bone while filling their wallets
            with the sweetest profits.
          </p>
        </div>
        <div className="mt-3">
          <img
            src="/assets/Group 1597883227.png"
            className="w-[85%] mx-auto"
          ></img>
        </div>
        <div className="relative flex justify-center items-center mt-20">
          <div>
            <img src="assets/Group 1000015072.png" className=""></img>
          </div>
          <div className="absolute ">
            <img
              src="/assets/Rotating_earth_animated_transparent.gif"
              className=" world lg:w-[100%] w-[50%] pr- mx-auto"
            />
          </div>
        </div>
        <div className="flex justify-center items-center gap-5 2xl:gap-10 lg:-translate-y-[170px] 2xl:-translate-y-[200px]">
          <a href="https://x.com/FOMO_Gizmo" target="_blank">
            <div>
              <img src="assets/x.png" className="cursor-pointer"></img>
            </div>
          </a>
          <a
            href="https://discord.com/channels/1274941772618924063/1274941772618924067"
            target="_blank"
          >
            <div>
              <img
                src="assets/Discord.png"
                className="cursor-pointer h-[65px]"
              ></img>
            </div>
          </a>
          <a href="https://t.me/FOMOmemecoins" target="_blank">
            <div>
              <img
                src="assets/Telegram.png"
                className="cursor-pointer h-[65px]"
              ></img>
            </div>
          </a>
        </div>
        <div>
          <p className="text-center font-fuzzy text-white text-2xl">
            <span className="text-[#64ffff]">Disclaimer:&nbsp;</span> MEME COINS
            are subject to market risks, DYOR before investing.{" "}
          </p>
        </div>
      </div>

      <div
        className="py-10 bg-no-repeat bg-center bg-cover"
        style={{ backgroundImage: "url('/assets/Group 1 (11).png')" }}
      >
        <div>
          <p className="font-fuzzy text-3xl md:text-6xl text-white text-center">
            How to Buy?
          </p>
        </div>
        <div className="flex justify-center mt-6 font-fuzzy mx-auto">
          <div className="flex flex-col gap-4 bg-[#9d3a6f] py-5 px-8">
            <div>
              <p className=" text-white italic font-bold text-xl">
                Connect Your Crypto Wallet
              </p>
              <p className=" text-white  text-lg">
                1.Click on "Connect Wallet": Usually located at the top right
                corner.
              </p>
              <p className=" text-white  text-lg">
                2.Select Wallet: Choose either MetaMask or Phantom.
              </p>
              <p className=" text-white  text-lg">
                3.Authorize Connection: Follow the prompts to authorize the
                connection.
              </p>
            </div>

            <div>
              <p className=" text-white italic font-bold text-xl">
                Select Payment Method
              </p>
              <p className=" text-white  text-lg">
                1.Choose Cryptocurrency: Select the cryptocurrency you want to
                use for purchasing FOMO tokens.
              </p>
              <p className=" text-white  text-lg">
                Note: To purchase using Solana, you will need a
                Solana-compatible wallet like Phantom.
              </p>
            </div>

            <div>
              <p className=" text-white italic font-bold text-xl">
                Enter Purchase Amount
              </p>
              <p className=" text-white  text-lg">
                1.Specify Amount: Input the amount of cryptocurrency you want to
                spend.
              </p>
              <p className=" text-white  text-lg">
                2.Review Token Amount: Check the number of tokens you'll
                receive.
              </p>
              <p className=" text-white  text-lg">
                3.Press ‘Buy Now’
              </p>
            </div>

            <div>
              <p className=" text-white italic font-bold text-xl">
                Complete the Purchase
              </p>
              <p className=" text-white  text-lg">
                1.Confirm Transaction: Review all details and confirm the
                transaction in your wallet.
              </p>
              <p className=" text-white  text-lg">
                2.Wait for Confirmation: Allow a few moments for the transaction
                to be processed and confirmed on the blockchain.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="sec-3 font-fuzzy text-white ">
        <div className='relative flex items-center justify-center '>
          <video
            autoPlay
            loop
            muted
            className="absolute z-0 w-full h-full lg:h-screen object-cover blur-xs"
          >
            <source src="/assets/Second.mp4" type="video/mp4" />
          </video>
          <div className='relative z-10 pb-32 '>
            <div className=" flex justify-center items-center">
              <p className="font-fuzzy text-3xl lg:text-6xl">MEME EVOLUTION</p>
              <img className="w-2/12 lg:w-1/12" src="/assets/image 155.png"></img>
            </div>

            <div className='flex flex-col font-fuzzy md:flex-row mt-10 justify-between gap-32 md:gap-10 items-center w-[90%] mx-auto'>
              <div className='md:w-[12.5%] relative'>
                <img src='/assets/meme1.webp' className='border-2 rounded-2xl'></img>
                <div className="absolute -bottom-[12rem] md:-bottom-[7rem] 2xl:-bottom-[8rem] text-center w-[100%]">
                  <img src='/assets/green.png' className='w-[90%] mx-auto'></img>
                  <p className="-translate-y-20 md:-translate-y-10">Since 2010</p>
                </div>
              </div>
              <div className='md:w-[15%] relative'>
                <img src='/assets/meme2.jpg' className='border-2 rounded-2xl'></img>
                <div className="absolute  -bottom-[12rem] md:-bottom-[7rem] 2xl:-bottom-[8rem] text-center w-[100%]">
                  <img src='/assets/green.png' className='w-full'></img>
                  <p className="-translate-y-[84px] md:-translate-y-10">Since 2022</p>
                </div>
              </div>
              <div className='md:w-[17.5%] relative '>
                <img src='/assets/meme3.jpg' className='border-2 rounded-2xl'></img>
                <div className="absolute -bottom-[10rem] md:-bottom-[7rem] 2xl:-bottom-[8rem] text-center w-[100%]">
                  <img src='/assets/red.png' className=' w-full'></img>
                  <p className="-translate-y-10 md:-translate-y-5">Since 2022</p>
                </div>
              </div>
              <div className='md:w-[15%] relative'>
                <img src='/assets/meme4.jpg' className='border-2 rounded-2xl'></img>
                <div className="absolute -bottom-[12rem] md:-bottom-[7rem] 2xl:-bottom-[8rem] text-center w-[100%]">
                  <img src='/assets/green.png' className='w-full'></img>
                  <p className="-translate-y-[84px] md:-translate-y-10">Since 2021</p>
                </div>
              </div>
              <div className='md:w-[12.5%] relative'>
                <img src='/assets/meme5.jpg' className='border-2 rounded-2xl'></img>
                <div className="absolute -bottom-[12rem] md:-bottom-[7rem] 2xl:-bottom-[8rem] text-center w-[100%]">
                  <img src='/assets/green.png' className='w-[90%] mx-auto'></img>
                  <p className="-translate-y-20 md:-translate-y-10">Since 2011</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*
        <div className="relative z-10 flex justify-center">
          <img
            className="w-[75%] lg:w-[99%]"
            src="/assets/Group 48096136 (3).png"
          ></img>
        </div>
*/}
        <div
          className="bg-[#363159] py-20  bg-cover bg-center bg-no-repeat "
          style={{ backgroundImage: "url('/assets/Group 1000015079.png')" }}
        >
          <div className="flex justify-center w-[90%] mx-auto">
            <img src="/assets/image 185.png"></img>
          </div>
          <div className="flex flex-col lg:flex-row justify-around w-[100%] mx-auto">
            <div className="flex items-center justify-center">
              <img className="" src="/assets/timer.gif"></img>
            </div>
            <img src="/assets/Group 1597883168.png"></img>
          </div>
        </div>

        {/* our team section */}
        <div
          className="relative sec-2 bg-[#363159] py-20  bg-cover bg-center bg-no-repeat "
          style={{ backgroundImage: "url('/assets/Rectangle 22713.pngs')" }}
        >
          {/*Tokenomics*/}
          <div className="text-center">
            <h2
              className="text-center text-white uppercase text-3xl md:text-6xl tracking-widest"
              style={{
                WebkitTextStrokeWidth: "1px",
                WebkitTextStrokeColor: "black",
              }}
            >
              Tokenomics
            </h2>
            <div className="flex flex-col md:flex-row gap-5 w-[90%] mx-auto">
              <div className="lg:w-[50%]">
                <img src="/assets/Group 1597883168 (2).png"></img>
              </div>
              <div className="lg:w-[50%] text-start  text-xl grid content-center gap-3">
                <p className="flex gap-3">
                  <span className="flex items-center">
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="text-[8px] text-[#F64570]"
                    />
                  </span>
                  The Meme Dream (Stage 1:&nbsp; Presale Allocation) 30%
                </p>
                <p className="flex gap-3">
                  <span className="flex items-center">
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="text-[8px] text-[#776999]"
                    />
                  </span>
                  Geargi's Foundry (Stage 2:&nbsp; Development and Team) 15%
                </p>
                <p className="flex gap-3">
                  <span className="flex items-center">
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="text-[8px] text-[#6A50A7]"
                    />
                  </span>
                  Hype Train (Stage 3:&nbsp; Marketing and Partnerships) 20%
                </p>
                <p className="flex gap-3">
                  <span className="flex items-center">
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="text-[8px] text-[#5934B0]"
                    />
                  </span>
                  Meme Treasure Trove (Stage 4:&nbsp; Community Rewards and
                  Staking) 15%
                </p>
                <p className="flex gap-3">
                  <span className="flex items-center">
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="text-[8px] text-[#B31BC5]"
                    />
                  </span>
                  Liquidity lagoon 10%
                </p>
                <p className="flex gap-3">
                  <span className="flex items-center">
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="text-[8px] text-[#218FA5]"
                    />
                  </span>
                  Treasury 10%
                </p>
              </div>
            </div>
          </div>
          {/*About us*/}
          <div className=" mx-auto overflow-hidden ">
            <div className="relative block mx-auto w-full text-center">
              <h2
                className="text-center text-white uppercase text-3xl md:text-6xl tracking-widest"
                style={{
                  WebkitTextStrokeWidth: "1px",
                  WebkitTextStrokeColor: "black",
                }}
              >
                About us
              </h2>
              <p className=" text-lg my-10 w-[85%] mx-auto">
                At Faktory of Memes, we turn laughter into loot! Here’s the
                deal: you share your funniest meme and the FOMO holders get
                cheeky to vote on which ones should become a coin. Watch out for
                the launch and your pocket—it's about to get fatter than a
                Thanksgiving turkey Remember, no idea is too silly!
              </p>

              <div className="bg-[#F64570]/40 w-[15rem] h-[40rem] absolute z-20  blur-3xl -left-20 top-20"></div>
              <div className="bg-[#F64570]/40 w-[15rem] h-[40rem] absolute z-20  blur-3xl -right-20 top-20"></div>

              <div className="relative flex items-center overflow-hidden ">
                {" "}
                <div className="carousel z-10 relative">
                  <Slider className=" mx-auto " {...settings}>
                    <div>
                      <img
                        src="/assets/Rectangle 2.png"
                        className="w-[95%] mx-auto"
                      ></img>
                    </div>
                    <div>
                      <img
                        src="/assets/Rectangle 3.png"
                        className="w-[95%] mx-auto"
                      ></img>
                    </div>
                    <div>
                      <img
                        src="/assets/Rectangle 4.png"
                        className="w-[95%] mx-auto"
                      ></img>
                    </div>
                    <div>
                      <img
                        src="/assets/Rectangle 5.png"
                        className="w-[95%] mx-auto"
                      ></img>
                    </div>
                    <div>
                      <img
                        src="/assets/Rectangle 2.png"
                        className="w-[95%] mx-auto"
                      ></img>
                    </div>
                    <div>
                      <img
                        src="/assets/Rectangle 3.png"
                        className="w-[95%] mx-auto"
                      ></img>
                    </div>
                    <div>
                      <img
                        src="/assets/Rectangle 4.png"
                        className="w-[95%] mx-auto"
                      ></img>
                    </div>
                    <div>
                      <img
                        src="/assets/Rectangle 5.png"
                        className="w-[95%] mx-auto"
                      ></img>
                    </div>
                  </Slider>
                </div>
                <div className="absolute inset-0 z-0">
                  <Slider className=" mx-auto " {...settings2}>
                    <div>
                      <img
                        src="/assets/Rectangle 5.png"
                        className="w-[100%]"
                      ></img>
                    </div>
                    <div>
                      <img
                        src="/assets/Rectangle 2.png"
                        className="w-[100%]"
                      ></img>
                    </div>
                    <div>
                      <img
                        src="/assets/Rectangle 4.png"
                        className="w-[100%]"
                      ></img>
                    </div>
                    <div>
                      <img
                        src="/assets/Rectangle 3.png"
                        className="w-[100%]"
                      ></img>
                    </div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </Slider>
                </div>
              </div>

              <div className="my-10  bg-gradient-to-b from-[#D9D9D9]/50 to-[#F97998]/50 w-[90%] md:w-[40%] py-5 px-5 md:px-10 mx-auto shadow-lg shadow-[#F97998]/30">
                <p className="text-2xl text-center">
                  Nitty Gritty Of the New Meme Coin Creation
                </p>
                <p className=" text-start mt-2 px-5">
                  1. Idea Generation & FOMO Investor Voting
                </p>
                <p className=" text-start px-5">
                  2. Coinification of the Meme
                </p>
                <p className=" text-start px-5 mb-4 lg:mb-0">
                  3. Launch
                </p>
                <a
                  className=""
                  href="/assets/Faktory of Memes Org.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="bg-red-600 text-white w-40 h-10 rounded-md shadow-md">
                    {" "}
                    Whitepaper
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className="container mx-auto ">
            <div className="block mx-auto w-full text-center">
              <h2
                className="text-center text-white text-3xl md:text-6xl tracking-widest"
                style={{
                  WebkitTextStrokeWidth: "1px",
                  WebkitTextStrokeColor: "black",
                }}
              >
                OUR TEAM
              </h2>
            </div>

            <div className="flex lg:flex-nowrap flex-wrap items-center lg:justify-evenly justify-center mt-20 gap-10 px-5">
              <div
                className="lg:w-[25%] md:w-1/3 sm:w-[45%] w-full p-2 sm:h-[560px] h-auto sm:bg-contain bg-cover  sm:bg-no-repeat bg-repeat-round mx-1 mb-6"
                style={{ backgroundImage: "url('/assets/border-line.png')" }}
              >
                <div
                  className="sm:bg-contain  sm:bg-no-repeat bg-repeat-round h-full w-full bg-cover "
                  style={{ backgroundImage: "url('/assets/bg-ourteam.png')" }}
                >
                  <div className="block  p-2 ">
                    <img
                      src="/assets/avatar-dog.png"
                      className="sm:w-auto h-auto w-full"
                    />
                    <h4 className="mt-1 text-[#33FCFF] font-bold lg:text-xl text-lg ">
                      GIZMO
                    </h4>
                    <p className="text-[#B8B7D0] font-semibold text-base ">
                      CEO
                    </p>
                    <ul className="2xl:w-[80%] xl:w-[100%] text-[#B8B7D0] font-medium ">
                      <li className="text-[#B8B7D0]  text-xs font-medium">
                        - Founder or Alive
                      </li>
                      <li className="text-[#B8B7D0] mt-1 font-medium text-[9px] xl:text-xs xl:text-xs">
                        - Professor of Memeology at Internet Culture Evangelist
                        Meme University
                      </li>
                      <li className="text-[#B8B7D0] mt-1 font-medium text-[9px] xl:text-xs">
                        - Fearless Optimistic Meme Overlord.
                      </li>
                      <li className="text-[#B8B7D0] mt-1 font-medium text-[9px] xl:text-xs ">
                        Meme Maestro
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="lg:w-[25%] md:w-1/3 sm:w-[45%] w-full p-2 sm:h-[560px] bg-cover h-auto sm:bg-contain sm:bg-no-repeat bg-repeat-round lg:mt-28  mx-1 mb-6"
                style={{ backgroundImage: "url('/assets/border-line.png')" }}
              >
                <div
                  className="sm:bg-contain sm:bg-no-repeat bg-repeat-round h-full w-full bg-cover"
                  style={{ backgroundImage: "url('/assets/bg-ourteam.png')" }}
                >
                  <div className="block h-full p-2 ">
                    <img
                      src="/assets/avatar-dog1.png"
                      className="sm:w-auto h-auto w-full"
                    />
                    {/* <h4 className="mt-2 text-[#33FCFF] font-bold lg:text-xl text-lg ">
                      Lyo Nguyen
                    </h4> */}
                    <p className="text-[#B8B7D0] mt-1 font-semibold text-base ">
                      CPO
                    </p>
                    <p className="w-[90%] text-[#B8B7D0] font-semibold text-[10.5px] ">
                      Community engagement Director
                    </p>
                    <ul className="w-[80%] text-[#B8B7D0] font-medium ">
                      <li className="text-[#B8B7D0] mt-1 font-medium text-[8px] xl:text-xs">
                        ·  Fun Architect: Designs and implements strategies that
                        make the Facktory of Memes the most entertaining place
                        in the crypto universe.{" "}
                      </li>
                      <li className="text-[#B8B7D0] mt-1 font-medium text-[8px] xl:text-xs">
                        {" "}
                        ·  Meme Coin Maestro: Oversees the creation of new meme
                        coins, ensuring each one is funnier and more engaging
                        than the last.
                      </li>
                      <li className="text-[#B8B7D0] mt-1 font-medium text-[8px] xl:text-xs">
                        ·  Community Cheerleader: Keeps the community laughing
                        and engaged with regular contests, games, and
                        interactive events.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="lg:w-[25%] md:w-1/3 sm:w-[45%] w-full p-2 sm:h-[560px] bg-cover h-auto sm:bg-contain sm:bg-no-repeat bg-repeat-round  mx-1 mb-6"
                style={{ backgroundImage: "url('/assets/border-line.png')" }}
              >
                <div
                  className="sm:bg-contain  sm:bg-no-repeat bg-repeat-round h-full w-full bg-cover"
                  style={{ backgroundImage: "url('/assets/bg-ourteam.png')" }}
                >
                  <div className="block h-full p-2 ">
                    <img
                      src="/assets/avatar-dog2.png"
                      className="sm:w-auto h-auto w-full"
                    />
                    <h4 className="mt-2 text-[#33FCFF] font-bold lg:text-xl text-lg ">
                      Viral Poodle
                    </h4>
                    <p className="text-[#B8B7D0] font-semibold text-base ">
                      CMO
                    </p>
                    <ul className="w-[80%] text-[#B8B7D0] font-medium ">
                      <li className="text-[#B8B7D0] mb-2 text-sm font-medium text-sm">
                        Chief Meme Officer
                      </li>
                      <li className="text-[#B8B7D0] mt-1 font-medium text-[9px] xl:text-xs">
                        Bachelor of Viral Marketing
                      </li>
                      <li className="text-[#B8B7D0] mt-1 font-medium text-[9px] xl:text-xs">
                        Mission: To ensure that the crypto community laughing
                        all the way to the bank.
                      </li>
                      <li className="text-[#B8B7D0] mt-1 font-medium text-[9px] xl:text-xs">
                        Crypto Comic: Successfully launched several meme coins,
                        each one more popular (and hilarious) than the last.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="lg:w-[25%] md:w-1/3 sm:w-[45%] w-full p-2 sm:h-[560px] bg-cover h-auto sm:bg-contain sm:bg-no-repeat bg-repeat-round lg:mt-28  mx-1 mb-6"
                style={{ backgroundImage: "url('/assets/border-line.png')" }}
              >
                <div
                  className="sm:bg-contain sm:bg-no-repeat bg-repeat-round h-full w-full bg-cover"
                  style={{ backgroundImage: "url('/assets/bg-ourteam.png')" }}
                >
                  <div className="block h-full p-2 ">
                    <img
                      src="/assets/avatar-dog3.png"
                      className="sm:w-auto h-auto w-full"
                    />
                    <h4 className="mt-2 text-[#33FCFF] font-bold lg:text-xl text-lg ">
                      Pepe McCoin
                    </h4>
                    <p className="w-[80%] text-[#B8B7D0] font-semibold text-xs ">
                      Community engagement Director
                    </p>
                    <ul className="w-[80%] text-[#B8B7D0] font-medium ">
                      <li className="text-[#B8B7D0] mt-1 font-medium text-[9px] xl:text-xs">
                        Education: Graduated top of his class from the Pond
                        University of Social Interaction with a degree in
                        Ribbiting Communication
                      </li>
                      <li className="text-[#B8B7D0] mt-1 font-medium text-[9px] xl:text-xs">
                        Viral Visionary: Consistently identified emerging trends
                        and turned them into viral sensations.
                      </li>
                      <li className="text-[#B8B7D0] mt-1  font-medium text-[9px]">
                        Humor Crafting: Expert in creating content that makes
                        people laugh out loud.{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {qrPopup && (
        <div className=" z-50 fixed inset-0 flex items-center  justify-center backdrop-blur-md">
          <div className="p-6 rounded-lg shadow-lg w-11/12 max-w-4xl border bg-[#363159] flex text-white">
            <div className="flex-1 py-8 flex items-center justify-center">
              <QRCode
                className="border-4 rounded-xl"
                value={adminWallet}
                size={200}
              />
            </div>
            <div className="flex-1 p-4 pl-6 flex flex-col justify-center items-center">
              <div className="flex items-center mb-4">
                <span className=" mr-2  text-bold text-md text-center">
                  {adminWallet}
                </span>
                <ClipboardDocumentIcon
                  className="w-5 h-5  cursor-pointer hover:text-gray-800 transition"
                  onClick={handleCopy}
                  title="Copy to clipboard"
                />
              </div>
              <div className=" mb-6 text-center">
                <p className="mb-1">
                  Amount: <span className="font-bold">{Amount}</span>
                </p>
                <p>
                  Token Name:{" "}
                  <span className="font-bold">
                    {selectedToken ? selectedToken.symbol : "USDT"}
                  </span>
                </p>
              </div>
              <div className="flex mt-6 justify-between w-full max-w-xs">
                <button
                  className="bg-[#F1456E] text-white px-4 py-2 rounded-lg shadow-md hover:bg-[#D93D57] transition w-full mr-2"
                  onClick={() => setConfirmPopup(true)}
                >
                  Paid
                </button>
                <button
                  className="bg-gray-300 text-gray-800 px-4 py-2 rounded-lg shadow-md hover:bg-gray-400 transition w-full ml-2"
                  onClick={() => setQrPopup(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {confirmPopup && (
        <div className=" z-50 fixed inset-0 flex items-center justify-center  p-2  backdrop-blur-md">
          <div className="relative w-full md:w-[50%] xl:w-[45%] border border-black p-6 rounded-xl bg-gradient-to-b from-[#D9D9D9] to-[#F97998]/60 shadow-lg  max-w-xlg">
            <button
              className="absolute text-2xl top-4 right-4 text-[#57345D] hover:text-gray-900 transition"
              onClick={() => {
                setQrPopup(false);
                setConfirmPopup(false);
              }}
            >
              &times;
            </button>
            <h2 className="text-black text-lg font-semibold mb-4 text-center">
              Confirm Payment
            </h2>
            <div className="mt-4 ">
              <label className="text-black text-lg font-semibold">
                Enter the wallet Address
              </label>
              <input
                type="text"
                placeholder="From Address"
                value={fromAddress}
                onChange={(e) => setFromAddress(e.target.value)}
                className="border-2 border-[#57345D] p-2 w-full mt-1 rounded-lg mb-2 focus:outline-none transition"
              />
            </div>
            <div className="mt-4 ">
              <label className="text-black text-lg font-semibold">
                Enter the Transaction Hash
              </label>
              <input
                type="text"
                placeholder="Transaction Hash"
                value={transactionHash}
                onChange={(e) => setTransactionHash(e.target.value)}
                className="border-2 border-[#57345D] p-2 w-full rounded-lg  mt-1 mb-4 focus:outline-none transition"
              />
            </div>
            <div className="flex justify-center">
              <button
                className="px-4 py-2 bg-[#F1456E] rounded-lg shadow-lg mt-4 text-white hover:bg-[#D93D57] transition"
                onClick={handleConfirm}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
