import { Contract_details } from "./Components/Config";
export const Network_Options = [
  {
    id: 3,
    name: "Polygon",
    token: "MATIC",
    img: "https://polygonscan.com/assets/poly/images/svg/logos/token-light.svg?v=24.6.3.0",
    chainId: Contract_details.POLYGON_CHAIN_ID,
  },
  {
    id: 0,
    name: "Ethereum",
    token: "ETH",
    img: "https://etherscan.io/images/svg/brands/ethereum-original.svg",
    chainId: Contract_details.ETH_CHAIN_ID,
  },
  // {
  //   name: "Bitcoin",
  //   token: "BTC",
  //   img: "https://btcscan.org/img/icons/Bitcoin-menu-logo.svg",
  //   chainId: "2203",
  // },
  {
    id: 1,
    name: "BNB Chain",
    token: "BNB",
    img: "https://bscscan.com/assets/bsc/images/svg/logos/token-light.svg?v=24.7.2.0",
    chainId: Contract_details.BSC_CHAIN_ID,
  },
  // {
  //   name: "Avalanche",
  //   token: "AVAX",
  //   img: "https://snowtrace.io/cdn/chains/avax/avax_32.avif",
  //   chainId: "43114",
  // },
  // {
  //   name: "Ripple",
  //   token: "XRP",
  //   img: "https://bscscan.com/token/images/xrp_32.png",
  //   chainId: "7668",
  // },
  {
    id: 2,
    name: "Base",
    token: "ETH",
    img: "https://basescan.org/assets/base/images/svg/logos/chain-light.svg?v=24.7.2.0",
    chainId: Contract_details.BASE_CHAIN_ID,
  },
  {
    id: 4,
    name: "TRON",
    token: "TRX",
    img: "https://static.tronscan.org/production/logo/trx.png",
    chainId: Contract_details.TRON_CHAIN_ID,
  },
];

