import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { NODE_URL } from "../Components/Config";

export default function UserDashboard() {
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [TotalUsers, setTotalUsers] = useState(null);
  const [ActiveUsers, setActiveUsers] = useState(null);
  const [InactiveUsers, setInactiveUsers] = useState(null);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${NODE_URL}/apiv2/getWalletAddress`);
      console.log(response);
      const result = await response.data;
      if (result.status) {
        setUserData(result.data);
        setTotalUsers(result.data.length);
        setActiveUsers(
          result.data.filter((user) => user.accountStatus === "Active").length
        );
        setInactiveUsers(
          result.data.filter((user) => user.accountStatus === "Inactive").length
        );
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDropdownChange = (event) => {
    setEntitiesPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const handlePrevClick = () => {
    setCurrentPage((prev) => Math.max(prev -1 ));
  };

  const handleNextClick = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleRedirect = (walletAddress) => {
    navigate(`/admin/userdetails?wallet=${walletAddress}`);
  };

  const startIndex = (currentPage - 1) * entitiesPerPage;
  const currentEntities = userData.slice(startIndex, startIndex + entitiesPerPage);

  return (
    <div className="flex flex-col lg:flex-row lg:bg-transparent bg-blue-500" >
      <Layout />
      <div
        className="w-[100%] bg-cover bg-center bg-no-repeat bg-[#363159]"
        style={{ backgroundImage: "url('/assets/bg-fomo.jpeg')" }}
      >
        <div className="h-screen py-10 overflow-hidden overflow-y-scroll font-syne text-white">
          
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-7 lg:gap-10 md:w-[90%] mx-auto my-5">
              <div className="bg-[#0A0F2185] backdrop-blur-sm  border border-white/60 text-center rounded-xl py-5">
                <p className="text-4xl border-b pb-5 border-white/60 font-bold">
                  {TotalUsers ?? 0}
                </p>
                <p className="mt-3 font-bold">Total Users</p>
              </div>
              <div className="bg-[#0A0F2185] backdrop-blur-sm  border border-white/60 text-center rounded-xl py-5">
                <p className="text-4xl border-b pb-5 border-white/60 font-bold">
                  {ActiveUsers ?? 0}
                </p>
                <p className="mt-3 text-[#0DFF25] font-bold">Active Users</p>
              </div>
              <div className="bg-[#0A0F2185] backdrop-blur-sm  border border-white/60 text-center rounded-xl py-5">
                <p className="text-4xl border-b pb-5 border-white/60 font-bold">
                  {InactiveUsers ?? 0}
                </p>
                <p className="mt-3 text-[#FF4D4D] font-bold">In-Active Users</p>
              </div>
            </div>
          <div className="w-[90%] mx-auto backdrop-blur-sm bg-[#0A0F2185] border border-[#FFFFFF5C] rounded-2xl py-5 px-5 md:px-10 mt-5 font-syne">
          
            <div className="flex justify-between pb-5">
              <div>
                <h1 className="text-lg font-bold">User Dashboard</h1>
              </div>
              <div className="flex items-center">
                <p className="text-end text-sm px-3 mb-2">
                  Show no of entity
                  <select
                    className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                    onChange={handleDropdownChange}
                    value={entitiesPerPage}
                  >
                    <option className="text-black" value="5">5</option>
                    <option className="text-black" value="7">7</option>
                    <option className="text-black" value="10">10</option>
                  </select>
                </p>
              </div>
            </div>
            <div className="pb-5 rounded-xl overflow-hidden overflow-x-auto">
              <table className="w-full text-center">
                <thead className="text-[#00FFC2]">
                  <tr>
                    <th>User Address</th>
                    <th>Paid Amount (USD)</th>
                    <th>Tokens Purchased</th>
                    <th>Payment Details</th>
                    <th>Account Status</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#FFFFFF63]">
                  {loading ? (
                    <tr>
                      <td colSpan="5">Loading...</td>
                    </tr>
                  ) : currentEntities.length > 0 ? (
                    currentEntities.map((entity, index) => (
                      <tr
                        key={index}
                        className="h-16 text-sm md:text-base text-center rounded-md"
                      >
                        <td className="px-5 md:px-0">
                          {entity.WalletAddress ? (
                            <span className="relative group cursor-pointer">
                              {entity.WalletAddress.substring(0, 10) + "..."}
                              <div className="absolute left-0 bottom-full mb-2 w-auto p-2 bg-gray-800 text-white text-xs rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                {entity.WalletAddress}
                              </div>
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td className="px-5 md:px-0">
                          {Number(entity.totalTokenAmountUSD).toFixed(5)}
                        </td>
                        <td className="px-5 md:px-0">
                          {Number(entity.totalCoinAmount).toFixed(5)}
                        </td>
                        <td className="px-5 md:px-0">
                          <button
                            className="bg-[#DB416B] font-impact text-white font-bold px-7 rounded-md py-1"
                            style={{
                              WebkitTextStrokeColor: "black",
                              WebkitTextStrokeWidth: "0.5px",
                            }}
                            onClick={() => handleRedirect(entity.WalletAddress)}
                          >
                            View
                          </button>
                        </td>
                        <td
                          className={`px-5 md:px-0 font-bold ${
                            entity.accountStatus === "Active"
                              ? "text-[#00ff00]"
                              : "text-[#FF0000]"
                          }`}
                        >
                          {entity.accountStatus}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="mt-2 flex justify-between text-xs px-3">
              <button
                className="bg-[#DB416B] text-white font-semibold border border-white hover:bg-transparent rounded-md px-5 py-1"
                onClick={handlePrevClick}
                disabled={currentPage === 1}
              >
                Prev
              </button>
              <button
                className="bg-[#DB416B] text-white font-semibold border border-white hover:bg-transparent rounded-md px-5 py-1"
                onClick={handleNextClick}
                disabled={currentEntities.length < entitiesPerPage}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
